import { 
    GET_TOKEN_COMPLETED,
    PUT_MAP_COMPLETED
} from "../constants/action-types"
const initialState = {
    token: '',
    uploadedMediaId: ''
};
function mediaReducer(state = initialState, action) {
	if(action.type === GET_TOKEN_COMPLETED){
        // console.log(action.payload)
		return Object.assign({}, state, {
            token: action.payload.data.token
		});
    }
    
	if(action.type === PUT_MAP_COMPLETED){
        console.log(action.payload)
		return Object.assign({}, state, {
            uploadedMediaId: action.payload.data.media_ids[0]
		});
	}
	return state;
};
export default mediaReducer;