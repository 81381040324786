import { connect } from 'react-redux'
import {
  toggleOrthoLayer,
  toggleOrthoOpacity,
} from '../../actions/index'
import {
  Checkbox,
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
// import Tooltip from '@material-ui/core/Tooltip'
// import Opacity from '@material-ui/icons/Opacity'
// import Popover from '@material-ui/core/Popover'
// import Slider from 'rc-slider'
import { withLeaflet } from 'react-leaflet'
import 'rc-slider/assets/index.css'

const styles = theme => ({
  popoverPaper: {
    overflowY: 'hidden',
    width: '200px',
    height: '35px',
    padding: '10px',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
})

// const Handle = Slider.Handle

// const handle = (props) => {
//  const {value, dragging, index, ...restProps} = props
//  return (
//    <Tooltip title={value}>
//      <Handle value={value} {...restProps} />
//    </Tooltip>
//  )
// }

function LayerItemHeader(props) {
  // const { classes } = props
  // const [anchorEl, setAnchorEl] = useState(null)

  // function handleClick(event) {
  //  if(props.visible){
  //    setAnchorEl(event.currentTarget)
  //  }
  // }

  // function handleClose(e) {
  //  e.stopPropagation()
  //  setAnchorEl(null)
  // }

  // function changeOpacity(event) {
  //  props.toggleOrthoOpacity(event)
  // }

  // const open = Boolean(anchorEl)
  // const id = open ? 'simple-popover' : null

  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item xs={6} style={{ paddingLeft: '16px' }}>
        <Typography>{ props.itemName }</Typography>
      </Grid>
      <Grid item xs={4}>
      { /*
        props.itemName === 'Orthomosaic' &&
        <>
        <Tooltip title='Adjust opacity'>
          <Opacity color={props.visible ? 'primary' : 'disabled'} onClick={(e) => {handleClick(e)}}/>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{
            paper: classes.popoverPaper,
          }}
          >
            <Slider min={0} max={100} defaultValue={props.opacity} handle={handle} onChange={changeOpacity}/>
          </Popover>
          </>
        */
      }
      </Grid>
      <Grid item xs={2}>
        <Checkbox
          checked={props.visible}
          value='props.itemId'
          onChange={props.toggleLayer}
          color='primary'
          disabled={props.items === null || props.items.length === 0}
        />
      </Grid>
    </Grid>
  )
}

function mapStateToProps(state, props){
  return {
    visible: state.estate.visible[props.itemId],
    opacity: state.estate.opacity[props.itemId],
    items: state.estate[props.listName],
    zoom: state.estate.zoom
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    // toggle layer visiblity
    toggleLayer: (payload = {}) => {
      if (props.itemId === 'ortho'){
        return dispatch(toggleOrthoLayer(payload))
      }
    },
    toggleOrthoOpacity: payload => dispatch(toggleOrthoOpacity(payload)),
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withLeaflet(LayerItemHeader)))
