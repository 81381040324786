import { takeEvery, takeLatest, put, delay } from "redux-saga/effects"

export default function* watcherSaga() {
  yield takeLatest('TOUR_NEXT_STEP', tourNextStep)
  yield takeLatest('TOUR_PREV_STEP', tourPrevStep)
  yield takeEvery('START_TOUR', startTour)
  yield takeEvery('END_TOUR', endTour)
}

function* tourNextStep({ delayTime }) {
  if (delayTime) 
    yield delay(delayTime)

  yield put({ type: '_TOUR_NEXT_STEP' })
}

function* tourPrevStep() {
  yield put({ type: '_TOUR_PREV_STEP' })
}

function* startTour({ payload }) {
  // console.log('in saga putting start tour and ', payload)
  yield put({ type: '_START_TOUR', payload })
}

function* endTour() {
  yield put({ type: '_END_TOUR' })
}
