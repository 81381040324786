import { takeEvery, call, put, takeLatest, take} from "redux-saga/effects"
import { eventChannel } from 'redux-saga'
import {
  uploadProgress,
  uploadSuccess,
  uploadFailure
} from '../actions/index'
import {
  ANNOTATIONS_DATA_REQUESTED,
  ANNOTATIONS_API_ERRORED,
  ANNOTATIONS_DATA_LOADED,
  ADD_ANNOTATION_REQUESTED,
  ADD_ANNOTATION_COMPLETED,
  REMOVE_ANNOTATION_REQUESTED,
  REMOVE_ANNOTATION_COMPLETED,
  UPLOAD_FILE_REQUESTED,
  BLOCKS_DATA_REQUESTED,
  BLOCKS_DATA_LOADED,
  BLOCKS_DATA_ERRORED
} from '../constants/action-types'
import axios from 'axios'
import store from '../store/index'

const analyticsUrl = process.env.REACT_APP_ANALYTICS_BASE_URL

export default function* watcherSaga() {
  yield takeLatest(ANNOTATIONS_DATA_REQUESTED, workerSaga)
  yield takeLatest(BLOCKS_DATA_REQUESTED, workerGetBlockSaga)
  yield takeEvery(ADD_ANNOTATION_REQUESTED, workerAddAnnotationSaga)
  yield takeEvery(REMOVE_ANNOTATION_REQUESTED, workerRemoveAnnotationSaga)
  yield takeEvery(UPLOAD_FILE_REQUESTED, workerUploadFileSaga)
}

function authHeader() {
  return { Authorization: 'Bearer ' + store.getState().oidc.user.access_token }
}

function* workerUploadFileSaga(action) {
  const formData = new FormData()
  const file = action.payload.files[0]
  formData.append('kml', file)

  const channel = yield call(createUploadFileChannel, analyticsUrl + 'geo/kml/' + action.payload.media_id, formData)
  while(true) {
    const { progress = 0, err, success } = yield take(channel)
    if (err) {
      yield put(uploadFailure(file, err))
      return
    }
    if (success) {
      yield put(uploadSuccess(file))
      return
    }
    yield put(uploadProgress(file, progress))
  }
}

function* workerRemoveAnnotationSaga(action) {
  try {
    const payload = yield call(removeAnnotationData, action)
    yield put({ type: REMOVE_ANNOTATION_COMPLETED, payload})
  } catch (e) {
    yield put({ type: ANNOTATIONS_API_ERRORED, payload: e })
  }
}

function* workerAddAnnotationSaga(action) {
  try {
    const payload = yield call(addAnnotationData, action)
    yield put({ type: ADD_ANNOTATION_COMPLETED, payload})
  } catch (e) {
    console.error(e)
    yield put({ type: ANNOTATIONS_API_ERRORED, payload: e })
  }
}

function* workerSaga(action) {
  try {
    const payload = yield call(getAnnotationData, action)
    yield put({ type: ANNOTATIONS_DATA_LOADED, payload })
  } catch (e) {
    console.error(e)
    yield put({ type: ANNOTATIONS_API_ERRORED, payload: e })
  }
}

function* workerGetBlockSaga(action) {
  try {
    const payload = yield call(getBlocksData, action)
    yield put({ type: BLOCKS_DATA_LOADED, payload })
  } catch (e) {
    console.error(e)
    yield put({ type: BLOCKS_DATA_ERRORED, payload: e })
  }
}

function removeAnnotationData(options) {
  const { media_id, deleteType } = options.payload

  return axios.post(
    analyticsUrl + 'annotation/' + media_id + '/bulk_delete', options.payload, {
      headers: authHeader()
    })
    .then(response => {
      response.data.deleteType = deleteType
      return response.data
    }
  )
}

function addAnnotationData(options) {
  const { media_id, feature } = options.payload
  return axios.post(
    analyticsUrl + 'annotation/' + media_id, feature, {
      headers: authHeader()
    })
  .then(response => {
    return response.data
  })
}

function getBlocksData(options) {
  const { maps } = options.payload
  const media_ids = maps.map((map) => map.media_id)

  if (media_ids.length > 0) {
    return axios.get(analyticsUrl + 'annotation/blocks/', {
      params: {
        map_ids: media_ids
      },
      headers: authHeader()
    })
    .then((result) => {
      return result.data
    })
  }
}

function getAnnotationData(options) {
  const params = {}
  if (options.payload.geometry) {
    params['bbox'] = JSON.stringify(options.payload.geometry.geometry.coordinates)
  }
  return axios.get(analyticsUrl + 'annotation/'+options.payload.media_id, {
    params: {
      ...params,
      purpose: options.payload.purpose,
      type: options.payload.type,
    },
    headers: authHeader()
  })
  .then(response => {
    return response.data
  })
}

function createUploadFileChannel(url, file) {
  return eventChannel(emit => {
    const onProgress = ({ total, loaded }) => {
      const percentage = Math.round((loaded * 100) / total)
      emit({ progress: percentage })
    }
    axios.post(url, file, {
      onUploadProgress: onProgress,
      headers: authHeader()
    })
    .then(() => {
      emit({ success: true })
    })
    .catch(err => {
      emit({ err : new Error(err.message) })
    })
    const unsubscribe = () => {}
    return unsubscribe
  })
}
