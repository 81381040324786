import { 
	QUERY_COMPLETED,
	COMPANY_ID_COMPLETED,
	ACCOUNT_INFO_COMPLETED
} from "../constants/action-types"

// import store from '../store/index'

const initialState = {
	status: false,
	companyId: '',
	accountInfo: []
};

function queryReducer(state = initialState, action) {
	if(action.type === QUERY_COMPLETED){
		return Object.assign({}, state, {
			status: action.payload.status
		});
	}
	if(action.type === COMPANY_ID_COMPLETED){
		return Object.assign({}, state, {
			companyId: action.payload.plex.company_id
		})
	}
	if(action.type === ACCOUNT_INFO_COMPLETED){
		return Object.assign({}, state, {
			accountInfo: action.payload.data.result[0]
		})
	}
	return state;
};
export default queryReducer;