import React        from 'react'
import ReactDOM     from 'react-dom'
import App          from './App'
import { Provider } from 'react-redux'

import { OidcProvider, processSilentRenew } from 'redux-oidc'
import store, { history }                   from './store/index'
import userManager                          from './utils/userManager'

if (window.location.pathname === '/silent-renew') {
  processSilentRenew()
} else {
  ReactDOM.render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <App history={history}/>
    </OidcProvider>
  </Provider>,
  document.getElementById('root'))
}
