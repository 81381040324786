import { red, yellow, blue, green } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'


// Plantation 4.0 website's theme to follow Style Guide for Plantation 4.0
const plantationTheme = createTheme({
  palette: {
    primary: {
      main:  '#2D5A27',
      light: '#598751',
      dark:  '#013000',
    },
    secondary: {
      main:  '#FFD700',
      light: '#FFFF52',
      dark:  '#C7A600',
    },
    error: {
      main: red.A700,
    },
    warning: {
      main: yellow.A700
    },
    info: {
      main: blue.A700
    },
    success: {
      main: green.A700
    },
    background: {
      default: '#f1f1f1',
      appbar:  '#384A58',
    },
    chart: {
      colors: ['#00689E', '#009C9C', '#E3883E', '#FFAC0A', '#E3323C', '#4C212A', '#003148', '#0F575B', '#4FA101', '#FF6146']
    },
    annotation: {
      colors: ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#000000'],
      disease:  '#9c27b0',
      pest:     '#ff9800',
      dry:      '#03a9f4', // is this really water stress?
      death:    '#ffffff',
      waterway: '#03a9f4',
      road:     '#ff9800', // dirt road? to choose a gravel colour
    }
  }
})

export default plantationTheme

// Old theme for reference
// const theme = createTheme({
//   palette: {
//     type: 'light',
//     primary: {
//       main: '#384A58',
//       // dark: '#003300',
//       light: '#d6eddc',
//       contrastText: '#ffffff',
//     },
//     background: {
//       default: '#f1f1f1',
//     },
//     secondary: {
//       main: '#cc3300',
//     }
//   },
//   typography: { useNextVariants: true },
// })