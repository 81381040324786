import { takeEvery, call, put } from "redux-saga/effects"
import {
  GET_MAPS_COMPLETED,
  GET_MAPS_REQUESTED,
  GET_MAPS_ERRORED,
  GET_MAP_REQUESTED,
  GET_MAP_ERRORED,
  GET_MAP_COMPLETED,
  UPDATE_ESTATE_REQUESTED,
  UPDATE_ESTATE_COMPLETED,
  UPDATE_ESTATE_ERRORED
} from '../constants/action-types'
import axios from 'axios'
import store from '../store/index'

const analyticsUrl = process.env.REACT_APP_ANALYTICS_BASE_URL

export default function* watcherSaga() {
  yield takeEvery(GET_MAPS_REQUESTED, workerGetMapsSaga)
  yield takeEvery(GET_MAP_REQUESTED, workerGetMapSaga)
  yield takeEvery(UPDATE_ESTATE_REQUESTED, workerUpdateEstateSaga)
}

function authHeader() {
  return { Authorization: 'Bearer ' + store.getState().oidc.user.access_token }
}

function* workerGetMapSaga(action) {
  try {
    const payload = yield call(getMap, action)
    yield put({ type: GET_MAP_COMPLETED, payload })
  } catch (e) {
    yield put({ type: GET_MAP_ERRORED, payload: e })
  }
}

function* workerGetMapsSaga(action) {
  try {
    const payload = yield call(getMaps, action)
    yield put({ type: GET_MAPS_COMPLETED, payload })
  } catch (e) {
    yield put({ type: GET_MAPS_ERRORED, payload: e })
  }
}

function* workerUpdateEstateSaga(action) {
  try {
    const payload = yield call(updateEstate, action)
    yield put({ type: UPDATE_ESTATE_COMPLETED, payload })
  } catch (e) {
    yield put({ type: UPDATE_ESTATE_ERRORED, payload: e })
  }
}

function getMap(payload) {
  return axios.get(analyticsUrl + 'map/' + payload.payload.id, {
    headers: authHeader()
  })
  .then((result) => {
    return result.data
  })
}

function getMaps(payload) {
  return axios.get(analyticsUrl + 'map', {
      headers: authHeader()
  })
  .then((result) => {
    return result.data
  })
}

function updateEstate(payload) {
  return axios.post(analyticsUrl + 'map/' + payload.payload.media_id + '/' + payload.payload.option.toLowerCase(),
    { name: payload.payload.update_name }, {
      headers: authHeader()
    }
  )
  .then((result) => {
    return result.data
  })
}
