import { Elements, StripeProvider } from 'react-stripe-elements'
import TreeCountForm from './TreeCountForm'

const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_ID
if (!STRIPE_API_KEY) throw new Error('Environment Variable REACT_APP_STRIPE_ID is required.')

function OrderSummary() {
  return (
    <StripeProvider apiKey={STRIPE_API_KEY}>
      <div>
        <Elements>
          <TreeCountForm />
        </Elements>
      </div>
    </StripeProvider>
  )
}

export default OrderSummary
