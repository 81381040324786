import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Card, Typography, Button } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withRouter } from 'react-router-dom'

import {
    clearPurchasedTree,
} from '../../actions/index'

const styles = theme => {
    return {
        root: {
            display: 'flex',
            marginTop: '15vh',
            marginBottom: 15,
            justifyContent: 'center'
        },
        action: {
            display: 'flex',
            justifyContent: 'center'
        },
        card: {
            display: 'inline-block',
            justifyContent: 'center',
            textAlign: 'center',
            width: 600,
            height: 300
        },
        icon: {
            color: '#5AA700',
            width: 50,
            height: 50,
            marginTop: 60,
            marginBottom: 20,
        },
        title: {
            color: '#5AA700',
        },
        descriptor: {
            marginTop: 40,
            marginLeft: 130,
            marginRight: 130
        },
        button: {
            margin: 15
        }
    }
}

function mapStateToProps(state){
    return {
        maps: state.maps.maps ? state.maps.maps : []
    }
}

function mapDispatchToProps(dispatch){
    return {
        clearPurchasedTree: payload => dispatch(clearPurchasedTree(payload))
    }
}

function getMapId(maps, media_id){
    var id = ''
    maps.forEach((map) => {
        if(map.media_id === media_id){
            id = map._id
        }
    })
    return id
}

function OrderSuccess(props) {
    const {classes, history, media_id} = props
    const mapId = getMapId(props.maps, media_id)
    return (
        <>
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CheckCircleIcon fullWidth className={classes.icon}/>
                    <Typography fullWidth variant='h5' className={classes.title}>You have successfully made a payment</Typography>
                    <Typography fullWidth variant='subtitle2' className={classes.descriptor}>We just sent your receipt to your email address, and your items will be on its way shortly</Typography>
                </Card>
            </div>
            <div className={classes.action}>
                <Button variant='outlined' className={classes.button} onClick={()=> {
                    props.clearPurchasedTree()
                    history.push('/')}}>Exit to Dashboard</Button>
                <Button variant='contained' color='primary' className={classes.button} onClick={()=> {
                    props.clearPurchasedTree()
                    history.push('/map/' + mapId)}}>Back to Estate</Button>
            </div>
        </>
    )
}
const ConnectedOrderSuccess = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderSuccess))
export default withRouter(ConnectedOrderSuccess)
