import { useState }  from 'react'
import { connect } from 'react-redux'
import { changeDetail } from '../../actions/index'

import distance from '@turf/distance'
import area     from '@turf/area'

import classNames from 'classnames'
import { CirclePicker } from 'react-color'
import { formatDecimal } from '../../utils/formatter'
import {
  Grid,
  Select,
  TextField,
  MenuItem,
  Link,
  Popover,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import theme from '../../utils/site-theme'

const styles = theme => ({
  divBox: {
    padding: '10px',
  },
  marginTop: {
    marginTop: '15px',
  },
  textOverflow: {
    overflowWrap: 'break-word',
  },
  dropdown: {
    width: '100%',
  },
  fontBold: {
    fontWeight: 'bold',
  },
  colorButton: {
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    display: 'block',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5)',
  },
  colorPopover: {
    backgroundColor: '#ffffff',
      overflowY: 'hidden',
      padding: '5px',
  },
})

function AnnotationDetailBody({ classes, detail, changeDetail }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : null

  const handleChangeProperties = propName => event => {
    changeDetail({
      [propName]: event.target.value
    })
  }

  function handleToggleColorPanel(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }
  function distanceLine(geometry){
    return geometry.coordinates.reduce((prev, coordinate, index)=>{
      if(index === geometry.coordinates.length - 1){
        return 0 + prev
      }
      return distance(coordinate, geometry.coordinates[index + 1]) + prev
    }, 0)
  }
  switch(detail.geometry.type) {
    case 'Polygon':
      return (
        <div className={classes.divBox}>
          <Typography variant='h6' gutterBottom className={classes.fontBold}>
            Measurement
          </Typography>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' wrap='nowrap'>
            <Grid item sm={5}>
              <Typography variant='body2' gutterBottom className={classes.fontBold}>
                Area: 
              </Typography>
            </Grid>
            <Grid item sm={7}>
              <Typography variant='body2' className={classNames(classes.textOverflow, classes.fontBold)} gutterBottom>
              { formatDecimal((area(detail.geometry)/10000), 2) } ha
              </Typography>
            </Grid>
          </Grid>
          {/*<Grid container direction='row' justifyContent='space-between' alignItems='center' wrap='nowrap'>
            <Grid item sm={5}>
              <Typography variant='body2' gutterBottom className={classes.fontBold}>
                Perimeter: 
              </Typography>
            </Grid>
            <Grid item sm={7}>
              <Typography variant='body2' className={classNames(classes.textOverflow, classes.fontBold)} gutterBottom>
                500 m
              </Typography>
            </Grid>
          </Grid>*/
          }
          
          <Typography variant='h6' className={classes.marginTop} gutterBottom>
            More Information
          </Typography>
          <Typography variant='body2' className={classes.marginTop} gutterBottom>
            Name
          </Typography>
          <TextField 
            fullWidth={true} 
            value={detail.properties.name}
            onChange={handleChangeProperties('name')}
          />
          <Typography variant='body2' className={classes.marginTop} gutterBottom>
            Description
          </Typography>
          <TextField margin='none' fullWidth={true} multiline={true} value={detail.properties.description ? detail.properties.description : ''} onChange={handleChangeProperties('description')}/>
          <Typography variant='body2' className={classes.marginTop} gutterBottom>
            Color
          </Typography>
          <Link
            component='button'
            classes={{
              root: classes.colorButton,
            }}
            onClick={handleToggleColorPanel}
            style={{
              // backgroundColor: props.blockDetail.properties.color,
              backgroundColor: detail.properties.color ,
            }}
          >
          </Link>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{
              paper: classes.colorPopover,
            }}>
            <div>
              <CirclePicker 
                width={210}
                colors={theme.palette.annotation.colors}
                onChangeComplete={(e) => {
                  handleToggleColorPanel(e)
                  handleChangeProperties('color')({target: { value: e.hex }})
                }}
              />
            </div>
          </Popover>
        </div>
      )
    case 'Point':
      return (
        <div className={classes.divBox}>
          <Typography variant='h6' gutterBottom className={classes.fontBold}>
            Position
          </Typography>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' wrap='nowrap'>
            <Grid item sm={5}>
              <Typography variant='body2' gutterBottom className={classes.fontBold}>
                Longitude: 
              </Typography>
            </Grid>
            <Grid item sm={7}>
              <Typography variant='body2' className={classNames(classes.textOverflow, classes.fontBold)} gutterBottom>
                {detail ? formatDecimal(detail.geometry.coordinates[0], 5) : ''}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' wrap='nowrap'>
            <Grid item sm={5}>
              <Typography variant='body2' gutterBottom className={classes.fontBold}>
                Latitude: 
              </Typography>
            </Grid>
            <Grid item sm={7}>
              <Typography variant='body2' className={classNames(classes.textOverflow, classes.fontBold)} gutterBottom>
                {detail ? formatDecimal(detail.geometry.coordinates[1], 5) : ''}
              </Typography>
            </Grid>
          </Grid>
          {/*<Grid container direction='row' justifyContent='space-between' alignItems='center' wrap='nowrap'>
            <Grid item sm={5}>
              <Typography variant='body2' gutterBottom className={classes.fontBold}>
                Elevation: 
              </Typography>
            </Grid>
            <Grid item sm={7}>
              <Typography variant='body2' className={classNames(classes.textOverflow, classes.fontBold)} gutterBottom>
                
              </Typography>
            </Grid>
          </Grid>*/
          }
          <Typography variant='h6' className={classes.marginTop} gutterBottom>
            More Information
          </Typography>
          <Typography variant='body2' className={classes.marginTop} gutterBottom>
            Name
          </Typography>
          <TextField 
            fullWidth={true} 
            value={detail.properties.name}
            onChange={handleChangeProperties('name')}
          />
          <Typography variant='body2' className={classes.marginTop} gutterBottom>
            Description
          </Typography>
          <TextField margin='none' fullWidth={true} multiline={true} value={detail.properties.description ? detail.properties.description : ''} onChange={handleChangeProperties('description')}/>
          <Typography variant='body2' className={classes.marginTop} gutterBottom>
            Color
          </Typography>
          <Link
            component='button'
            classes={{
              root: classes.colorButton,
            }}
            onClick={handleToggleColorPanel}
            style={{
              // backgroundColor: props.blockDetail.properties.color,
              backgroundColor: detail.properties.color ,
            }}>
          </Link>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{
              paper: classes.colorPopover,
            }}> 
            <div>
              <CirclePicker 
                width={210}
                colors={theme.palette.annotation.colors}
                onChangeComplete={(e) => {
                  handleToggleColorPanel(e)
                  handleChangeProperties('color')({target: { value: e.hex }})
                }}
              />
            </div>
          </Popover>
        </div>
      )
    case 'LineString':
      return(
        <div className={classes.divBox}>
          <Typography variant='h6' gutterBottom className={classes.fontBold}>
            Measurement
          </Typography>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' wrap='nowrap'>
            <Grid item sm={5}>
              <Typography variant='body2' gutterBottom className={classes.fontBold}>
                Length: 
              </Typography>
            </Grid>
            <Grid item sm={7}>
              <Typography variant='body2' className={classNames(classes.textOverflow, classes.fontBold)} gutterBottom>
                {formatDecimal(distanceLine(detail.geometry), 2)} km
              </Typography>
            </Grid>
          </Grid>
          <Typography variant='h6' className={classes.marginTop} gutterBottom>
            More Information
          </Typography>
          <Typography variant='body2' className={classes.marginTop} gutterBottom>
            Name
          </Typography>
          <TextField 
            fullWidth={true} 
            value={detail.properties.name}
            onChange={handleChangeProperties('name')}
          />
          <Typography variant='body2' className={classes.marginTop} gutterBottom>
            Description
          </Typography>
          <TextField margin='none' fullWidth={true} multiline={true} value={detail.properties.description ? detail.properties.description : ''} onChange={handleChangeProperties('description')}/>
          { (detail.properties.purpose === 'drain' || detail.properties.purpose === 'road') && 
            <Typography variant='body2' className={classes.marginTop} gutterBottom>
              Category
            </Typography>
          }
            { detail.properties.purpose === 'drain' &&
            <Select
              autoWidth={true}
              inputProps={{
                name: 'category',
              }}
              value={detail.properties.category}
              className={classes.dropdown}
              onChange={(e)=> handleChangeProperties('category')({
                target: {
                  value: e.target.value.toLowerCase()
                }
              })}>
              <MenuItem value='none'>
                <em>None</em>
              </MenuItem>
              <MenuItem value='river'>River</MenuItem>
              <MenuItem value='drain'>Drain</MenuItem>
            </Select>     
          }
          { detail.properties.purpose === 'road' &&
            <Select
              autoWidth={true}
              inputProps={{
                name: 'category',
              }}
              value={detail.properties.category}
              className={classes.dropdown}
              onChange={(e)=> handleChangeProperties('category')({
                target: {
                  value: e.target.value.toLowerCase()
                }
              })}> 
              <MenuItem value='none'>
                <em>None</em>
              </MenuItem>
              <MenuItem value='mainroad'>Main Road</MenuItem>
              <MenuItem value='secondaryroad'>Secondary Road</MenuItem>
            </Select>
          }
          <Typography variant='body2' className={classes.marginTop} gutterBottom>
            Color
          </Typography>
          <Link
            component='button'
            classes={{
              root: classes.colorButton,
            }}
            onClick={handleToggleColorPanel}
            style={{
              // backgroundColor: props.blockDetail.properties.color,
              backgroundColor: detail.properties.color ,
            }}>
          </Link>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{
              paper: classes.colorPopover,
            }}> 
            <div>
              <CirclePicker 
                width={210}
                colors={theme.palette.annotation.colors}
                onChangeComplete={(e)=> {
                  handleToggleColorPanel(e)
                  handleChangeProperties('color')({target: { value: e.hex }})
                }}
              />
            </div>
          </Popover>
        </div>
      )
    default:
      break
  }  
}

function mapStateToProps(state) {
  return {
    detail: state.estate.detail,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    changeDetail: (payload) => dispatch(changeDetail(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AnnotationDetailBody))
