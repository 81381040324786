import { ONBOARDING_STEPS } from '../components/OnboardingTour'

const initialState = { 
  step: 0,
  maxSteps: ONBOARDING_STEPS.length,
  tourIsOpen: false
}

function tourReducer(state = initialState, action) {
  switch (action.type) {
    case '_TOUR_NEXT_STEP':
      const nextStep = state.step + 1

      if (nextStep >= state.maxSteps)
        return { ...state, step: state.maxSteps-1 }

      return { ...state, step: state.step + 1 }

    case '_TOUR_PREV_STEP':
      return { ...state, step: state.step - 1 }

    case '_START_TOUR':
      console.log('in _START_TOUR', action.payload)
      return { ...state, tourIsOpen: action.payload }
    case '_END_TOUR':
      return { ...state, tourIsOpen: false  }
    default:
  }
	
	return state
}

export default tourReducer
