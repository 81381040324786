import { connect } from 'react-redux'
import RasterLayerComponent from './RasterLayerComponent'
import VectorLayerComponent from './VectorLayerComponent'
import { tourNextStep } from '../../actions/tour-actions'
import {
  List,
  ListItem,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  listRoot: {
    padding: 0,
  },
  listItemRoot: {
    padding: 0,
  },
})

function goToNextTourStep({ tourIsOpen, step, dispatch }) {
  // 5 because it allow tour to proceed if user clicks 'Blocks'
  // 6 because it allow tour to proceed if user clicks any one of the expanded blocks
  // 9 because it allow tour to proceed if user clicks 'Annotations'
  
  if (tourIsOpen && [5, 6, 9].includes(step))
    dispatch(tourNextStep())
}

function AnnotationLayerItemList(props) {
  const { classes } = props

  return (
    <List className={classes.listRoot}>
      <ListItem className={classes.listItemRoot}>
        <RasterLayerComponent
          itemName='Orthomosaic'
          itemId='ortho'
          listName='map'
        />
      </ListItem>
      <ListItem className={classes.listItemRoot} data-tour='step-5' onClick={() => goToNextTourStep(props)}>
        <VectorLayerComponent
          itemName='Blocks'
          listName='blocks'
          purpose='block'
          hasChildItem
          hasSubOptions
          geometry='polygon'
        />
      </ListItem>
      <ListItem className={classes.listItemRoot} data-tour='step-9' onClick={() => goToNextTourStep(props)}>
        <VectorLayerComponent
          itemName='Annotations'
          listName='annotations'
          purpose='annotation'
          hasChildItem
          hasSubOptions
        />
      </ListItem>
      <div data-tour='step-15'>
        <ListItem className={classes.listItemRoot}>
          <VectorLayerComponent
            itemName='Roads'
            listName='roads'
            hasChildItem
            geometry='line'
            purpose='road'
          />
        </ListItem>
        <ListItem className={classes.listItemRoot}>
          <VectorLayerComponent
            itemName='Waterways'
            listName='drains'
            hasChildItem
            geometry='line'
            purpose='drain'
          />
        </ListItem>
        <ListItem className={classes.listItemRoot} data-tour='step-3'>
          <VectorLayerComponent
            itemName='Trees'
            listName='trees'
            geometry='point'
            purpose='tree'
          />
        </ListItem>
      </div>
      {/*<ListItem className={classes.listItemRoot}>
        <VectorLayerComponent
          itemName='Flights'
          listName='flights'
          purpose='flight'
          hasChildItem
        />
      </ListItem>*/}
      {/*<ListItem className={classes.listItemRoot}>
        <VectorLayerComponent
          itemName='Discharges'
          listName='discharges'
          purpose='discharge'
          hasChildItem
        />
      </ListItem>*/}
    </List>
  )
}

function mapStateToProps(state) {
  return {
    step: state.tour.step,
    tourIsOpen: state.tour.tourIsOpen
  }
}
export default connect(mapStateToProps)(withStyles(styles)(AnnotationLayerItemList))
