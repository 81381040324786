import { Component } from 'react'
import { connect } from 'react-redux'
import { getAccountInfo, getCompanyId } from '../../actions/index'

import PlantationHeader from './PlantationHeader'

class PlantationList extends Component {
  constructor(props) {
		super(props)
		this.state = {
			anchorElEstate: null,
			anchorElFilter: null
		}
  }

  async componentDidMount(){
    await this.props.getCompanyId()
    await this.props.getAccountInfo(this.props.companyId)
  }

  render() {
    return (
      <div>
        <PlantationHeader page= 'Estates' isSort={true} isAddEstate={true} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    maps:      state.maps.maps ? state.maps.maps : [],
    allBlocks: state.estate.allBlocks ? state.estate.allBlocks : [],
    companyId: state.query.companyId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAccountInfo: (payload) => dispatch(getAccountInfo(payload)),
    getCompanyId:   (payload) => dispatch(getCompanyId(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlantationList)
