import {
	SUBSCRIBE_SOCKET_MESSAGE,
	ADD_ANNOTATION_REQUESTED,
	SAVE_DETAIL_REQUESTED,
	REMOVE_ANNOTATION_REQUESTED,
	RECEIVE_SOCKET_MESSAGE,CLEAR_UPDATES, ERROR_SOCKET_MESSAGE,
	CLEAR_MAP_STATE,
	UPLOAD_FILE_REQUESTED,
	UPLOAD_FILE_ERRORED,
	ANNOTATIONS_API_ERRORED,
	PLANTATION_LOGOUT
} from '../constants/action-types';
const initialState = {
	waiting: {},
	updated: {},
	isLogout : false
}
export default function socketReducer(state= initialState, action){	

	if(
		action.type === ANNOTATIONS_API_ERRORED || action.type === UPLOAD_FILE_ERRORED
	){	
		return Object.assign({}, state, {
			waiting: initialState.waiting
		})
	}
	
	if(
		action.type === ADD_ANNOTATION_REQUESTED || 
		action.type === SAVE_DETAIL_REQUESTED ||
		action.type === UPLOAD_FILE_REQUESTED ||
		action.type === REMOVE_ANNOTATION_REQUESTED
	){
		let item = null;
		if(action.type === ADD_ANNOTATION_REQUESTED || 
			action.type === SAVE_DETAIL_REQUESTED){
			item = action.payload.feature;
		}
		
		if(action.type === UPLOAD_FILE_REQUESTED || 
			(action.type === REMOVE_ANNOTATION_REQUESTED && action.payload.deleteType === "tree") || 
			(item && (item.properties.purpose === "tree" || item.properties.purpose === "block"))
		){
			const payload = {
				purpose: 'block',
				topic: 'blocks_processing'
			}
			return Object.assign({}, state, {
				waiting: {
					...state.waiting,
					[payload.purpose] : payload
				}
			})
		}
	}
	
	if(action.type === SUBSCRIBE_SOCKET_MESSAGE){
		return Object.assign({}, state, {
			waiting: {
				...state.waiting,
				[action.payload.purpose] : action.payload
 			}
		})
	}
	if(action.type === RECEIVE_SOCKET_MESSAGE){
		let changes = {};
		changes['waiting'] = {...state.waiting};
		changes['updated'] = {
			...state.updated,
			[action.payload.purpose] : action.payload
		 }
		delete changes['waiting'][action.payload.purpose]
		return Object.assign({}, state, {
			...changes,
		})
	}

	if(action.type === CLEAR_UPDATES){
		const updated = state.updated;
		delete updated[action.payload.purpose]
		return Object.assign({}, state, {
			updated: updated
		})
	}
	if(action.type === ERROR_SOCKET_MESSAGE){
		return Object.assign({}, state, {
			waiting: initialState.waiting
		})
	}
	if(action.type === CLEAR_MAP_STATE){
        return Object.assign({}, state, initialState);
	}
	if(action.type === PLANTATION_LOGOUT){
		return Object.assign({},state,{
			isLogout : !initialState.isLogout
		})
	}
    return state
}