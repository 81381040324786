import { 
     COMPLETED_DRAWING, 
     TOGGLE_DRAWING,
     // START_DRAWING,
     CLEAR_MAP_STATE,
     } from '../constants/action-types';
import _ from 'lodash';
const initialState = {
    draw:{}
}
export default function drawReducer(state= initialState, action){	
    
    if(action.type === COMPLETED_DRAWING){
        //deactive drawing mode
        return Object.assign({}, state, {
            results: {
                ...state.results,
                [action.payload.purpose]: action.payload
            }
        })
    }
    if(action.type === TOGGLE_DRAWING && action.payload.geometry && !state.draw[action.payload.geometry]){
        return Object.assign({}, state, {
            draw: {
                // ...state.draw,
                [action.payload.geometry]:  action.payload
            }
        })
    }
    if(action.type === TOGGLE_DRAWING && Object.keys(state.draw).length > 0 ){
        let changes = {}
        _.forEach(state.draw, (value, mode)=>{
            if(action.payload.purpose !== value.purpose){
                changes = {[action.payload.geometry]:  action.payload}
            }
        })
        return Object.assign({}, state, {
            // draw: {}
            draw: changes
        })
    }
    if(action.type === CLEAR_MAP_STATE){
        return Object.assign({}, state, initialState);
    }
    return state
}