import React          from 'react'
import { connect }    from 'react-redux'
import { withRouter } from 'react-router'
import { Link }       from 'react-router-dom'
import userManager    from '../utils/userManager'
import { clearMapState,plantationLogout } from '../actions/index'
import { tourNextStep }                   from '../actions/tour-actions'
import { showOnboardingBlinker }          from '../utils/onboardingTour'

import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core'
import {
  KeyboardArrowLeft,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { ReactComponent as Logo } from '../static/svg/logo.svg'


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    backgroundColor: theme.palette.background.appbar,
  },
  grow: {
    flexGrow: 1,
    display: 'flex'
  },
  nav: {
    margin: theme.spacing(2),
    color: theme.palette.common.white
  },
  email: {
    margin: theme.spacing(1),
    color: theme.palette.secondary.main
  },
  logoIcon: {
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.background.appbar
     }
  },
  backIcon: {
    marginLeft: -theme.spacing(1),
    color: 'white',
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    color: 'white',
  }
})

function PlantationAppBar(props) {
  const { classes, user, map } = props

  const estateSelected = map !== null

  function handleSignIn(event) {
    event.preventDefault()
    userManager.signinRedirect()
  }

  async function handleSignOut() {
    props.plantationLogout()
    await userManager.signoutRedirect()
  }

  const signInOutButton =
    user === null ?
      <Button size='small' variant='outlined' onClick={handleSignIn} className={classes.button}>
        Login
      </Button>
    :
      <Button size='small' variant='outlined' onClick={handleSignOut} className={classes.button}>
        Logout
      </Button>

  const backButton =
    <Link to={'/'} className={classes.link} replace>
      <IconButton className={classes.backIcon}>
        <KeyboardArrowLeft />
      </IconButton>
    </Link>

  const logoButton =
    <Link to={'/'} className={classes.link} replace>
      <IconButton  className={classes.logoIcon}>
        <Logo className={classes.logoIcon}/>
      </IconButton>
    </Link>

  const estateName =
    <Typography variant='h5'>
      {map?.name}
    </Typography>

  const estateTab =
    <Link to={'/'} className={classes.link} replace>
      <Typography variant='h6' className={classes.nav}>
        Estates
      </Typography>
    </Link>

  const dashboardTab =
    <Link to={'/dashboard'} className={classes.link}
      data-tour='step-16'
      onClick={() => { if (props.tourIsOpen && props.step === 16) props.onNextStep() }}
      replace>
      <Typography variant='h6' className={classes.nav}>
        Dashboard {showOnboardingBlinker(props)}
      </Typography>
    </Link>

  const userEmail =
    <Typography variant='body1' className={classes.email}>
      { user?.profile?.email }
    </Typography>

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar className={classes.toolbar}>
          { estateSelected ? backButton : logoButton }
          <div className={classes.grow}>
            { estateSelected ? estateName :
              <div className={classes.grow}>
                { estateTab }
                { dashboardTab }
              </div>
            }
          </div>
          { userEmail }
          { signInOutButton }
        </Toolbar>
      </AppBar>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    clearMapState:    payload   => dispatch(clearMapState(payload)),
    onNextStep:       delayTime => dispatch(tourNextStep(delayTime)),
    plantationLogout: payload   => dispatch(plantationLogout(payload))
  }
}

function mapStateToProps(state) {
  return {
    user:       state.oidc.user,
    map:        state.estate.map,
    step:       state.tour.step,
    tourIsOpen: state.tour.tourIsOpen,
  }
}

const ConnectedAppBar = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlantationAppBar))

export default withRouter(ConnectedAppBar)
