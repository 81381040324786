import React from 'react'
import { connect } from "react-redux"
import {withLeaflet, GeoJSON} from 'react-leaflet'
import * as turf from '@turf/helpers'
import * as turfDistance from '@turf/distance'
import * as turfArea from '@turf/area'
import * as turfKinks from '@turf/kinks'
import {
    completedDrawing,
    addAnnotationData,
    subscribeSocketMessage,
    showMessage,
    toggleDrawing
} from '../actions/index'
import L from 'leaflet'
import _ from 'lodash'
import theme from '../utils/site-theme'

var tooltip = null

const mapStateToProps = (state,props) => {
    return { 
        drawingMode: state.drawing.draw,
        map: state.estate.map,
        preview: state.drawing.preview,
        detail: state.estate.detail
	};
}

function mapDispatchToProps(dispatch) {
	return{
        addAnnotationData: payload => dispatch(addAnnotationData(payload)),
        completedDrawing: payload => dispatch(completedDrawing(payload)),
        subscribeSocketMessage: payload => dispatch(subscribeSocketMessage(payload)),
		showMessage: (payload)=>dispatch(showMessage(payload)),
        toggleDrawing: payload => dispatch(toggleDrawing(payload))
    }
}

class CustomDrawControl extends React.Component{
    getStartDrawingTooltipMessage(){
        if(this.props.drawingMode.point != null){
            if(this.props.drawingMode.point.purpose === "tree"){
                return "Click on the map to add tree"
            } else if(this.props.drawingMode.point.purpose === "annotation") {
                return "Click on the map to add marker"
            }
        } else if(this.props.drawingMode.polygon != null){
            if(this.props.drawingMode.polygon.purpose === "block"){
                return "Click on the map to start drawing block"
            } else if(this.props.drawingMode.polygon.purpose === "annotation"){
                return "Click on the map to start drawing polygon"
            }
        } else if(this.props.drawingMode.line != null){
            if(this.props.drawingMode.line.purpose === "road"){
                return "Click on the map to start drawing road"
            } else if(this.props.drawingMode.line.purpose === "drain"){
                return "Click on the map to start drawing waterway"
            } else if(this.props.drawingMode.line.purpose === "annotation"){
                return "Click on the map to start drawing line"
            }
        }
    }
    addTooltip(e){
        L.DomEvent.on(document, 'mousemove', this.moveTooltip)
        tooltip.innerHTML = this.getStartDrawingTooltipMessage()
        tooltip.style.display = 'block'
    }
    moveTooltip(e){
        tooltip.style.left = e.clientX + 15 + 'px'
        tooltip.style.top = e.clientY - 60 + 'px'
    }
    removeTooltip(e){
        tooltip.innerHTML = ''
        tooltip.style.display = 'none'
        L.DomEvent.off(document, 'mousemove', this.moveTooltip)
    }
    updateTooltip(e){
        var guidelineColor = this.getGuideLineColor()
        if(this.props.drawingMode.line != null) {
            if(e.layer.editor._drawnLatLngs.length < e.layer.editor.MIN_VERTEX){
                tooltip.innerHTML = "Click again to continue drawing"
            } else {
                tooltip.innerHTML = "Double click to finish drawing"
                e.layer.setStyle({
                    color: guidelineColor,
                    weight: 6
                })
            }
        }
        if(this.props.drawingMode.polygon != null){
            if(e.layer.editor._drawnLatLngs.length < e.layer.editor.MIN_VERTEX - 1){
                tooltip.innerHTML = "Click again to continue drawing"
            } else {
                tooltip.innerHTML = "Double click to finish drawing"
                e.layer.setStyle({
                    color: guidelineColor,
                    weight: 6
                })
            }
        }
    }
    checkValidGeometry(feature){
		var block = turf.polygon(feature.geometry.coordinates)
		return turfKinks.default(block).features.length
	}
    convertFeature(featureLayer){
        let feature = null
        if(this.props.drawingMode.point != null) {
            feature = turf.point([featureLayer.getLatLng().lng, featureLayer.getLatLng().lat])
            feature['properties']['purpose'] = this.props.drawingMode.point.purpose
            if(this.props.drawingMode.point.purpose === "annotation") {
                feature['properties']['name'] = "Marker"
            }
        } else if(this.props.drawingMode.polygon != null) {
            let latLngs = featureLayer.toGeoJSON().geometry.coordinates
            feature = turf.polygon(latLngs)
            let area = Number(turfArea.default(feature)) * 0.0001
            feature['properties']['purpose'] = this.props.drawingMode.polygon.purpose
            feature['properties']['area'] = area

            if(this.props.drawingMode.polygon.purpose === "annotation") {
                feature['properties']['name'] = "Polygon"
                feature['properties']['color'] = "black"
            }
            if(this.props.drawingMode.polygon.purpose === "block"){
                feature['properties']['name'] = "Block"
                feature['properties']['color'] = "black"
            }
        } else if(this.props.drawingMode.line != null){
            
            let latLngs = featureLayer.toGeoJSON().geometry.coordinates
            let options = {units: 'meters'}
            let distance = 0
            latLngs.forEach((el, index) => {
                if(index < latLngs.length - 1){
                    let startPoint = turf.point(latLngs[index])
                    let endPoint = turf.point(latLngs[index + 1])
                    distance = distance + turfDistance.default(startPoint, endPoint, options)
                }
            })
            feature = turf.lineString(latLngs)
            feature['properties']['purpose'] = this.props.drawingMode.line.purpose
            feature['properties']['distance'] = distance

            if(this.props.drawingMode.line.purpose === "annotation") {
                feature['properties']['name'] = "Line"
                feature['properties']['color'] = "black"
            }

            if(this.props.drawingMode.line.purpose === "road"){
                feature['properties']['name'] = "Road"
                feature['properties']['color'] = theme.palette.annotation.road
            }

            if(this.props.drawingMode.line.purpose === "drain"){
                feature['properties']['name'] = "Waterway"
                feature['properties']['color'] = theme.palette.annotation.waterway
            }
        }

        return feature
    }
    onMouseMove(e){
        if(e.layer && (e.layer.toGeoJSON()['geometry']['type'].toLowerCase() === 'polygon' || e.layer.toGeoJSON()['geometry']['type'].toLowerCase() === 'polyline' || e.layer.toGeoJSON()['geometry']['type'].toLowerCase() === 'linestring')){
            var guidelineColor = this.getGuideLineColor()
            e.editTools.forwardLineGuide.setStyle({
                color: guidelineColor,
                weight: 6
            })
            e.editTools.backwardLineGuide.setStyle({
                color: guidelineColor,
                weight: 6
            })
        }
    }
    escapeKey(e){
        // if(e.originalEvent.keyCode === 27){
        if(e.which === 27){
            this.props.leaflet.map.editTools.stopDrawing();
            this.props.toggleDrawing(this.props.drawingMode[Object.keys(this.props.drawingMode)[0]])
        }
    }
    getGuideLineColor(){
        if(this.props.drawingMode.line != null && this.props.drawingMode.line.purpose === "road") {
            return '#ff9800'
        } else if( this.props.drawingMode.line != null && this.props.drawingMode.line.purpose === "drain") {
            return '#03a9f4'
        } 
        return 'black'

    }
    startLeafletDrawing(){
        this.props.leaflet.map.off('editable:drawing:end');
        this.props.leaflet.map.off('editable:drawing:start');
        this.props.leaflet.map.off('editable:drawing:commit');
        this.props.leaflet.map.off('mousemove',this.onMouseMove.bind(this));
        // this.props.leaflet.map.off('keyup', this.escapeKey.bind(this))
        this.props.leaflet.map.once('editable:drawing:start', (e)=>{
            this.props.leaflet.map.on('editable:drawing:clicked', (e)=>{
                this.updateTooltip(e)
            })
            this.addTooltip(e)
            // this.props.leaflet.map.once('keyup', this.escapeKey.bind(this))
            document.addEventListener('keyup', this.escapeKey.bind(this))
            this.props.leaflet.map.on('mousemove',this.onMouseMove.bind(this))
            this.props.leaflet.map.once('editable:drawing:cancel', (event)=>{
                this.props.leaflet.map.removeLayer(event.layer);
            })
            this.props.leaflet.map.once('editable:drawing:end', (event)=>{
                this.props.leaflet.map.removeLayer(event.layer);
            })
            this.props.leaflet.map.once('editable:drawing:commit', (event)=>{ 
                this.props.leaflet.map.once('editable:drawing:end', ()=>{
                    this.startLeafletDrawing();
                })
                
                // const latLng = event.layer.getLatLng()
                const feature = this.convertFeature(event.layer)
                if(feature.properties.purpose.toLowerCase() === 'block'){
                    if(this.checkValidGeometry(feature) === 0){
                        this.props.subscribeSocketMessage({
                            topic: 'blocks_processing',
                            purpose: 'block'
                        })
                        this.props.addAnnotationData({
                            feature: feature,
                            media_id: this.props.map.media_id,
                        })
                    } else {
                        this.props.showMessage({type: "error", message: "This is an invalid block! Please redraw it!"})
                    }
                }else{
                    if(feature.properties.purpose.toLowerCase() === "tree"){
                        this.props.subscribeSocketMessage({
                            topic: 'blocks_processing',
                            purpose: 'block'
                        })
                    }
                    this.props.addAnnotationData({
                        feature: feature,
                        media_id: this.props.map.media_id,
                    })
                }
            })
        })
        
        if(this.props.drawingMode.point != null){
            this.props.leaflet.map.editTools.startMarker()
        } else if(this.props.drawingMode.polygon != null){
            this.props.leaflet.map.editTools.startPolygon()
        } else if(this.props.drawingMode.line != null){
            this.props.leaflet.map.editTools.startPolyline()
        }
    }
    componentDidUpdate(prevProps){
        tooltip = L.DomUtil.get('tooltip')
        if(prevProps.drawingMode || this.props.drawingMode){
            _.forEach(prevProps.drawingMode, (value, mode)=>{
                if(prevProps.drawingMode[mode] && !this.props.drawingMode[mode]){
                    this.props.leaflet.map.off('editable:drawing:end', this.removeTooltip())
                    this.props.leaflet.map.editTools.stopDrawing()
                }
            })
            _.forEach(this.props.drawingMode, (value, mode)=>{
                if((!prevProps.drawingMode || !prevProps.drawingMode[mode]) && this.props.drawingMode[mode]){ 
                    this.startLeafletDrawing();   
                }
                if(prevProps.drawingMode[mode] && this.props.drawingMode[mode] && prevProps.drawingMode[mode]['purpose'] !== this.props.drawingMode[mode]['purpose']){
                    this.props.leaflet.map.off('editable:drawing:end', this.removeTooltip())
                    this.props.leaflet.map.editTools.stopDrawing()
                    this.startLeafletDrawing();   
                }
            })
        }
    }
	render(){
        return (
			<React.Fragment>
                <div id='tooltip'></div>
                <GeoJSON 
                    data={this.props.preview} 
					style={{
							color: 'black'
					}}
					ref="geojson"
				>
                </GeoJSON>
			</React.Fragment>
		)
	}
}

const ConnectedCustomDrawControl = connect(mapStateToProps, mapDispatchToProps)(withLeaflet(CustomDrawControl))
export default ConnectedCustomDrawControl