import React, { Component } from 'react'
import { Route } from 'react-router'
import {connect} from 'react-redux'
import PlantationList from '../views/Plantation'
import PlantationAppBar from './PlantationAppBar'
import Estate from '../views/Estate'
import userManager from '../utils/userManager'

class RedirectUser extends Component {

  componentWillMount() {
    if (!this.props.isLoadingUser && !this.props.user)
      userManager.signinRedirect()
  }
    
  render() {
    if (!this.props.isLoadingUser && !this.props.user)
      return null
    return (<>
      <PlantationAppBar />
      <Route exact path='/map/:id' component={Estate} />
      <Route exact path='/' component={PlantationList} />
    </>)
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.oidc.user,
      isLoadingUser: state.oidc.isLoadingUser,
      isUserLoading: state.oidc.isUserLoading
    }
}

export default connect(mapStateToProps)(RedirectUser)
