import { connect }         from 'react-redux'
import { Route, Switch }   from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import ReactGA             from 'react-ga'

// Existing views (when improving, move to /views and below)
import PlantationAppBar from './components/PlantationAppBar'
import OrderSummary     from './components/Checkout/OrderSummary'
import RedirectUser     from './components/RedirectUser'
import LoginCallback    from './components/LoginCallback'
import OnboardingTour   from './components/OnboardingTour'
import UploadTypeChoice from './components/Upload/UploadTypeChoice'
import UploadGoogleTilesContainer from './components/Upload/UploadGoogleTilesContainer'
import UploadRaster     from './components/Upload/UploadRaster'
import DashboardChart   from './components/Dashboard/DashboardChart'

// Improved views
import Plantation from './views/Plantation'
import Estate     from './views/Estate'

import {
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles'

import {
  CssBaseline,
} from '@material-ui/core'

import theme from './utils/site-theme'

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },

  mapBox: {
    width: '100%',
    height: 'calc(100vh - 70px)'
  }
}

function PostLogout(props) {
  window.location = 'https://garuda.io/plantation/'
  return <div>Redirecting to Garuda Robotics website..</div>
}

function App(props) {
  const { history } = props
  const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID

  ReactGA.initialize(GA_TRACKING_ID)

  if (props.user) {
    // console.log('user exists, setting ReactGA user email to: ', props.user.profile.email)
    ReactGA.set({
      userEmail: props.user.profile.email
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      { props.numberOfMaps ? <OnboardingTour history={history} /> : '' }
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path='/callback' component={LoginCallback}/>
          { props.isLoadingUser ?
            <>Loading Plantation 4.0</>
            : (
              props.user || props.isLogout ?
                <>
                  <PlantationAppBar />
                  <Route exact path='/' component={Plantation} />
                  <Route exact path='/map/:id' component={Estate} />
                  <Route exact path='/upload' component={UploadTypeChoice} />
                  <Route exact path='/upload/google-tiles' component= {UploadGoogleTilesContainer}/>
                  <Route exact path='/upload/raster' component= {UploadRaster}/>
                  <Route exact path='/dashboard' component= {DashboardChart}/>
                  <Route exact path='/report' component= {DashboardChart}/>{/* to deprecate */}
                  <Route exact path='/checkout/tree/:id' component= {OrderSummary}/>
                  <Route exact path='/logout' component={PostLogout}/>
                </>
              :
                <RedirectUser />
            )
          }
        </Switch>
      </ConnectedRouter>
    </ThemeProvider>
  )
}

function mapStateToProps(state) {
  let numberOfMaps = 0

  if (state.maps && state.maps.maps)
    numberOfMaps = state.maps.maps.length

  return {
    user:          state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser,
    isLogout:      state.socket.isLogout,
    numberOfMaps
  }
}

export default connect(mapStateToProps, {})(withStyles(styles)(App))
