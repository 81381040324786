import {
  UPLOAD_FILE_PROGRESS,
  UPLOAD_FILE_COMPLETED,
  UPLOAD_FILE_ERRORED,

  GET_MAPS_REQUESTED,
  GET_MAP_REQUESTED,
  POINT_ELEVATION_REQUESTED,
  SAVE_DETAIL_REQUESTED,
  ANNOTATIONS_DATA_REQUESTED,
  BLOCKS_DATA_REQUESTED,
  ADD_ANNOTATION_REQUESTED,
  REMOVE_ANNOTATION_REQUESTED,

  QUERY_REQUESTED,
  COMPANY_ID_REQUESTED,
  ACCOUNT_INFO_REQUESTED,

  GET_TOKEN_REQUESTED,
  PUT_MAP_REQUESTED
} from '../constants/action-types'

export const uploadProgress = (file, progress) => ({
  type: UPLOAD_FILE_PROGRESS,
  payload: progress,
  meta: { file },
})
export const uploadSuccess = (file) => ({
  type: UPLOAD_FILE_COMPLETED,
  meta: { file },
})
export const uploadFailure = (file, err) => ({
  type: UPLOAD_FILE_ERRORED,
  payload: err,
  error: true,
  meta: { file },
})

export function getMaps(payload) {
  return {type: GET_MAPS_REQUESTED, payload}
}
export function getMap(payload) {
  return {type: GET_MAP_REQUESTED, payload}
}
export function getPointElevation(payload) {
  return {type: POINT_ELEVATION_REQUESTED, payload}
}
export function saveDetail(payload) {
  return {type: SAVE_DETAIL_REQUESTED, payload}
}

export function getAnnotationsData(payload) {
  return {type: ANNOTATIONS_DATA_REQUESTED, payload}
}
export function getBlocksData(payload) {
  return {type: BLOCKS_DATA_REQUESTED, payload}
}
export function addAnnotationData(payload) {
  return {type: ADD_ANNOTATION_REQUESTED, payload}
}
export function removeAnnotations(payload) {
  return {type: REMOVE_ANNOTATION_REQUESTED, payload}
}
export function queryRequested(payload) {
  return {type: QUERY_REQUESTED, payload}
}
export function getToken(payload) {
  return {type: GET_TOKEN_REQUESTED, payload}
}
export function putMap(payload) {
  return {type: PUT_MAP_REQUESTED, payload}
}
export function getCompanyId(payload) {
  return {type: COMPANY_ID_REQUESTED, payload}
}
export function getAccountInfo(payload) {
  return {type: ACCOUNT_INFO_REQUESTED, payload}
}
