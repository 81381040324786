import { takeEvery, call, put } from "redux-saga/effects"
import {
  POINT_ELEVATION_REQUESTED,
  POINT_ELEVATION_COMPLETED,
  POINT_ELEVATION_ERRORED,
} from '../constants/action-types'
import axios from 'axios'
import store from '../store/index'

const analyticsUrl = process.env.REACT_APP_ANALYTICS_BASE_URL

export default function* watcherSaga() {
  yield takeEvery(POINT_ELEVATION_REQUESTED, workerElevationSaga)
}
 
function authHeader() {
  return { Authorization: 'Bearer ' + store.getState().oidc.user.access_token }
}

function* workerElevationSaga(action) {
  try {
    const payload = yield call(getPointElevation, action)
    yield put({ type: POINT_ELEVATION_COMPLETED, payload })
  } catch (e) {
    yield put({ type: POINT_ELEVATION_ERRORED, payload: e })
  }
}

function getPointElevation(payload) {
  const elevationUrl = analyticsUrl + "geo/elevation?lat=" + payload.payload.latlng.lat + "&lng=" + payload.payload.latlng.lng
  return axios.get(elevationUrl, {
    headers: authHeader()
  })
  .then((result) => {
    return result.data
  })
}
