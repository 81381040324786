import {LayersControl} from 'react-leaflet'
import {connect} from 'react-redux'
import {GoogleLayer} from 'react-leaflet-google'

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
if (!GOOGLE_MAPS_API_KEY) throw new Error('Environment Variable REACT_APP_GOOGLE_MAPS_API_KEY is required.')

const {BaseLayer} = LayersControl
const terrain = 'TERRAIN'
const road = 'ROADMAP'
const satellite = 'SATELLITE'

function mapStateToProps(state){
	return {
		visible: state.estate.visible.baseLayer,
		map: state.estate.map,
	}
}

function BaseLayerMap(props) {
	return (
		(props.visible) && 
		<LayersControl position='topright'>
			<BaseLayer checked name='Roads'>
				<GoogleLayer googlekey={GOOGLE_MAPS_API_KEY} maptype={road}/>
			</BaseLayer>
			<BaseLayer name='Terrain'>
				<GoogleLayer googlekey={GOOGLE_MAPS_API_KEY} maptype={terrain}/>
			</BaseLayer>
			<BaseLayer name='Satellite'>
				<GoogleLayer googlekey={GOOGLE_MAPS_API_KEY} maptype={satellite}/>
			</BaseLayer>
		</LayersControl>
	)
}

const ConnectedBaseLayerMap = connect(mapStateToProps)(BaseLayerMap)
export default ConnectedBaseLayerMap