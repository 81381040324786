import React from 'react'

import Blinker from '../components/OnboardingBlinker'

// Step 1 is a blinker on Plantation Demo picture
// Step 3 is a blinker on Trees checkbox
// Step 5 is a blinker on Blocks in Map View
// Step 6 is a blinker on a particular block in the Blocks dropdown in Map View
// Step 9 is a blinker on Annotations to open the annotations
// Step 10 is a blinker on "Unhealthy" to show the unhealthy trees
// Step 11 is a blinker on the "+" button next to Annotations to add annotations
// Step 12 is a blinker on Polygon in the Annotations' "+" menu
// Step 16 is a blinker on Reports

const STEPS_WITH_BLINKER = [1, 3, 5, 6, 9, 10, 11, 12, 16]

export function showOnboardingBlinker(props, onPolygon=false) {
  try {
    if (STEPS_WITH_BLINKER.includes(props.step)) {  
      if (props.step === 1) {
        if (props.map.demo) {
          return <Blinker marginTop={100} marginLeft={20} />
        }
      }
      if (props.step === 3 && props.listName === 'trees')
        return <Blinker fixed transparent top={370} left={175} />
      if (props.step === 5 && props.itemName === 'Blocks')  
        return <Blinker marginLeft={85} marginTop={-200} />
      if (props.step === 6 && props.listName === 'blocks' && props.index === 0)
        return <Blinker marginLeft={60} marginTop={-20} />
      if (props.step === 9 && props.itemName === 'Annotations') 
        return <Blinker top={15} left={120} />
      if ((props.step === 10 || props.step === 12) && props.listName === 'annotations' && props.item.properties.name === 'Unhealthy') 
        return <Blinker marginLeft={80} marginTop={-22} />
      if (props.step === 11 && props.itemName === 'Annotations' && !onPolygon)  
        return <Blinker top={23} left={128}  transparent goToNext={false} />
      if (props.step === 11 && props.itemName === 'Annotations' && onPolygon) 
        return <Blinker top={-1} left={6} transparent goToNext={false} />
      if (props.step === 16)
        return <Blinker />
    }
  } catch (error) {
    console.log('showOnboardingBlinker ERROR: ', error)
  }
}
