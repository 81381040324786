import {
    PAYLOAD_VISIBLE_BASELAYER,
    PAYLOAD_VISIBLE_ORTHO,
    PAYLOAD_VISIBLE_TREES,
    PAYLOAD_VISIBLE_RIGHT_PANEL,

	TOGGLE_VISIBLE_SINGLE,
	TOGGLE_VISIBLE_LIST_ENTRY,
	TOGGLE_VISIBLE_LIST,
} from '../constants/action-types';

export function toggleBaseLayer(payload={}){
	payload.type = PAYLOAD_VISIBLE_BASELAYER;
	return {type: TOGGLE_VISIBLE_SINGLE, payload}
}
export function toggleOrthoLayer(payload={}){
	payload.type = PAYLOAD_VISIBLE_ORTHO;
	return {type: TOGGLE_VISIBLE_SINGLE, payload}
}
export function toggleTreesLayer(payload={}){
	payload.type = PAYLOAD_VISIBLE_TREES;
	return {type: TOGGLE_VISIBLE_SINGLE, payload}
}
export function toggleDetailDrawer(payload={}){
	payload.type = PAYLOAD_VISIBLE_RIGHT_PANEL;
	return {type: TOGGLE_VISIBLE_SINGLE, payload}
}

export function toggleAllListItem(payload={}){
	return {type: TOGGLE_VISIBLE_LIST, payload}
}
export function toggleListItem(payload={}){
	return {type: TOGGLE_VISIBLE_LIST_ENTRY, payload}
}