import React          from 'react'
import { connect }    from 'react-redux'
import { Chart }      from 'react-chartjs-2'
import PlantationHeader from '../../views/Plantation/PlantationHeader'
import DashboardHectarage       from './DashboardHectarage'
import DashboardStandCount      from './DashboardStandCount'
import DashboardPlantEfficiency from './DashboardPlantEfficiency'
import { Paper }      from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'


// Adding text to the center of Doughnut Chart
var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function() {
    originalDoughnutDraw.apply(this, arguments)
    const chart = this.chart
    const ctx = chart.chart.ctx

    const fontSize = 1.8
    ctx.font = fontSize + 'em sans-serif'
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'

    let total = 0
    for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
      total += Number(chart.config.data.datasets[0].data[i])
    }
    const
      text = total.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      textX = ((chart.chartArea.left + chart.chartArea.right) / 2),
      textY = ((chart.chartArea.top + chart.chartArea.bottom) / 2)
    ctx.fillText(text, textX, textY)

    const fontSize2 = 0.8
    ctx.font = fontSize2 + 'em sans-serif'
    ctx.textBaseline = 'middle'

    const
      subtext = chart.options.subtext,
      subtextX = ((chart.chartArea.left + chart.chartArea.right) / 2),
      subtextY = ((chart.chartArea.top + chart.chartArea.bottom + 50) / 2)
    ctx.fillText(subtext, subtextX, subtextY)
  }
})

function fetchEstateStats(estates, blocks){
  const
    nameList       = [],
    standCount     = [],
    densityList    = [],
    optimalCount   = [],
    optimalDensity = [],
    hectarage      = []

  const OPTIMAL_DENSITY = 148 // Stand per Hectare

  for (let i = 0; i < estates.length; i++) {
    const el = estates[i]
    let estate_size = 0
    let blocks_stand_count = 0
    if (el.properties.isDeleted === false || el.properties.isDeleted === undefined) {
      for (let j = 0; j < blocks.length; j++) {
        const bl = blocks[j]
        if (bl.properties.media_id === el.media_id){
          blocks_stand_count += bl.properties.count
          estate_size += bl.properties.area
        }
      }
      nameList.push(el.name)
      // estateSummary.estate_size === 0 && estateSummary.stand_count === 0 ?
      //   (this.props.map.properties.estate_size ? this.props.map.properties.estate_size : this.props.map.properties.total_size / 1000000) :
      //   estateSummary.estate_size
      let stand_count = blocks_stand_count === 0 ?
        (el.properties.stand_count ? el.properties.stand_count : el.count) :
        blocks_stand_count

      if (!stand_count)
        stand_count = 0
      standCount.push(stand_count)

      estate_size =
        estate_size === 0 && blocks_stand_count === 0 ?
          (el.properties.estate_size ? el.properties.estate_size : (el.properties.total_size / 1000000).toFixed(0)) :
          estate_size
      hectarage.push(estate_size).toFixed(0)

      densityList.push((stand_count / estate_size).toFixed(2))

      optimalCount.push((estate_size * OPTIMAL_DENSITY) - stand_count).toFixed(0)
      optimalDensity.push(OPTIMAL_DENSITY)
    }
  }
    
  return {
    nameList,
    standCount,
    densityList,
    optimalCount,
    optimalDensity,
    hectarage,
  }
}

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    margin: theme.spacing(5),
    marginLeft: theme.spacing(5),
    alignContent: 'center'
  },
  paper: {
    display: 'flex',
    width:  theme.spacing(87),
    margin: theme.spacing(3)
  }
})

function DashboardChart(props) {
  const { classes, maps, allBlocks } = props
  const estateSummary = fetchEstateStats(maps, allBlocks)
  return (
    <div data-tour='step-18' style={{ paddingBottom: '200px'}}>
      <PlantationHeader page='Plantation Company' isSort={false} isAddEstate={true}/>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <DashboardHectarage estateSummary={estateSummary}/>
        </Paper>
        <Paper className={classes.paper}>
          <DashboardStandCount estateSummary={estateSummary}/>
        </Paper>
        <Paper className={classes.paper} data-tour='step-19'>
          <DashboardPlantEfficiency estateSummary={estateSummary}/>
        </Paper>
      </div>
    </div>
  )
}

function mapStateToProps(state){
  return {
    maps:      state.maps.maps ? state.maps.maps : [],
    allBlocks: state.estate.allBlocks ? state.estate.allBlocks : []
  }
}

export default connect(mapStateToProps, {})(withStyles(styles)(DashboardChart))
