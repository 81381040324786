import { takeEvery, call, put } from "redux-saga/effects"
import {
  QUERY_REQUESTED,
  QUERY_COMPLETED,
  QUERY_ERRORED,
  COMPANY_ID_REQUESTED,
  COMPANY_ID_COMPLETED,
  COMPANY_ID_ERRORED,
  ACCOUNT_INFO_REQUESTED,
  ACCOUNT_INFO_COMPLETED,
  ACCOUNT_INFO_ERRORED,
} from '../constants/action-types'
import axios from 'axios'
import store from '../store/index'

const analyticsUrl = process.env.REACT_APP_ANALYTICS_BASE_URL

export default function* watcherSaga() {
  yield takeEvery(QUERY_REQUESTED, workerQueryRequestedSaga)
  yield takeEvery(COMPANY_ID_REQUESTED, workerCompanyIdRequestedSaga)
  yield takeEvery(ACCOUNT_INFO_REQUESTED, workerAccountInfoRequestedSaga)
}

function authHeader() {
  return { Authorization: 'Bearer ' + store.getState().oidc.user.access_token }
}

function companyEmail() {
  return store.getState().oidc.user.profile.email
}

function* workerQueryRequestedSaga(action) {
  try {
    const payload = yield call(queryRequested, action)
    yield put({ type: QUERY_COMPLETED, payload })
  } catch (e) {
    yield put({ type: QUERY_ERRORED, payload: e })
  }
}

function* workerCompanyIdRequestedSaga(action) {
  try {
    const payload = yield call(companyIdRequested, action)
    yield put({ type: COMPANY_ID_COMPLETED, payload })
  } catch (e) {
    yield put({ type: COMPANY_ID_ERRORED, payload: e })
  }
}

function* workerAccountInfoRequestedSaga(action){
  try {
    const payload = yield call(accountInfoRequested, action)
    yield put({ type: ACCOUNT_INFO_COMPLETED, payload })
  } catch (e) {
    yield put({ type: ACCOUNT_INFO_ERRORED, payload: e })
  }
}

function queryRequested(payload) {
  return axios.post(analyticsUrl + 'contact', payload.payload, {
    headers: authHeader()
  })
  .then((result) => {
    return result.data
  })
}

function companyIdRequested(payload){
  const
    jwtDecode = require('jwt-decode'),
    access_token = jwtDecode(store.getState().oidc.user.access_token)
  return access_token
}

function accountInfoRequested(payload){
  return axios.get(analyticsUrl + 'user/' + payload.payload + '/' + companyEmail(), {
    headers: authHeader()
  })
  .then((result) => {
    if (result.data.isNew){
      // addNewUser(result) //handle later when identity is up and user management is possible
      // Well, identity is up and user management is already possible, FIXME
    }
    return result
  })
}

// function addNewUser(result){
//  return axios.post(analyticsUrl + 'user/' + result.data.companyId + '/new_user' , {email: companyEmail()} ,{
    // headers: authHeader()
//  }).then((result)=>{
//    return result
//  })
// }