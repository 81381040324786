import {connect} from 'react-redux'
import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {toggleDeleteDialog, removeAnnotations,subscribeSocketMessage} from '../actions/index'
import Slide from '@material-ui/core/Slide'

function mapStateToProps(state, props){
	return {
		open: state.estate.deleteDialog.open,
		deleteType: state.estate.deleteDialog.deleteType,
		detail: state.estate.detail,
		blocks: state.estate.blocks, 
	}
}

function mapDispatchToProps(dispatch, props){
	return {
		toggleDeleteDialog: payload => dispatch(toggleDeleteDialog(payload)),
		removeAnnotations: (payload) => dispatch(removeAnnotations(payload)),
		subscribeSocketMessage: (payload) => dispatch(subscribeSocketMessage(payload))
	}
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function DeleteDialog(props) {
	function handleDeletion(){
		var mediaId = null
		switch(props.deleteType){
			case "blocks":
				if(!props.blocks || props.blocks.length === 0){
					props.toggleDeleteDialog("blocks");
					return;
				}
				const annotations = []
				mediaId = props.blocks[0].properties.media_id
				props.blocks.forEach((el) => {
					annotations.push(el._id)
				})

				props.removeAnnotations({
					annotation_ids: annotations,
					media_id: mediaId,
					deleteType: props.deleteType
				})
				
				props.toggleDeleteDialog("")
				break
			default:
				const annotation = []
				mediaId = props.detail.properties.media_id
				annotation.push(props.detail._id)
				if(props.deleteType === "tree")
					props.subscribeSocketMessage({
						topic: 'blocks_processing',
						purpose: 'block'
					})
				props.removeAnnotations({
					annotation_ids: annotation,
					media_id: mediaId,
					deleteType: props.deleteType
				})
				if(props.deleteType === "drain")
					props.removeAnnotations({
						annotation_ids: annotation,
						media_id: mediaId,
						deleteType: 'waterway'
					})
				props.toggleDeleteDialog("")
				break;
		}
	}
	return (
		<div>
			<Dialog
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				onClose={props.toggleDeleteDialog}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">
					{	props.deleteType === "blocks" &&
						"Delete all blocks"
					}
					{	props.deleteType === "block" &&
						"Delete block"
					}
					{	props.deleteType === "tree" &&
						"Delete tree"
					}
					{	props.deleteType === "road" &&
						"Delete road"
					}
					{	props.deleteType === "drain" &&
						"Delete waterway"
					}
					{	props.deleteType === "annotation" &&
						"Delete annotation"
					}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						{
							props.deleteType === "blocks" &&
							"Are you sure you want to delete all blocks?"
						}
						{
							props.deleteType === "block" &&
							"Are you sure you want to delete this block?"
						}
						{
							props.deleteType === "tree" &&
							"Are you sure you want to delete this tree?"
						}
						{
							props.deleteType === "road" &&
							"Are you sure you want to delete this road?"
						}
						{
							props.deleteType === "drain" &&
							"Are you sure you want to delete this waterway?"
						}
						{
							props.deleteType === "annotation" &&
							"Are you sure you want to delete this annotation?"
						}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={props.toggleDeleteDialog}>
						Cancel
					</Button>
					<Button color="secondary" onClick={handleDeletion}>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

const ConnectedDeleteDialog = connect(mapStateToProps, mapDispatchToProps)(DeleteDialog)
export default ConnectedDeleteDialog