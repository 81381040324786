import { connect } from 'react-redux'
import {
  saveDetail,
  showMessage,
  subscribeSocketMessage,
  toggleDeleteDialog,
} from '../../actions/index'
import * as turf from '@turf/helpers'
import * as turfKinks from '@turf/kinks'

import AnnotationDetailBody from './AnnotationDetailBody'
import BlockDetailBody      from './BlockDetailBody'
import TreeDetailBody       from './TreeDetailBody'
import {
  Button,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { tourNextStep } from '../../actions/tour-actions'

const styles = theme => ({
  buttonRoot: {
    borderRadius: 0,
    position: 'absolute',
    bottom: 0
  },
  deleteRoot: {
    position: 'absolute',
    bottom: 31
  },
  messageBlock: {
    textAlign: 'center',
    margin: 'auto',
  }
})

function DetailDrawerBody(props) {
  const { classes, detail, open } = props
  let purpose = ''

  function checkValidGeometry(){
    const block = turf.polygon(detail.geometry.coordinates)
    return turfKinks.default(block).features.length
  }

  if (detail && detail.properties && detail.properties.purpose && open){
    purpose = detail.properties.purpose
  }
  if (purpose === 'tree' || purpose === 'block'){
    return (
      <div>
        { purpose === 'tree' &&
          <TreeDetailBody />
        }
        { purpose === 'block' &&
          <BlockDetailBody/>
        }
        <Button
          variant='contained'
          size='small' 
          fullWidth={true}
          color='default'
          classes={{
            root: classes.deleteRoot,
          }}
          onClick={(e) => {props.toggleDeleteDialog(purpose)}}>
          Delete
        </Button>
        <Button 
          onClick={()=>{
            if (purpose === 'tree') {
              props.subscribeSocketMessage({
                topic: 'blocks_processing',
                purpose: 'block'
              })
              props.saveDetails({feature: detail})
            } else if  (purpose === 'block') {
              if (checkValidGeometry() === 0){
                props.subscribeSocketMessage({
                  topic: 'blocks_processing',
                  purpose: 'block'
                })
                props.saveDetails({feature: detail})
              } else {
                props.showMessage({type: 'error', message: 'This is an invalid '+purpose+'! Please modify it!'})
              }
            } else {
              props.saveDetails({feature: detail})
            }
          }}
          variant='contained' 
          size='small' 
          fullWidth={true} 
          color='primary'
          classes={{
            root: classes.buttonRoot,
          }}>
          Save
        </Button>
      </div>
    )
  } else if (purpose === 'annotation' || purpose === 'road' || purpose === 'drain'){
    return(
      <div>
        <AnnotationDetailBody />
        <Button
          variant='contained'
          size='small'
          fullWidth
          color='default'
          classes={{
            root: classes.deleteRoot,
          }}
          onClick={(e) => {props.toggleDeleteDialog(purpose)}}>
          Delete
        </Button>
        <Button 
          onClick={() => {
            props.saveDetails({feature: detail})
            if (props.tourIsOpen)
              props.goToNextTourStep()
          }}
          variant='contained'
          size='small'
          fullWidth
          color='primary'
          classes={{
            root: classes.buttonRoot,
          }}>
          Save
        </Button>
      </div>
    )
  } else {
    return (
      <Typography className={classes.messageBlock}>
        Select tree or block to view its properties or modify it.
      </Typography>
    )
  }
}

function mapStateToProps(state) {
  return {
    tourIsOpen: state.tour.tourIsOpen,
    detail: state.estate.detail,
    open: state.estate.visible.rightPanel
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    saveDetails: (payload)=> dispatch(saveDetail(payload)),
    showMessage: (payload)=>dispatch(showMessage(payload)),
    subscribeSocketMessage: (payload)=> dispatch(subscribeSocketMessage(payload)),
    toggleDeleteDialog: (payload) => dispatch(toggleDeleteDialog(payload)),
    goToNextTourStep: ()  => dispatch(tourNextStep())
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DetailDrawerBody))
