import React, { Component } from 'react'
import { connect } from 'react-redux'
import Tour from 'reactour'

import { tourNextStep, tourPrevStep, endTour, startTour } from '../actions/tour-actions'
import { toggleDetailDrawer } from '../actions/index'

import '../static/css/onboardingTour.css'

const styles = {
  onboardingStandard: {
		fontFamily: 'Roboto',
		color: 'white',
		backgroundColor: '#2E3345',
		borderRadius: '4px',
		width: '335px'
	},
	prevButton: {
		color: 'white',
		border: '1px solid white',
		backgroundColor: '#2E3345',
		fontSize: '24px',
		fontWeight: 'semi-bold',
		paddingLeft: '10px',
		paddingRight: '10px',
		borderRadius: '4px',
	},
	nextButton: {
		color: '#2E3345',
		border: '1px solid white',
		backgroundColor: 'white',
		fontSize: '24px',
		fontWeight: 'semi-bold',
		paddingLeft: '10px',
		paddingRight: '10px',
		borderRadius: '4px',
		marginLeft: '-40px'
	},
	disabledNextButton: {
		color: '#2E3345',
		border: '#2E3345',
		backgroundColor: '#2E3345',
		fontSize: '24px',
		fontWeight: 'semi-bold',
		paddingLeft: '10px',
		paddingRight: '10px',
		borderRadius: '4px',
		marginLeft: '-40px'
	},
	lastNextButton: {
		color: '#2E3345',
		border: '1px solid white',
		backgroundColor: 'white',
		fontSize: '12px',
		fontWeight: 'semi-bold',
		paddingLeft: '6px',
		paddingRight: '6px',
		paddingTop: '8px',
		paddingBottom: '8px',
		borderRadius: '4px',
		marginLeft: '-42px'
	},
	stepProgress: {
		color: '#919CAB',
		fontFamily: 'Roboto',
		marginRight: '140px'
	},
	stepOne: {
		// maxWidth: '600px !important'
		color: 'red'
	}
}

export const ONBOARDING_STEPS = [
	{
		selector: '',
		content: ({ inDOM }) => (
			<div style={{ paddingBottom: '120px'}}>
				<h1>Welcome to Plantation 4.0</h1>
				In one minute,
				<ul>
					<li>Learn how to count your palm stands</li>
					<li>Optimise your estates by measuring Planting Density and Efficiency</li>
					<li>Identify problems and areas of interest</li>
					<li>Share your estates with your colleagues</li>
				</ul>
				<br />
				{inDOM && `Let's go! 🎉 `}
			</div>
		),
		position: 'top',
		style: { ...styles.onboardingStandard, width: '500px' }
	},
	{
		selector: '[data-tour="step-1"]',
		content: () => { 
			return (
				<div>
					<p>This is the Estates View. It lists all estates uploaded, along with key metrics such as area, stand count and planting density.</p>
					<p>Click on the <strong>Demo Plantation Estate</strong> to proceed with the tutorial.</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard }
	},
	{
		selector: '#gmap',
		content: () => (
			<div>
				<p>To pan around, hold your left mouse button before moving the mouse, or use the arrow keys.</p>
				<p>Zoom in and out by scrolling your wheel mouse up and down or clicking on + / -</p>
				<br/>
				<br/>
			</div>
		),
		position: [243,70],
		style: { ...styles.onboardingStandard }
	},
	{
		selector: '[data-tour="step-3"]',
		content: () => { 
			return (
				<div>
					<p>To see all the trees in the estate, select the checkbox.</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard }
	},
	{
		selector: '#gmap',
		content: () => { 
			return (
				<div>
					<p>All the tree stands in this estate has been automatically geotagged with a known position. You can use this data in aggregate, or annotate them individually.</p>
					<p>Click on any red dot for information about only that tree. When you’re done, click next to continue with the tutorial.</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard },
		position: [243,70],
	},
	{
		selector: '[data-tour="step-5"]',
		content: () => { 
			return (
				<div>
					<p>Let’s look at aggregate data. To visualize your stands by block, click on Blocks to list your blocks in this estate</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard }
	},
	{
		selector: '[data-tour="step-6"]',
		content: () => { 
			return (
				<div>
					<p>Select a block</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard }
	},
	{
		selector: '#gmap',
		content: () => { 
			return (
				<div>
					<p>This is the selected block, the number in the field reflects the number of stands in this block.</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard },
		position: [243,70],
	},
	{
		selector: '[data-tour="step-8"]',
		content: () => { 
			return (
				<div>
					<p>Additional information can be found on the right panel.</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard }
	},
	{
		selector: '[data-tour="step-9"]',
		content: () => { 
			return (
				<div>
					<p>To annotate the map with more information, click Annotations</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard }
	},
	{
		selector: '#gmap',
		content: () => { 
			return (
				<div>
					<p>Annotations are user defined layers of data for better plantation management.</p>
					<p>Click on unhealthy to see a list of <strong>UNHEALTHY</strong> trees, marked by the field workers.</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard },
		position: [243,220]
	},
	{
		selector: '#gmap',
		content: () => { 
			return (
				<div>
					<p>Try creating your own annotations. <strong>Click on the + symbol</strong> besides Annotations and select <strong>Polygon</strong>.</p>
					<p>Create a polygon around the Unhealthy Trees.</p>
					<p>When you're done click Next</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard },
		position: [243,70]
	},
	{
		selector: '[data-tour="step-9"]',
		content: () => { 
			return (
				<div>
					<p>Let's edit an annotation to add more info.</p>
					<p>Click on any of the annotations to open the info panel on the right</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard },
		position: [243,70]
	},
	{
		selector: '[data-tour="step-8"]',
		content: () => { 
			return (
				<div>
					<p>Add detailed descriptions to your annotations here.</p>
					<p>But for this tutorial, let's just change the colour and hit the save button :)</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard }
	},
	{
		selector: '[data-tour="step-15"]',
		content: () => { 
			return (
				<div>
					<p>There are more features on the Estates View, such as adding roads, waterways, or managing tree stand information.</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard }
	},
	{
		selector: '[data-tour="step-16"]',
		content: () => { 
			return (
				<div>
					<p>Welcome back to the home page!</p>
					<p>Let’s check out the Reports View. </p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard }
	},
	{
		selector: '[data-tour="step-16"]',
		content: () => { 
			return (
				<div>
					<p>Click on Reports to continue.</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard }
	},
	{
		selector: '[data-tour="step-18"]',
		content: () => { 
			return (
				<div>
					<p>In Reports View, we provide a dashboard of how your estates are doing across Estates and Blocks. </p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard },
		position: [800,150]
	},
	{
		selector: '[data-tour="step-19"]',
		content: () => { 
			return (
				<div>
					<p>For example, the planting efficiency chart shows the current stand count in the estate.</p>
					<p 
						id='planting-density-link'
						onClick={() => window.open('https://www.google.com/search?q=planting+density+oil+palm&oq=planting+density')}
					>
						Learn more about planting density
					</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard }
	},
	{
		selector: '[data-tour="step-20"]',
		content: () => { 
			return (
				<div>
					<p>Are you ready to upload your own maps?</p>
					<p>You will need to prepare a stitched imagery of your estate in Google Tiles format in order to upload it to Plantation 4.0.</p>
					<p>Click on “Add Estate” to start uploading estate maps</p>
				</div>
			)
		},
		style: { ...styles.onboardingStandard }
	}
]

const DISABLED_NEXT_BUTTONS_STEPS = [1, 3, 5, 6, 9, 10, 12, 16]

class OnboardingTour extends Component {
	
	isNextButtonDisabled = () => {
		if (DISABLED_NEXT_BUTTONS_STEPS.includes(this.props.step))
			return true 

		return false
	}

	handleCurrentStep = (currentStep) => {		
		if (currentStep === 12) {
			// We want to open the most recent annotation's information bar 
			this.props.openDetailDrawer()
		}

		if (currentStep === 15) {
			// Go back to '/'
			this.props.history.push('/')
		}

		if (currentStep === 19) {
			// Go back to '/'
			this.props.history.push('/')
		}
	}

	handleTourPrevButtonClick = async () => {
		if (this.props.step === 2 || this.props.step === 17)
			await this.props.history.push('/')

		if (this.props.step === 15)
			await this.props.history.push('/map/' + this.props.demoEstateId) 

		if (this.props.step === 19)
			await this.props.history.push('/report')

		await this.props.onPrevStep()
	}

	handleTourNextButtonClick = async () => {
		if (this.props.step === 16 || this.props.step === 20) 
			await this.props.onNextStep(500)

		this.props.onNextStep()
	}

	componentDidMount() {
		this.props.onStartTour()
	}

	render() {
		return (
			<div>
				<Tour 
					steps={ONBOARDING_STEPS} 
					isOpen={this.props.tourIsOpen} 
					onRequestClose={() => this.props.onEndTour()} 
					badgeContent={(currentStep) => <div>{currentStep} of {ONBOARDING_STEPS.length}</div>}
					rounded={0} 
					showNavigation={false} 
					showNavigationNumber={false} 
					prevButton={
						<button 
							id="prev-button" 
							style={styles.prevButton} 
							onClick={() => this.handleTourPrevButtonClick()}
						> 
							{ "<" } 
						</button>
					}
					nextButton={
						<button 
							id="next-button" 
							style={DISABLED_NEXT_BUTTONS_STEPS.includes(this.props.step) ? styles.disabledNextButton : styles.nextButton} 
							onClick={() => this.handleTourNextButtonClick()}
							disabled={this.isNextButtonDisabled()}
						> 
							{ ">" } 
						</button>
					}
					lastStepNextButton={
						<button
							id="last-next-button"
							style={styles.lastNextButton} 
							onClick={() => this.props.onEndTour()}
						>
							Done
						</button>
					}
					disableDotsNavigation={true}
					goToStep={this.props.step}
					getCurrentStep={currentStep => this.handleCurrentStep(currentStep)}
					closeWithMask={false}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	let demoEstateId = ''

	if (state.maps.maps) {
		for (const map of state.maps.maps) 
			if (map.demo) 
				demoEstateId = map._id
	}

	return { 
		step: state.tour.step,
		tourIsOpen: state.tour.tourIsOpen,
		user: state.oidc.user,
		demoEstateId
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onNextStep: (delayTime) => dispatch(tourNextStep(delayTime)),
		onPrevStep: () => dispatch(tourPrevStep()),
		onStartTour: () => dispatch(startTour()),
		onEndTour: () => dispatch(endTour()),
		openDetailDrawer: () => dispatch(toggleDetailDrawer())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingTour)
