import React from 'react'
import {TileLayer} from 'react-leaflet'
import {connect} from 'react-redux'

function mapStateToProps(state, props){
	return {
		visible: state.estate.visible[props.layerName],
		map: state.estate.map,
		opacity: state.estate.opacity[props.layerName],
	}
}

function RasterLayer(props) {
	return (
		(props.map) && (!props.isWMSTileLayer) &&
		<TileLayer
			minZoom={12}
			maxZoom={20}
			zoomReverse={false}
			tms={true}
			url={props.visible ? props.url : ""}
			bounds={props.map.properties.bounds}
			zIndex={10000}
			opacity={Number(props.opacity)/100}
		/>
	)
}

const ConnectedRasterLayer = connect(mapStateToProps)(RasterLayer)
export default ConnectedRasterLayer