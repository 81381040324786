import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  hideMessage,
  getMap as getMapRequest
} from '../../actions/index'
import MapViewer         from './MapViewer'
import AnnotationDrawers from './AnnotationDrawers'
import DetailDrawers     from './DetailDrawers'

import classNames from 'classnames'
import {
  IconButton,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core'
import {
  Close,
  CheckCircle,
  Error,
  Info,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const drawerWidth = 240

const styles = theme => ({
  mapBox: {
    width: '100%',
    height: 'calc(100vh - 70px)',
    display: 'inline-flex',
  },
  mapComponent: {
    width: '100%',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  mapComponentRightShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  mapComponentExpandRight: {
    width: '100%',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  success: {
    backgroundColor: theme.palette.primary.main,
  },
  snackbarBox: {
    display: 'flex',
    alignItems: 'center',
  },
  iconPadding: {
    marginRight: '5px',
  },
  error: {
    backgroundColor: '#D32F2F',
  },
  info: {
    backgroundColor: '#1976D2',
    minWidth: '600px',
  }
})

function Estate({
  classes,
  hideMessage,
  getMapRequest,
  match,
  user,
  openRightDrawer,
  messageDisplay,
  map,
}) {
  const [ leftDrawerOpen, setLeftDrawerOpen ] = useState(true)

  useEffect(() => {
    if (match?.params?.id)
      getMapRequest({ id: match.params.id })
  }, [getMapRequest, match])

  function handleClose() {
    hideMessage()
  }

  return (
    <div>
      <div className={classes.mapBox}>
        <AnnotationDrawers
          open={leftDrawerOpen}
          onToggle={() => setLeftDrawerOpen(!leftDrawerOpen)}
        />
        <div
          className={classNames(classes.mapComponent, {
            [classes.mapComponentLeftShift] : leftDrawerOpen,
            [classes.mapComponentRightShift] : openRightDrawer,
            [classes.mapComponentExpandRight] : !openRightDrawer,
          })}>
          { map?._id && <MapViewer id={match.params.id} /> }
        </div>
        <DetailDrawers />
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={messageDisplay.message !== ''}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}>
        <SnackbarContent
          message={
            <span id='message-id' className={classes.snackbarBox}>
              { messageDisplay.type === 'success' && 
                <CheckCircle className={classes.iconPadding}/>
              }
              { messageDisplay.type === 'error' && 
                <Error className={classes.iconPadding} />
              }
              { messageDisplay.type === 'info' && 
                <Info className={classes.iconPadding} />
              }
              { messageDisplay.message }
            </span>
          }
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              onClick={handleClose}>
              <Close />
            </IconButton>
          ]}
          classes={{
            root: classes[messageDisplay.type]
          }}
        />
      </Snackbar>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user:            state.oidc.user,
  openRightDrawer: state.estate.visible.rightPanel,
  messageDisplay:  state.estate.messageDisplay,
  map:             state.estate.map
})

const mapDispatchToProps = (dispatch, state) => ({
  hideMessage:   payload => dispatch(hideMessage(payload)),
  getMapRequest: payload => dispatch(getMapRequest(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Estate))
