import { createRef, Component } from 'react'
import { connect } from 'react-redux'
import 'leaflet-editable/src/Leaflet.Editable'
import L from 'leaflet'
import { Map, FeatureGroup,ZoomControl } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
import bboxPolygon from '@turf/bbox-polygon'
import bbox from '@turf/bbox'

import { formatDecimal } from '../../utils/formatter'
import {
  clearMapState,
  getAnnotationsData,
  getPointElevation,
  hideTrees,
  toggleDrawing,
  unselectDetail,
  zoomLevelChanged,
  clearUpdates,
} from '../../actions/index'

import BaseLayer           from '../../components/BaseLayer'
import CustomDrawControl   from '../../components/CustomDrawControl'
import CustomEditControl   from '../../components/CustomEditControl'
import DeleteDialog        from '../../components/DeleteDialog'
import ImportKMLDialog     from '../../components/ImportKMLDialog'
import PolygonEditorClass  from '../../components/PolygonEditor'
import PolylineEditorClass from '../../components/VertexMarkerClass'
import RasterLayer         from '../../components/RasterLayer'
import SocketElement       from '../../components/SocketElement'
import VectorLayer         from '../../components/VectorLayer'
// import StaticVectorLayer   from '../../components/StaticVectorLayer'

class MapViewer extends Component {
  state = {
    measurementLayer: null,
    zoom: 12,
    canvas: false,
    preElevation: 0.0,
    leafletBounds : new L.latLngBounds(this.props.map.properties.properties.bounds)
  }
  mapRef = createRef()
  getMap() {
    return this.mapRef.current.leafletElement
  }
  getMapBbox() {
    const map = this.getMap()
    const bounds = map.getBounds()
    const bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()]
    const geometry = bboxPolygon(bbox)
    return geometry
  }
  UNSAFE_componentWillReceiveProps=(nextProps)=>{
    if (nextProps.elevation !== this.state.preElevation) {
      this.setState({
        preElevation: nextProps.elevation,
      })
      const popupContent = '<p>Latitude: ' + formatDecimal(this.state.measurementLayer._latlng.lat, 5) + '</p> ' 
        + '<p>Longitude: ' + formatDecimal(this.state.measurementLayer._latlng.lng, 5) + '</p></p>Elevation: ' + formatDecimal(nextProps.elevation, 1) + ' m</p>'
        this.state.measurementLayer.bindPopup().setPopupContent(popupContent).openPopup()
    }
  }
  componentDidUpdate=(prevProps)=>{
    if (this.props.detail) {
      if ((!prevProps.detail)|| prevProps.detail._id !== this.props.detail._id) {
        this.props.getAnnotationsData({
          purpose: 'block, annotation, road, drain',
          media_id: this.props.map.media_id
        })
        var geometryBbox = bboxPolygon(bbox(this.props.detail.geometry))
        this.getMap().fitBounds(L.geoJson(geometryBbox).getBounds())
      }
    }
    if ((!prevProps.updated || !prevProps.updated.block) && this.props.updated['block']) {
      this.props.getAnnotationsData({
      //  geometry: this.state.leafletBounds,
        purpose: 'block,annotation,road,drain',
        media_id: this.props.map.media_id,
      })
      this.props.clearUpdates({purpose: 'block'})
    }
  }
  componentDidMount=()=> {
    this.getMap().once('moveend', (e)=>{

      // TENTATIVELY REMOVING THIS FOR SIMEDARBY DEMO DEVELOPMENT
      // this.getMap().setMaxBounds(this.getMap().getBounds())

      this.props.getAnnotationsData({
          //geometry: leafletBounds,
          purpose: 'block,annotation,road,drain',
          media_id: this.props.map.media_id,
      })
    })
    this.getMap().fitBounds((new L.latLngBounds(this.props.map.properties.properties.bounds)))
    this.getMap().setZoom(this.props.map.properties.properties.minZoom)

    L.drawLocal.draw.toolbar.buttons.polyline = 'Measure distance'
    L.drawLocal.draw.toolbar.buttons.polygon = 'Measure area'
    L.drawLocal.draw.toolbar.buttons.marker = 'Measure elevation'

    L.drawLocal.draw.handlers.polyline.tooltip.start = 'Click to start drawing line to measure distance'
    L.drawLocal.draw.handlers.marker.tooltip.start = 'Place marker on the map to measure point elevation'
    L.drawLocal.draw.handlers.polygon.tooltip.start = 'Click to start drawing shape to measure area'
  }
  componentWillUnmount=(e) => {
    this.props.clearMapState()
  }
  onViewPortChanged = ()=>{
    const bbox = this.getMapBbox()
    const map = this.getMap()
    if (map.getZoom() > 18) {
      this.props.getAnnotationsData({
        purpose: 'tree',
        geometry: bbox,
        media_id: this.props.map.media_id
      })
    } else {
      this.props.hideTrees()
    }
    this.props.zoomLevelChanged({ zoom: map.getZoom()})
    let prefix = 'track-zoom-'
    for(let class_ of map._container.classList) {
      if (class_.indexOf(prefix) !== -1 ) {
        L.DomUtil.removeClass(map._container, class_)
      }
    }
    L.DomUtil.addClass(map._container, prefix+map.getZoom())
  }
  calculateDistance = (latLngArray) => {
    let distance = 0
    for(let i = 0; i < latLngArray.length - 1; i++) {
      distance = distance + latLngArray[i].distanceTo(latLngArray[i + 1])
    }
    return distance
  }
  onMeasurementCreated = (e) => {
    const map = this.getMap()
    if (this.state.measurementLayer != null) {
      map.removeLayer(this.state.measurementLayer)
    }
    this.setState({
      measurementLayer: e.layer,
    })
    switch(e.layerType) {
      case 'marker':
        this.props.getPointElevation({latlng: e.layer._latlng})
        break
      case 'polyline':
        const distance = this.calculateDistance(e.layer._latlngs)
        const popupDistanceContent = '<p>' + formatDecimal(distance, 1) + ' m</p> '
        this.state.measurementLayer.bindPopup().setPopupContent(popupDistanceContent).openPopup()
        break
      case 'polygon':
        const area = L.GeometryUtil.geodesicArea(e.layer.getLatLngs()[0])
        const areaHectares = Number(area) * 0.0001
        const areaAcres = Number(area) * 0.00025
        const popupAreaContent = '<p>' + formatDecimal(areaHectares, 2) + ' ha</p> '
        + '<p>' + formatDecimal(areaAcres, 2) + ' ac </p>'
        this.state.measurementLayer.bindPopup().setPopupContent(popupAreaContent).openPopup()
        break
      default:
        break
    }
  }

  render = () => {
    const { zoom, map, toggleDrawing, unselectDetail } = this.props
    const { canvas, measurementLayer } = this.state

    return (<>
      <Map preferCanvas={canvas}
        id='gmap'
        zoom={zoom}

        // TENTATIVELY REPLACING THIS FOR SIMEDARBY DEMO DEVELOPMENT
        // minZoom={map.properties.properties.minZoom}

        minZoom={1}
        maxZoom={map.properties.properties.maxZoom}
        zoomControl={false}
        attributionControl={false}
        onZoomEnd={this.onViewPortChanged}
        onMoveEnd={this.onViewPortChanged}
        ref={this.mapRef}
        onPopupClose={(e) => {
          if (e.popup !== null && measurementLayer !== null &&
            e.popup._leaflet_id === measurementLayer._popup._leaflet_id) {
            this.getMap().removeLayer(measurementLayer)
            this.setState({ preElevation: 0 })
          }
        }}
        editable
        editOptions={{
          lineGuideOptions: { color: 'black' },
          polylineEditorClass: PolylineEditorClass,
          polygonEditorClass: PolygonEditorClass
        }}>
        <CustomEditControl />
        <CustomDrawControl />
        <ZoomControl position='bottomright' />
        <RasterLayer layerName='ortho' isWMSTileLayer={false}
          url={map.properties.properties.tile_location + '/{z}/{x}/{y}.png'}
        />
        <BaseLayer />
        <VectorLayer layerName='blocks' />
        <VectorLayer layerName='drains' />
        <VectorLayer layerName='roads' />
        <VectorLayer layerName='annotations' />
        <VectorLayer layerName='trees'/>
        {/* <StaticVectorLayer /> */}
        <FeatureGroup>
          <EditControl
            onCreated={(e) => {
              this.onMeasurementCreated(e)
            }}
            onDrawStart={(e) => {
              toggleDrawing({})
              unselectDetail()
            }}
            position='topright'
            draw={{
              //disable most of the markers, only allow circlemarker to edit
              polyline: {
                repeatMode: false,
                shapeOptions: {
                  color: '#ffae00',
                  opacity: '1.0',
                }
              },
              polygon: {
                showArea: true,
                shapeOptions: {
                  color: 'blue',
                  opacity: '1.0',
                }
              },
              circle: false,
              marker: {
                repeatMode: false,
                shapeOptions: {
                  color: '#CC3300',
                }
              },
              rectangle: false,

              circlemarker: false,
            }}
            edit={{
              edit: false,
              //TODO: remove all layers options to prevent user from clearing all
              remove: false,
            }}
          />
        </FeatureGroup>
      </Map>
      <ImportKMLDialog />
      <DeleteDialog />
      <SocketElement />
    </>)
  }
}

function mapStateToProps(state, ownProps) {
  return {
    map:       state.estate.map,
    elevation: state.estate.elevation,
    detail:    state.estate.detail,
    zoom:      state.estate.zoom,
    updated:   state.socket.updated,
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    getAnnotationsData: payload => dispatch(getAnnotationsData(payload)),
    zoomLevelChanged:   payload => dispatch(zoomLevelChanged(payload)),
    hideTrees:          payload => dispatch(hideTrees(payload)),
    clearMapState:      payload => dispatch(clearMapState(payload)),
    getPointElevation:  payload => dispatch(getPointElevation(payload)),
    clearUpdates:       payload => dispatch(clearUpdates(payload)),
    toggleDrawing:      payload => dispatch(toggleDrawing(payload)),
    unselectDetail:     payload => dispatch(unselectDetail(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapViewer)
