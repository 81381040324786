import { 
    START_DRAWING,
    COMPLETED_DRAWING,
	TOGGLE_DRAWING,
} from '../constants/action-types';

export const startDrawing=(payload={})=>{
	return {type: START_DRAWING, payload}
}
export const completedDrawing=(payload={})=>{
	return {type: COMPLETED_DRAWING, payload}
}
export const toggleDrawing=(payload = {})=>{
	return {type:TOGGLE_DRAWING, payload}
}