export const ANNOTATIONS_DATA_REQUESTED = 'ANNOTATIONS_DATA_REQUESTED'
export const ANNOTATIONS_DATA_LOADED = 'ANNOTATIONS_DATA_LOADED'
export const ANNOTATIONS_API_ERRORED = 'ANNOTATIONS_API_ERRORED'

export const BLOCKS_DATA_REQUESTED = 'BLOCKS_DATA_REQUESTED'
export const BLOCKS_DATA_LOADED = 'BLOCKS_DATA_LOADED'
export const BLOCKS_DATA_ERRORED = 'BLOCKS_DATA_ERRORED'

export const ADD_ANNOTATION_REQUESTED = 'ADD_ANNOTATION_REQUESTED'
export const ADD_ANNOTATION_COMPLETED = 'ADD_ANNOTATION_COMPLETED'
export const REMOVE_ANNOTATION_REQUESTED = 'REMOVE_ANNOTATION_REQUESTED'
export const REMOVE_ANNOTATION_COMPLETED = 'REMOVE_ANNOTATION_COMPLETED'

export const GET_MAPS_REQUESTED = 'GET_MAPS_REQUESTED'
export const GET_MAPS_COMPLETED = 'GET_MAPS_COMPLETED'
export const GET_MAPS_ERRORED = 'GET_MAPS_COMPLETED'

export const GET_MAP_REQUESTED = 'GET_MAP_REQUESTED'
export const GET_MAP_ERRORED = 'GET_MAP_ERRORED'
export const GET_MAP_COMPLETED = 'GET_MAP_COMPLETED'

export const POINT_ELEVATION_REQUESTED = 'POINT_ELEVATION_REQUESTED'
export const POINT_ELEVATION_COMPLETED = 'POINT_ELEVATION_COMPLETED'
export const POINT_ELEVATION_ERRORED = 'POINT_ELEVATION_ERRORED'

export const SAVE_DETAIL_REQUESTED = 'SAVE_DETAIL_REQUESTED'
export const SAVE_DETAIL_COMPLETED = 'SAVE_DETAIL_COMPLETED'
export const SAVE_DETAIL_ERRORED = 'SAVE_DETAIL_ERRORED'

export const UPLOAD_FILE_REQUESTED = 'UPLOAD_FILE_REQUESTED'
export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS'
export const UPLOAD_FILE_ERRORED = 'UPLOAD_FILE_ERRORED'
export const UPLOAD_FILE_COMPLETED = 'UPLOAD_FILE_COMPLETED'

export const QUERY_REQUESTED = 'QUERY_REQUESTED'
export const QUERY_COMPLETED = 'QUERY_COMPLETED'
export const QUERY_ERRORED = 'QUERY_ERRORED'

export const GET_TOKEN_REQUESTED = 'GET_TOKEN_REQUESTED'
export const GET_TOKEN_COMPLETED = 'GET_TOKEN_COMPLETED'
export const GET_TOKEN_ERRORED = 'GET_TOKEN_ERRORED'

export const PUT_MAP_REQUESTED = 'PUT_MAP_REQUESTED'
export const PUT_MAP_COMPLETED = 'PUT_MAP_COMPLETED'
export const PUT_MAP_ERRORED = 'PUT_MAP_ERRORED'

export const COMPANY_ID_REQUESTED = 'COMPANY_ID_REQUESTED'
export const COMPANY_ID_COMPLETED = 'COMPANY_ID_COMPLETED'
export const COMPANY_ID_ERRORED = 'COMPANY_ID_ERRORED'

export const ACCOUNT_INFO_REQUESTED = 'ACCOUNT_INFO_REQUESTED'
export const ACCOUNT_INFO_COMPLETED = 'ACCOUNT_INFO_COMPLETED'
export const ACCOUNT_INFO_ERRORED = 'ACCOUNT_INFO_ERRORED'
