import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from "react-redux"
import async from 'async'

import Button from '@material-ui/core/Button'
import TextField    from '@material-ui/core/TextField'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { getToken, putMap } from '../../actions/index'

const API_URL = process.env.REACT_APP_API_URL
if (!API_URL) throw new Error('Environment Variable REACT_APP_API_URL is required.')

const styles = (theme) => {
    return {
		title: {
			fontFamily: 'Roboto',
			fontSize: 14
		},
		subtitle: {
			fontFamily: 'Roboto',
			fontSize: 14,
			width: 350,
			marginTop: 5
		},
		inputFile:{
			width: 300,
			height: 50,
		},
		uploadButton: {
			border: '2px solid #2B3940',
			color: '#2B3940',
			fontSize: 14,
			width: 140,
			height: 40,
			borderRadius: 4,
			marginRight: 10
		},
		textLink: {
			color: 'blue',
			"&:hover": {
				color: 'black',
				cursor: 'pointer'
			 }
		},
		button: {
			marginRight: '12px', 
			backgroundColor: '#2B3940',
			color: 'white'
		}
	}
}

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
		token: state.media.token,
		uploadedMediaId: state.media.uploadedMediaId,
		companyId: state.query.companyId
	}
}

function mapDispatchToProps(dispatch, state) {

	return {
		getToken: payload => dispatch(getToken(payload)),
		putMap: payload => dispatch(putMap(payload))
	}
}

class UploadGoogleTiles extends Component {

    static defaultProps = {
		projectID:    'plantation',
		projectTitle: '',
		format:       null,
		companyID: ''
	}

	constructor(props){
		super(props)
		this.state = {
			uploadStatus: 'pending',
			mosaicName:   '',
			projectID: 'plantation',
			companyID: '',
			fileArray:               [], // File objects
			fileArrayImageIndices:   [], // index into fileArray
			fileArrayImageZoomLevel: [], // zoomLevels for index
	
			rootHTMLfile: undefined,
			rootKMLfile:  undefined,
			thumbnail:    undefined,
			thumbnailUploaded: false,
	
			previewImages:      [],      // Array of image contents
			orthomosaicBounds:  [],
			mapMinZoom:         -1,
			mapMaxZoom:         -1,
			highWatermarkIndex: -1,

			step1error: '',
			step2error: '',
			step3error: '',
			step4error: '',
			stepIndex:  0,

			filesUploaded: 0,
			estimatedEstateSize: 0
		}
	}
    
    componentDidMount(){
		this.props.getToken()
    }
    componentDidUpdate(prevProps){
		console.log(this.state.companyID)
    }

    hasHTML5features = () => {
		if (!window.FileReader) {
			console.error('Browser does not support FileReader (HTML5)')
			this.setState({ step2error: 'We got a problem here, you have a more modern browser by any chance?' })
			return false
		}
		if (!window.Worker) {
			console.error('Browser does not support Worker (HTML5)')
			this.setState({ step2error: 'We got a problem here, you have a more modern browser by any chance?' })
			return false
		}
		return true
	}
	
	
	handleDroneMapping(){
		console.log('Clicked')
	}
    
    ///// STEP 1 /////

	handleDirectorySelected = (e) => {
		let files = e.target.files, // FileList (not Array)
			fileArray               = [],
			fileArrayImageIndices   = [],
			fileArrayImageZoomLevel = [],
			rootKMLfile  = null,
			rootHTMLfile = null,
			totalSize    = 0,
			estimatedEstateSize = 0

		// Reset all state for managing upload
		// this.setState(UploadGoogleTiles.initialUploadState)

		for (let i = 0; i < files.length ; i++) {
			let f = files[i]

			if (f.webkitRelativePath.substring(0, 13) !== 'google_tiles/') {
				console.error(f.webkitRelativePath.substring(0, 13), 'is not google_tiles/')
				return this.setState({ step1error: 'Please select a directory called "google_tiles"' })
			}

			if (f.type === 'image/png') {
                fileArrayImageIndices.push(fileArray.length) // before increment
                if(f.webkitRelativePath.match(/google_tiles\/([0-9]+)/) !== null){
					fileArrayImageZoomLevel.push(parseInt(f.webkitRelativePath.match(/google_tiles\/([0-9]+)/)[1]))
					// count size based on the resolution of images in zoom level 20, excluding transparent file
					if(f.webkitRelativePath.match(/google_tiles\/20/) !== null && f.size > 1000){
						estimatedEstateSize += 1
					}
				}
                else{
					fileArrayImageZoomLevel.push(-1)
					return this.setState({ step1error: 'Please ensure that the zoom level directories are properly named within google_tiles folder (eg. 13, 14, ..., 20)'})
                }
                fileArray.push(f)
			}
			// Current preferred way of detecting KML files. 
			// Might fail when the MIME Type is not specified correctly on target system.
			// For example, on Windows, HKLM\Software\Classes\.kml must exist with Content Type: application/vnd.google-earth.kml+xml
			else if (f.type === 'application/vnd.google-earth.kml+xml') {
				fileArray.push(f)
				if (!rootKMLfile && f.webkitRelativePath === 'google_tiles/' + f.name)
					rootKMLfile = f
			}
			// Backup plan: still read the extension of the filename to match {some name}.kml
			else if (f.name.length > 4 && f.name.substring(f.name.length - 4, f.name.length) === '.kml') {
				fileArray.push(f)
				if (!rootKMLfile && f.webkitRelativePath === 'google_tiles/' + f.name)
					rootKMLfile = f
			}
			else if (f.type === 'text/html') {
				if (rootHTMLfile) {
					console.error('Another HTML file', rootHTMLfile, 'is already found. Make sure there is only one HTML present.')
					return this.setState({ step1error: 'Please ensure the directory only have one HTML file' })
				}
				rootHTMLfile = f
				fileArray.push(f)
				this.extractMetadataFromHTMLFile(f)
			}
			else
				console.log('Ignoring file:', f.webkitRelativePath, f.type, f.size)

			// Prepopulate some fields for display so that it doesn't get rerendered over and over
			// f.prettySize = prettyFileSize(f.size)
			totalSize += f.size
		}

		console.log('fileArray.length:', fileArray.length,
			'fileArrayImageIndices.length:', fileArrayImageIndices.length,
			'fileArrayImageZoomLevel.length:', fileArrayImageZoomLevel.length,
			'rootHTMLfile:', rootHTMLfile,
			'rootKMLfile:', rootKMLfile,
			'estimated estate size:', estimatedEstateSize)

		if(estimatedEstateSize > 0){
			// zoom level 20 resolution is 0.149 m / pixel (# number of non-transparent images * pixel^2 * resolution^2)
			estimatedEstateSize = estimatedEstateSize * 256 * 256 * 0.149 * 0.149 * 0.0001
		}
		if (fileArray.length === 0)
			return this.setState({ step1error: 'Please select a google_tiles directory with some files' })

		if (!rootHTMLfile)
			return this.setState({ step1error: 'HTML file in google_tiles directory is missing' })

		if (!rootKMLfile)
			return this.setState({ step1error: 'KML file in google_tiles directory is missing' })

		this.setState({
			fileArray:               fileArray,
			fileArrayImageIndices:   fileArrayImageIndices,
			fileArrayImageZoomLevel: fileArrayImageZoomLevel,
			rootHTMLfile:            rootHTMLfile,
			rootKMLfile:             rootKMLfile,
			step1error:              '',
			totalSize:               totalSize,
			companyID:				 String(this.props.companyId),
			estimatedEstateSize:	 estimatedEstateSize
        })
    }

    extractMetadataFromHTMLFile = (file) => {
		let reader = new FileReader()

		reader.onload = (e) => {
			let html         = e.target.result.toString(),
				boundsRegex  = /google.maps.LatLng\((-?[0-9.]+), *(-?[0-9.]+)\)/g,
				boundsRegexAlt = /GLatLng\((-?[0-9.]+), *(-?[0-9.]+)\)/g,
				maxZoomRegex = /var mapMaxZoom = *([0-9]+)/g,
				minZoomRegex = /var mapMinZoom = *([0-9]+)/g,
				bounds       = [],
				mapMaxZoom   = null,
				mapMinZoom   = null,
				match

			while ((match = boundsRegex.exec(html) || boundsRegexAlt.exec(html)) !== null) {
				bounds.push([parseFloat(match[1]), parseFloat(match[2])])
			}

			if (bounds.length !== 2)
				return console.error('Cannot find bounds from HTML. Found:', bounds)

			while ((match = maxZoomRegex.exec(html)) !== null) {
				if (mapMaxZoom !== null)
					return console.error('Multiple mapMaxZoom found, using the first one', mapMaxZoom, match[1])
				mapMaxZoom = parseInt(match[1])
			}

			while ((match = minZoomRegex.exec(html)) !== null) {
				if (mapMinZoom !== null)
					return console.error('Multiple mapMinZoom found, using the first one', mapMinZoom, match[1])
				mapMinZoom = parseInt(match[1])
			}
			for (let i = 0; i < this.state.fileArrayImageIndices.length; i++) {
                if (this.state.fileArrayImageZoomLevel[i] === mapMinZoom)
					this.previewImageContents(this.state.fileArray[this.state.fileArrayImageIndices[i]])
			}
			this.setState({
				orthomosaicBounds: bounds,
				mapMinZoom:        mapMinZoom,
				mapMaxZoom:        mapMaxZoom
			})
		}
		reader.readAsBinaryString(file)

		this.setState({ mosaicName: file.name.split('.html')[0] })
	}
    previewImageContents = (file) => {
		let reader = new FileReader()
		reader.onload = (e) => {
			console.log('previewing image:', file.webkitRelativePath)
			let contents      = e.target.result,
				previewImages = this.state.previewImages
			previewImages.push(contents)
			this.setState({ previewImages: previewImages })
		}
		reader.readAsDataURL(file)
    }
    
    ///// STEP 2 /////

	handleChooseThumbnail = (e) => {
		if (!this.hasHTML5features) return
		let thumbnail = e.target.files[0]

		if (!thumbnail.type.match(/image.*/)) {
			this.setState({ step2error: 'Please select an image as thumbnail' })
			return
		}
		else if (thumbnail.size === 0) {
			this.setState({ step2error: 'Thumbnail is empty ..' })
			return
		}
		else if (thumbnail.size > 10000000) {
			this.setState({ step2error: 'Thumbnail file size is too big!!' })
			return
		}

		let reader = new FileReader()
		reader.onload = (e) => {
			thumbnail.content = e.target.result
			this.setState({
				thumbnail: thumbnail,
				step2error: '',
				thumbnailUploaded: true
			})
		}
		reader.readAsDataURL(thumbnail)
	}

    ///// STEP 3 /////

	handleMosaicNameChange = (e) => {
		this.setState({
			mosaicName: e.target.value,
			step3error: ''
		})
    }
    
    ///// STEP 4 /////

	// Worker Factory
	workers = []
	workerAvailable = []

	createWorkers = (totalWorkers) => {
		this.workers = []
		this.workerAvailable = []

		for (let i = 0; i < totalWorkers; i++) {
			this.workers.push(new Worker('/upload-worker.js'))
			this.workerAvailable.push(true)
		}
	}

	destroyWorkers = () => {
		this.workers.forEach(w => { w.terminate() })
		this.workers = []
		this.workerAvailable = []
	}

	employWorker = () => {
		let firstAvailableIndex = this.workerAvailable.indexOf(true)

		if (firstAvailableIndex < 0)
			return null

		this.workerAvailable[firstAvailableIndex] = false

		return {
			worker:   this.workers[firstAvailableIndex],
			workerID: firstAvailableIndex
		}
	}

	dismissWorker = (index) => { this.workerAvailable[index] = true }

	handleStopUpload = () => { console.log("Can't stop. I don't know how!") }

	handleStartUpload = async () => {
		let { fileArray, orthomosaicBounds, mapMinZoom, mapMaxZoom, thumbnail, rootHTMLfile, rootKMLfile, mosaicName, estimatedEstateSize } = this.state
		
		if (fileArray.length <= 0) {
			this.setState({ step4error: 'Select a directory with files please.' })
			return
		}
		if (orthomosaicBounds.length <= 0 || mapMinZoom < 0 || mapMaxZoom < 0) {
			console.log('Detected bounds:', orthomosaicBounds, 'mapMinZoom:', mapMinZoom, 'mapMaxZoom', mapMaxZoom)
			this.setState({ step4error: "I can't find the map bounds, min & max zooms - did you accidentally remove the HTML file generated by Pix4D?" })
			return
		}
		if (!thumbnail) {
			this.setState({ step4error: "I will need a thumbnail bro. Take the 1_initial/report/html/orthomosaic_preview.png if you don't have anything nicer" })
			return
		}

		this.setState({
			step4error:   '',
			uploadStatus: 'started'
		})

		const [tasks, total_size] = await this.pushTask(fileArray, rootKMLfile, rootHTMLfile, mosaicName, thumbnail)
		
		const maxWorkers = Math.min(50, tasks.length)
		this.createWorkers(maxWorkers)

		const startTime = new Date()
		console.log('Start parallel upload of max', maxWorkers, 'workers. Time now is', startTime.toLocaleString())

		async.parallelLimit(tasks, maxWorkers, (parallelErr, result) => {
			this.destroyWorkers()

			const endTime = new Date()
			console.log('End parallel upload. Time now is', endTime.toLocaleString() + '.\n\nTotal elapsed time:' +
				(endTime.getTime() - startTime.getTime()) / 1000)

			if (parallelErr)
				console.error('Error: uploading incomplete:', parallelErr)
			else {
				console.log('Upload complete')
				const baseMediaURL = 'https://s3-ap-southeast-1.amazonaws.com/garuda-plex/orthomosaic/' 
				+ this.state.companyID + '/' 
				+ this.state.projectID + '/' 
				+ mosaicName + '/'


				let data = {
					name:   		mosaicName,
					customer_id:    this.state.companyID,
					project_id:    	this.state.projectID,
					create_date: 	(new Date()).getTime(),
					type:			'orthomosaic',
					thumb: 			baseMediaURL + 'thumbs/' + thumbnail.name,
					preview: 		baseMediaURL + 'thumbs/' + thumbnail.name,
					total_size:   	total_size,
					estate_size: 	estimatedEstateSize,
					properties: {
						minZoom:      	mapMinZoom,
						maxZoom:      	mapMaxZoom,
						bounds:       	orthomosaicBounds,
						tile_location: 	baseMediaURL + 'google_tiles'
					}
					
				}
				console.log(data)
				
				this.props.putMap(data)
				this.setState({uploadStatus: 'completed'})
			}
		})
    }
	pushTask = (fileArray, rootKMLfile, rootHTMLfile, mosaicName, thumbnail) => {

		let tasks      = [],
			total_size = 0

		for (let i = 0; i < fileArray.length; i++) {
			let filesize = fileArray[i].size
			if (filesize)
				total_size += filesize

			fileArray[i].originalIndex = i
			// "rename" the S3 object so that the HTML and KML file takes the mosaic name as file names
			// This ensures the local (probably badly named) mosaic never makes it to the cloud
			let relativePath = fileArray[i].webkitRelativePath
			if (fileArray[i] === rootHTMLfile)
				relativePath = 'google_tiles/' + mosaicName + '.html'
			else if (fileArray[i] === rootKMLfile)
				relativePath = 'google_tiles/' + mosaicName + '.kml'
			
			tasks.push(this.uploadTask.bind(this, i, fileArray[i], relativePath))
		}

		// Also upload the single thumbnail into a folder called thumbs
		thumbnail.originalIndex = fileArray.length
		tasks.push(this.uploadTask.bind(this, fileArray.length, thumbnail, 'thumbs/' + thumbnail.name))

		// We are now going to create parallel web workers to speed up the uploading of many small files
		// This has been tested between 5 and 20. We're unsure of the effects beyond 20
		//
		console.log('list of tasks: ', tasks)
		return [tasks, total_size]
	}

	uploadTask = async (index, file, mosaicRelativePath, done) => {
		let currentComponent = this
		// var fullpath = 'orthomosaic/' + this.state.companyID + '/' + this.state.projectID + '/' +
		// 	this.state.mosaicName + '/' + mosaicRelativePath
			
		
		await fetch(API_URL
			+ '/v1/'
			+ 'sign_s3_put_object/orthomosaic/' 
			+ this.state.companyID + '/'
			+ this.state.projectID + '/'
			+ this.props.token
			+ '?relativePath=' + encodeURIComponent(this.state.mosaicName + '/' + mosaicRelativePath) 
			+ '&mimeType=' + encodeURIComponent(file.type)
		)

		.then(function(res){
			res.json().then((signResult) => {
				// console.log(signResult)
				
				if (!signResult.success) {
					console.error('Failed to sign AWS signed URL for:', mosaicRelativePath, file.type)
					return done('Failed to sign AWS signed URL for:', mosaicRelativePath, file.type)
				}

				const signedRequest = signResult.signed_s3_put
				console.log('signed file:', index, signedRequest)

			let {worker, workerID} = currentComponent.employWorker()

			worker.postMessage({
				id:            workerID,
				fileIndex:     file.originalIndex,
				file:          file,
				signedRequest: signedRequest
			})

			worker.onmessage = (event) => {
				currentComponent.dismissWorker(workerID)
				let result = event.data

				if (result.success) {
					console.log('upload done:', index, file.name)
					currentComponent.setState({
						filesUploaded: currentComponent.state.filesUploaded + 1
					})
					// console.log('Current files uploaded: ', currentComponent.state.filesUploaded)
					if (currentComponent.state.highWatermarkIndex < file.originalIndex)
						currentComponent.setState({ highWatermarkIndex: file.originalIndex })
					done()
				}
				else {
					console.error('Could not upload file:', file.name, signedRequest, result.reason)
					alert('Could not upload file:' + file.name + "\n" + result.reason)
					done(result.reason)
				}
			}

			worker.onerror = (event) => {
				currentComponent.dismissWorker(workerID)
				let err = event.data
				if (err)
					console.error('Web worker error at', err.filename + ':' + err.lineno, err)
				else
					console.error('Unknown web worker event', event)
				done(err)
			}
				
			})
		})	
		.catch(err => {
			console.error('Error getting AWS signed URL for:', mosaicRelativePath, file.type)
			done('Error getting AWS signed URL for:', mosaicRelativePath, file.type)
		})
	}

    ///// STEPPER CONTROL & RENDER /////

	handlePrev = () => {
		const {stepIndex} = this.state
		if (stepIndex > 0)
			this.setState({
				stepIndex:  stepIndex - 1,
				step1error: '',
				step2error: '',
				step3error: '',
				step4error: ''
			})
	}

	handleNext = () => {
		const {
      stepIndex,
      step1error,
      step2error,
      step3error,
      // step4error,
      fileArray,
      rootHTMLfile,
      rootKMLfile,
      thumbnail,
      mosaicName
    } = this.state
		if (stepIndex === 0) {
			if (step1error === '' && fileArray && rootHTMLfile && rootKMLfile)
				return this.setState({ stepIndex: 1 })
			else
				return this.setState({ step1error: 'Please select the google_tiles folder' })

		}
		else if (stepIndex === 1) {
			if (step2error === '' && thumbnail)
				return this.setState({ stepIndex: 2 })
			else
				return this.setState({ step2error: 'Please select a thumbnail'})
		}
		else if (stepIndex === 2) {
			if (step3error === '' && mosaicName)
				return this.setState({ stepIndex: 3 })			
		}
		// else if (stepIndex === 3) {
        //     if (step4error === '')
        //     START
		// }
	}

    renderStepActions = (step) => {
		const {stepIndex} = this.state

		return (
			<div style={{margin: '20px 0'}}>
			{stepIndex !== 2 ?
			<Button
				disableTouchRipple={true}
				disableFocusRipple={true}
				variant='contained'
				primary={true}
				onClick={this.handleNext}
				style={{marginRight: 10, width: 80, height: 40, backgroundColor: '#2B3940', color: 'white'}} >Next</Button>: ''}
				{
					step > 0 && stepIndex !== 2 && (
						<Button
							disabled={stepIndex === 0}
							disableTouchRipple={true}
							disableFocusRipple={true}
							variant='contained'
							style={{width: 80, height: 40}}
							onClick={this.handlePrev} >Back</Button>
					)
				}
			</div>
		)
    }
    
    renderError = (error) => {
		return (error ? 
			<div style={{padding: '16px', color: 'red', fontWeight: 'bold'}}>{error}</div>
			: null
		)
	}

    render() {
        const {
          classes
        } = this.props, {
          fileArray,
          // highWatermarkIndex,
          thumbnail,
          uploadStatus,
          step1error,
          step2error,
          step3error,
          step4error,
          // totalSize,
          // estimatedEstateSize
        } = this.state
        let step4Action

        if (uploadStatus === 'started')
            // step4Action = <Button variant='contained' primary={true} onClick={this.handleStopUpload} >Processing... Please Wait</Button>
            step4Action = <><Typography>{this.state.filesUploaded}/{fileArray.length} files uploaded</Typography><LinearProgress variant='determinate' color='primary' value={Number(this.state.filesUploaded / fileArray.length) * 100} /></>
        else if (uploadStatus === 'cancelled' || uploadStatus === 'completed')
            step4Action = <Button variant='contained' className={classes.button} onClick={() => {window.location = '/'}} >Return to Home Page</Button>
        else
            step4Action = <Button variant='contained' className={classes.button} primary={true} onClick={this.handleStartUpload} >Start Upload</Button>
		
        return (
            <div style={{backgroundColor: 'white', padding: '16px'}}>
				<Stepper
					activeStep={this.state.stepIndex}
					orientation="vertical">
					<Step>
						<StepLabel>Select the tiles directory</StepLabel>
						<StepContent>
							<div><b className={classes.title}>Orthomosaic map</b><p className={classes.subtitle}>Select the folder named google_tiles</p></div>
							<input
								id="upload-ortho"
								type="file"
								name="files[]"
								style= {{display: 'none'}}
                                webkitdirectory="" directory=""
								ref={ref => this.directoryInput = ref}
								onChange={this.handleDirectorySelected} />
								<label htmlFor="upload-ortho"><Typography>
									<Button variant="outlined" component="span" className={classes.uploadButton}>
										Choose files 
									</Button> {fileArray.length === 0 ? 'No file chosen' : fileArray.length + ' files chosen'}</Typography>
								</label>
							{/* <div className={classes.subtitle}>No orthomosaic? <a href={'/upload'} >Request for drone mapping </a></div> */}
							{/* <Typography className={classes.textLink} onClick={this.handleDroneMapping.bind(this)}>Request for drone mapping</Typography> */}
							{
								this.renderError(step1error)
							}
							{
								fileArray.length === 0 ? null :
								<div>
									<br />
									<p className={classes.title}>Preview</p>
									<table style={{margin: 'auto'}}>
										<tbody>
										<tr>
											<td><img alt = '' src={this.state.previewImages[2]} style={{border: '1px solid black', width: '160px', height: '160px'}} /></td>
											<td><img alt = '' src={this.state.previewImages[1]} style={{border: '1px solid black', width: '160px', height: '160px'}} /></td>
										</tr>
										<tr>
											<td><img alt = '' src={this.state.previewImages[3]} style={{border: '1px solid black', width: '160px', height: '160px'}} /></td>
											<td><img alt = '' src={this.state.previewImages[0]} style={{border: '1px solid black', width: '160px', height: '160px'}} /></td>
										</tr>
										</tbody>
									</table>
									<br />
									{/* <b>Checklist</b><br />
									Now, look at the google_tiles folder, check that<br />
									<ol>
										<li>All files are structured google_tiles/[Zoom]/[X]/[Y].png or .kml</li>
										<li>There's one master KML file which links to all the subfolder's KML files</li>
										<li>There's one static HTML file (which can be used for troubleshooting later)</li>
									</ol> */}
								</div>
							}
							{
								this.renderStepActions(0)
							}
						</StepContent>
					</Step>
					<Step>
						<StepLabel>Upload thumbnail</StepLabel>
						<StepContent>
							{/* <p>Upload an image file that would be used to represent this asset.</p>
							<p><i>[Hint] you can use a Pix4D generated preview: navigate to the output folder, step 1, html and look for orthomosaic_preview.png</i></p> */}
							<div><b className={classes.title}>Thumbnail image</b><p className={classes.subtitle}>Only .jpg and .png files; 500kb max file size</p></div>
							<input
								id="upload-thumbs"
								type="file"
								accept="image/*"
								name="thumbnail"
								style= {{display: 'none'}}
								onChange={this.handleChooseThumbnail} />​
							<label htmlFor="upload-thumbs"><Typography>
								<Button variant="outlined" component="span" className={classes.uploadButton}>
									Add file
								</Button> {!thumbnail ? 'No file chosen' : ' Thumbnail successfully chosen'}</Typography>
							</label>
							{
								thumbnail ?
									<div>
										<p className={classes.title}>Thumbnail preview</p>
										<img alt= '' style={{width: '260px', height: '260px', objectFit: 'cover'}}
											src={thumbnail.content} /><br />
									</div>
								: null
							}
							{ this.renderError(step2error) }
							{ this.renderStepActions(1) }
						</StepContent>
					</Step>
					<Step>
						<StepLabel>Name your estate</StepLabel>
						<StepContent>
							<div><b className={classes.title}>Estate name</b></div>
							<TextField
								floatingLabelText='Mosaic Name'
								variant='outlined'
								defaultValue={this.state.mosaicName}
								onChange={this.handleMosaicNameChange}
								style={{width: '100%', height: 40, marginTop: 10, marginBottom: 20, color: 'primary'}} />
							{ this.renderError(step3error) }
							{ this.renderStepActions(2) }
							
							{ step4Action }
							{ this.renderError(step4error) }
						</StepContent>
					</Step>
				</Stepper>
			</div>
        )
    }
}

const ConnectedUploadGoogleTiles = connect(mapStateToProps, mapDispatchToProps)(UploadGoogleTiles)
export default withStyles(styles)(ConnectedUploadGoogleTiles)