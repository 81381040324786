import {
    RECEIVE_SOCKET_MESSAGE,
    UPLOAD_FILE_REQUESTED,
    SUBSCRIBE_SOCKET_MESSAGE,
    CLEAR_UPDATES,
    ERROR_SOCKET_MESSAGE,
    PLANTATION_LOGOUT
} from '../constants/action-types'

export function receiveSocketMessage(payload){
	return {type: RECEIVE_SOCKET_MESSAGE, payload}
}
export function uploadFile(payload){
	return {type: UPLOAD_FILE_REQUESTED, payload}
}
export function subscribeSocketMessage(payload){
	return {type: SUBSCRIBE_SOCKET_MESSAGE, payload}
}
export function clearUpdates(payload){
    return {type: CLEAR_UPDATES, payload}
}
export function errorSocketMessage(payload){
    return {type: ERROR_SOCKET_MESSAGE, payload}
}
export function plantationLogout(payload){
    return {type: PLANTATION_LOGOUT, payload}
}