import { createUserManager }    from 'redux-oidc'
import { WebStorageStateStore } from 'oidc-client'

const userManagerConfig = {

  authority:     process.env.REACT_APP_OIDC_AUTHORITY,
  client_id:     process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri:  process.env.REACT_APP_OIDC_REDIRECT_URI,
  response_type: 'code',
  scope:         'openid email plex',

  loadUserInfo:         true,
  userStore:            new WebStorageStateStore({ store: window.localStorage }),

  automaticSilentRenew: true,
  silent_redirect_uri:  process.env.REACT_APP_OIDC_REDIRECT_URI,

  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,

}

const userManager = createUserManager(userManagerConfig)
export default userManager
