import LayerItemHeader from './LayerItemHeader'
import {
  Accordion,
  AccordionSummary,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  layerItem: {
    margin: 0,
    color: theme.palette.text.secondary,
    width: '100%',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
  },
  accordionRoot: {
    padding: 0,
  },
  accordionSummary: {
    margin: 0,
  }
})

function RasterLayerComponent({ classes, itemName, itemId, listName }) {
  // const [expanded, setExpanded] = useState(false)
  return (
    <Accordion
      square
      expanded={false}
      className={classes.layerItem}>
      <AccordionSummary
        classes={{ root: classes.accordionRoot }}
        className={classes.accordionSummary}>
        <LayerItemHeader
          itemName={itemName}
          itemId={itemId}
          expanded={false}
          listName={listName}
        />
      </AccordionSummary>
    </Accordion>
  )
}

export default withStyles(styles)(RasterLayerComponent)
