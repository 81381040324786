import {createSelector} from 'reselect';
import _ from 'lodash';
const getVisibleList = (state, props) =>{
    if(!props.listName){
        return true;
    }
    if(!state.estate[props.listName] || !(state.estate[props.listName] instanceof Array)){
        return true;
    }
    return state.estate[props.listName].map((f)=> f.meta.visible);
}

export const getListAllVisible = createSelector(
    [getVisibleList],
    (listItems)=>{
        return _.every(listItems)
    }
)
export const getListSomeVisible = createSelector(
    [getVisibleList, getListAllVisible],
    (listItems, allVisible)=>{
        return _.some(listItems) && !allVisible 
    }
)

const getVisibleListFromItems = (state, props) =>{
    if(!state.estate.detail){
        return [];
    }

    var listName = state.estate.detail.meta.payload_type;
    if(!state.estate[listName]){
        return [];
    }
    return state.estate[state.estate.detail.meta.payload_type].map((f)=> f.meta.visible);
}

const getVisibleListFromVisibleMap = (state, props) =>{
    if(!state.estate.detail){
        return false;
    }
    return state.estate.visible[state.estate.detail.meta.payload_type]
}

export const getVisiblityForDetail = createSelector(
    [getVisibleListFromItems, getVisibleListFromVisibleMap , (state, props)=>{
        return state.estate.detail
    }],
    (visibleFromItems, visibleFromMap, selectedDetail)=>{
        if(visibleFromItems.length === 0){
            return false;
        }else if(visibleFromMap){
            return visibleFromMap;
        }else if(visibleFromItems){
            return visibleFromItems[selectedDetail.meta.index]
        }else{
            return false;
        }
    }
)