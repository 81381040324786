import { takeEvery, call, put } from "redux-saga/effects"
import {
  PAYMENT_TREE_COUNT_REQUESTED,
  PAYMENT_TREE_COUNT_COMPLETED,
  PAYMENT_TREE_COUNT_ERRORED,
  COUNT_TREE_REQUESTED,
  COUNT_TREE_COMPLETED,
  COUNT_TREE_ERRORED
} from '../constants/action-types'

import axios from 'axios'
import store from '../store/index'

const analyticsUrl = process.env.REACT_APP_ANALYTICS_BASE_URL

// TODO: FIND OUT WHAT THIS IS, IT DOES NOT EXIST ANYWHERE
const treeCountUrl = process.env.REACT_APP_TREECOUNT_BASE_URL

function authHeader() {
  return { Authorization: 'Bearer ' + store.getState().oidc.user.access_token }
}

export default function* watcherSaga() {
    yield takeEvery(PAYMENT_TREE_COUNT_REQUESTED, workerPaymentTreeCount)
    yield takeEvery(COUNT_TREE_REQUESTED, workerCountTree)
}

function* workerPaymentTreeCount(action){
  try {
    const payload = yield call(requestPaymentTreeCount, action)
    yield put({ type: PAYMENT_TREE_COUNT_COMPLETED, payload })
  } catch (e) {
    yield put({ type: PAYMENT_TREE_COUNT_ERRORED, payload: e })
  }
}

function* workerCountTree(action){
  try {
    const payload = yield call(requestCountTree, action)
    yield put({ type: COUNT_TREE_COMPLETED, payload })
  } catch (e) {
    yield put({ type: COUNT_TREE_ERRORED, payload: e })
  }
}

function requestPaymentTreeCount(payload){
  // console.log(payload.payload.result.paymentMethod, payload.payload.type)
  return axios.post(analyticsUrl + 'payment/' + payload.payload.type , payload.payload.result.source, {
    headers: authHeader()
  })
  .then((result) => {
    console.log(result)
    return result
  })
}

function requestCountTree(payload){
  return axios.post(treeCountUrl + 'treecounting/', payload.payload.media_id, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((result) => {
    console.log(result)
  })
}
