import React from "react"
import { connect } from "react-redux"
import { CallbackComponent } from "redux-oidc"
import userManager from "../utils/userManager"
import { replace } from 'connected-react-router'

class Callback extends React.Component {
	render() {
		// just redirect to '/' in both cases
		return (
			<CallbackComponent
				userManager={userManager}
				successCallback={() => {
					this.props.dispatch(replace('/'))
				}}
				errorCallback={error => {
					this.props.dispatch(replace("/"))
					console.error(error)
				}}
			>
				<div>Redirecting...</div>
			</CallbackComponent>
		);
	}
}

export default connect()(Callback)