import React, { Component } from 'react'
import { withRouter } from 'react-router'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button'

const styles = (theme) => {
  return {
    root: {
      paddingTop: '60px',
    },
    grid: {
      maxWidth: '360px', 
      width: '360px'
    },
    title: {
      color: '#000000',
      fontSize: '20px'
    },
    paper: {
      padding: theme.spacing(2),
      cursor: 'pointer'
    },
    choiceContainer: {
      paddingTop: '32px'
    },
    choiceTitle: {
      color: '#000000',
      fontSize: '18px',
      textAlign: 'center'
    },
    choiceDescription: {
      color: '#546580',
      fontSize: '14px',
      paddingTop: '18px',
      paddingBottom: '18px',
      textAlign: 'center'
    },
    divider: {
      width: '95%',
      margin: '0 auto',
      marginBottom: '20px'
    },
    choiceRequirements: {
      paddingTop: '18x !important',
      paddingBottom: '18px'
    },
    choiceFooter: {
      fontSize: '14px',
      paddingBottom: '12px',
    },

    radioContainer: {
      textAlign: 'right',
    },
    radio: {
      margin: 0,
      padding: 0,
      color: '#384A58 !important'
    },
    goodButton: {
      backgroundColor: '#2D5DCC',
      color: 'white',
      '&:hover': {
        backgroundColor: '#2D5DCC',
        color: 'white'
      }
    }
  }
}

class UploadTypeChoice extends Component {

  state = {
    format: ''
  }

  componentDidMount() {
    console.log('props: ', this.props)
  }

  handleUploadTypeChoiceClick = () => {
    const { format } = this.state
     
    if (format === 'google-tiles')
      this.props.history.push('/upload/google-tiles')
    else if (format === 'raster')
      this.props.history.push('/upload/raster')
  }
  
  render() {
    const { classes } = this.props 

    return (
      <Grid container className={classes.root} spacing={24} direction='row' justifyContent='center' alignItems='center'>
        <Typography className={classes.title}>Upload Google Tiles or Raster File</Typography>
        <Grid container className={classes.choiceContainer} spacing={24} direction='row' justifyContent='center' alignItems='center'>
          <Grid item md={6} className={classes.grid}>
            <Paper className={classes.paper} onClick={() => this.setState({ format: 'google-tiles' })}>
              <div className={classes.radioContainer}>
                <Radio className={classes.radio} checked={this.state.format === 'google-tiles'} onClick={() => this.setState({ format: 'google-tiles' })} />
              </div>
              <Typography className={classes.choiceTitle}>Google Tiles</Typography>
              <Typography className={classes.choiceDescription}>
                Select this if you already pre-tiled your maps into Google Tiles format. 
                Please use Google Chrome for this.
              </Typography>

              <Divider className={classes.divider} />
              
              <Typography className={classes.choiceRequirements}>Requirements:</Typography>
              <Typography className={classes.choiceFooter}>
                Name your folder <strong>google_tiles</strong>. 
                It should contain tilesets that conform to Google Maps specification. 
              </Typography>
            </Paper>
          </Grid>
          <Grid item md={6} className={classes.grid}>
            <Paper className={classes.paper} onClick={() => this.setState({ format: 'raster' })}>
              <div className={classes.radioContainer}>
                <Radio className={classes.radio} checked={this.state.format === 'raster'} onClick={() => this.setState({ format: 'raster' })} />
              </div>
              <Typography className={classes.choiceTitle}>Raster Image</Typography>
              <Typography className={classes.choiceDescription}>
                Let us tile your map for you. We will take up to 1 day to process your maps. 
                We currently can tile in GeoTIFF format.
              </Typography>

              <Divider className={classes.divider} />
              
              <Typography className={classes.choiceRequirements}>Requirements:</Typography>
              <Typography className={classes.choiceFooter}>
                Name your raster image after your estate and ensure 
                that it has the extension .tiff. 
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        
        <div style={{ paddingTop: '40px' }}>
          <Button 
            variant='contained' 
            className={this.state.format ? classes.goodButton : ''} 
            disabled={!this.state.format}
            onClick={() => this.handleUploadTypeChoiceClick()}
          >
            Next
          </Button>
        </div>
      </Grid>
    )
  }
}

export default withStyles(styles)(withRouter(UploadTypeChoice))
