import { 
    PAYMENT_TREE_COUNT_COMPLETED,
    COUNT_TREE_COMPLETED,
    CLEAR_PURCHASED_TREE
} from "../constants/action-types"
const initialState = {
    purchasedTree: {},
    countedEstate: {}
};
function paymentReducer(state = initialState, action) {
	if(action.type === PAYMENT_TREE_COUNT_COMPLETED){
        console.log(action.payload.data)
		return Object.assign({}, state, {
            purchasedTree: action.payload.data
		});
    }
	if(action.type === COUNT_TREE_COMPLETED){
        console.log(action.payload.data)
		return Object.assign({}, state, {
            countedEstate: action.payload.data
		});
    }
    if(action.type === CLEAR_PURCHASED_TREE){
        return Object.assign({}, state, {
            purchasedTree: {}
        })
    }
	return state;
};
export default paymentReducer;