import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import RenameDialog from './RenameDialog'
import { showOnboardingBlinker } from '../../utils/onboardingTour'
import { updateEstate } from '../../actions/index'
import { tourNextStep } from '../../actions/tour-actions'
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Menu,
  MenuItem,
  CardHeader,
  IconButton,
} from '@material-ui/core'
import {
  MoreVert,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const availableOptions = [
  'Calculate stand count',
  'Rename',
  'Delete'
]
const deletedOptions =[
  'Restore'
]

const styles = (theme) => {
  return {
    card: {
      width: 350,
      height: 350,
      margin: 20
    },
    media: {
      maxHeight: 162,
      paddingTop: '45%',
      backgroundColor: theme.palette.background.default
    },
    linkStyle: {
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': { backgroundColor: theme.palette.background.default }
    },
    descriptor: {
      color: theme.palette.primary.main,
      fontSize: 13,
      fontWeight: 500,
      fontFamily: 'Roboto',
      float: 'left',
      lineHeight: '0px',
      width: 180,
    },
    details: {
      color: theme.palette.common.black,
      fontSize: 13,
      textAlign: 'right',
      fontFamily: 'Roboto',
      lineHeight: '0px',
      width: 120
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      height: 140,
      padding: 15
    },
    header:{
      maxHeight: 70
    },
    action: {
      display: 'flex'
    }
  }
}

function fetchEstateStats(blocks) {
  let stand_count = 0,
      estate_size = 0

  blocks.forEach(estate => {
    stand_count += estate.properties.count
    if(estate.properties.area !== undefined){
        estate_size += estate.properties.area
    }
  })
  return {stand_count: stand_count, estate_size: estate_size}
}

class SelectPlantation extends Component {
  constructor(props) {
		super(props)
		this.state = {
      anchorEl: null,
      isRename: false
		}
  }

  handleClose(e){
    this.setState({anchorEl: null})
  }

  handleDialogClose(e){
    this.setState({isRename: false})
  }

  handleClick(e){
    this.setState({anchorEl: e.currentTarget})
  }

  handleSelect(option, e){
    if(option.toLowerCase() === 'rename'){
      this.setState({anchorEl: null})
      this.setState({isRename: true})
    } 
    else if(option.toLowerCase() === 'calculate stand count'){
      this.props.history.push('/checkout/tree/' + this.props.media_id)
      this.setState({ anchorEl: null })
    } 
    else {
      this.props.updateEstate({ 'media_id': this.props.media_id, 'option':option })
    }
  }

  handleRename(val){
    this.setState({isRename: false})
    this.props.updateEstate({ 'media_id': this.props.media_id, 'option':'rename', 'update_name': String(val) })
  }

  handlePlantationClick = () => {
    this.props.history.push('/map/' + this.props.map._id) 
    
    if (this.props.tourIsOpen)  {
      this.props.onNextStep(1000)
    }
  }
   
  render() {
    const { classes } = this.props
    const isCounted = this.props.map.count !== undefined
    var estateSummary = fetchEstateStats(this.props.blocks)

    const plantationDataTourStep = this.props.map.demo ? 'step-1' : ''

    // Stand count
    var stand_count = estateSummary.stand_count === 0 && estateSummary.estate_size === 0 ? (this.props.map.properties.stand_count ? this.props.map.properties.stand_count : this.props.map.count) : estateSummary.stand_count
    if(stand_count === undefined){stand_count = '0'}

    // Hectarage
    var estate_size = estateSummary.estate_size === 0 && estateSummary.stand_count === 0 ? (this.props.map.properties.estate_size ? this.props.map.properties.estate_size : this.props.map.properties.total_size / 1000000) : estateSummary.estate_size

    return (
      <Card className={classes.card} data-tour={plantationDataTourStep}>
        <CardHeader className={classes.header}
          action={
            <>
              <IconButton onClick={this.handleClick.bind(this)}>
                <MoreVert />
              </IconButton>
              <Menu
                disableAutoFocusItem
                id='long-menu'
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose.bind(this)}>
                {
                  this.props.display === 'Estates' ? 
                  <div>
                    <MenuItem style={{width: 150}} disabled= {isCounted} key = {availableOptions[0]} onClick={this.handleSelect.bind(this, availableOptions[0])}>{availableOptions[0]}</MenuItem>
                    <MenuItem style={{width: 150}} key = {availableOptions[1]} onClick={this.handleSelect.bind(this, availableOptions[1])}>{availableOptions[1]}</MenuItem>
                    <MenuItem style={{width: 150}} key = {availableOptions[2]} onClick={this.handleSelect.bind(this, availableOptions[2])}>{availableOptions[2]}</MenuItem>
                  </div>
                    : deletedOptions.map(option => (
                  <MenuItem style={{width: 120}} key = {option} onClick={this.handleSelect.bind(this, option)}>{option}</MenuItem>
                  ))
                }
              </Menu>
              { this.state.isRename ? 
                  <RenameDialog isOpened
                    handleDialogClose={this.handleDialogClose.bind(this)}
                    handleRename = {this.handleRename.bind(this)}
                    name={this.props.map.name}
                  /> 
                : ''
              }
            </>
          }
          subheader={<Typography variant= 'h6' className={classes.header}>{this.props.map.name} {this.props.step === 1 ? showOnboardingBlinker(this.props) : ''} </Typography>}
        />
        <div className={classes.linkStyle} onClick={() => this.handlePlantationClick()}>
          <CardMedia
            className={classes.media}
            image={this.props.map.properties.thumb}
            title='Image Thumbnail'
          />
          <CardContent className={classes.content}>
            <p className={classes.descriptor}> Total Hectarage </p>
            <p className={classes.descriptor}> Total Stand Count </p>
            <p className={classes.descriptor}> Average Planting Density </p>
            <p className={classes.details}>
              { estate_size.toLocaleString(undefined, {'minimumFractionDigits': 2, 'maximumFractionDigits':2}) } ha
            </p>
            <p className={classes.details}>
              { (stand_count / 1).toLocaleString(undefined, {'minimumFractionDigits': 0, 'maximumFractionDigits':0}) } Stands
            </p>
            <p className={classes.details}>
              { (stand_count / estate_size ).toLocaleString(undefined, {'minimumFractionDigits': 2, 'maximumFractionDigits':2}) } Stands/ha
            </p>
          </CardContent>
        </div>
        {/*<CardActions>
          <Link to={'/map/'+this.props.map._id} className={classes.linkStyle} exact={true}>
            <Button size='small' classes={{root: classes.cardButton}}>
              View More
            </Button>
          </Link>
        </CardActions>*/}
      </Card>
    )
  }
}

function mapStateToProps(state) {
  return {
    step: state.tour.step,
    tourIsOpen: state.tour.tourIsOpen
  }
}
function mapDispatchToProps(dispatch) {
  return {
    onNextStep: (delayTime) => dispatch(tourNextStep(delayTime)),
    updateEstate: (payload) => dispatch(updateEstate(payload))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SelectPlantation)))
