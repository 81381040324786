import { 
	GET_MAPS_COMPLETED,
	UPDATE_ESTATE_COMPLETED,
	SORT_ESTATES
} from '../constants/action-types'
import _ from 'lodash'

const initialState = {
	maps : [],
	deletedMaps : [],
	updatedMap: null
}

function mapsReducer(state = initialState, action) {
	if (action.type === GET_MAPS_COMPLETED) {
		return Object.assign({}, state, {
			maps: action.payload.results
		})
	}

	if (action.type === UPDATE_ESTATE_COMPLETED) {
		return Object.assign({}, state, {
			updatedMap: Math.random()
		})
	}

	if (action.type === SORT_ESTATES) {
		const sortingMethod = String(action.payload).toLowerCase() //enum: ['name (ascending)', 'stand count (largest)', 'last updated (latest)']
		let sortedEstates = []

		switch(sortingMethod){
			case 'stand count (largest)':
				sortedEstates = _.sortBy(state.maps, [(map) => {return map.properties.stand_count || map.count || 0}]).reverse()
				break
			case 'last updated (latest)':
				sortedEstates = _.sortBy(state.maps, ['properties.create_date']).reverse()
				break
			default:
				sortedEstates = _.sortBy(state.maps, ['properties.name', 'name'])
				break
		}

		return Object.assign({}, state, {
			maps: sortedEstates
		})
	}
	return state
}
export default mapsReducer
