import { connect } from 'react-redux'
import { changeDetail } from '../../actions/index'

import classNames from 'classnames'
import { formatDecimal } from '../../utils/formatter'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import theme from '../../utils/site-theme'

const styles = theme => ({
  divBox: {
    padding: '10px',
  },
  marginTop: {
    marginTop: '15px',
  },
  textOverflow: {
    overflowWrap: 'break-word',
  },
  dropdown: {
    width: '100%',
  },
  fontBold: {
    fontWeight: 'bold',
  }
})

function TreeDetailBody({ classes, detail, changeDetail }) {

  if (!detail.properties.description){
    detail.properties.description = ''
  }
  if (!detail.properties.category){
    detail.properties.category = 'none'
  }

  const handleChangeProperties = propName => event => {
    changeDetail({
      [propName]: event.target.value
    })
  }

  return (
    <div className={classes.divBox}>
      <Typography variant='h6' gutterBottom className={classes.fontBold}>
        Position
      </Typography>
      <Grid container direction='row' justifyContent='space-between' alignItems='center' wrap='nowrap'>
        <Grid item sm={5}>
          <Typography variant='body2' gutterBottom className={classes.fontBold}>
            Longitude: 
          </Typography>
        </Grid>
        <Grid item sm={7}>
          <Typography variant='body2' className={classNames(classes.textOverflow, classes.fontBold)} gutterBottom>
            {detail ? formatDecimal(detail.geometry.coordinates[0], 5) : ''}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction='row' justifyContent='space-between' alignItems='center'>
        <Grid item sm={5}>
          <Typography variant='body2' gutterBottom className={classes.fontBold}>
            Latitude: 
          </Typography>
        </Grid>
        <Grid item sm={7}>
          <Typography variant='body2' className={classNames(classes.textOverflow, classes.fontBold)} gutterBottom>
            {detail ? formatDecimal(detail.geometry.coordinates[1], 5) : ''}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction='row' justifyContent='space-between' alignItems='center'>
        <Grid item sm={5}>
          <Typography variant='body2' gutterBottom className={classes.fontBold}>
            Confidence: 
          </Typography>
        </Grid>
        <Grid item sm={7}>
          <Typography variant='body2' className={classNames(classes.textOverflow, classes.fontBold)} gutterBottom>
            {detail.properties.score}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant='h6' className={classes.marginTop} gutterBottom>
        More Information
      </Typography>
      <Typography variant='body2' className={classes.marginTop} gutterBottom>
        Category
      </Typography>
      <Select
        autoWidth
        inputProps={{
          name: 'category',
        }}
        value={detail.properties.category}
        className={classes.dropdown}
        onChange={(e)=> handleChangeProperties('category')({
          target: {
            value: e.target.value.toLowerCase()
          }
        })}>
        <MenuItem value='none'>
          <em>None</em>
        </MenuItem>
        <MenuItem value={theme.palette.annotation.disease}>Disease</MenuItem>
        <MenuItem value={theme.palette.annotation.pest}>Pest</MenuItem>
        <MenuItem value={theme.palette.annotation.dry}>Water Stress</MenuItem>
        <MenuItem value={theme.palette.annotation.death}>Death</MenuItem>
      </Select>
      <Typography variant='body2' className={classes.marginTop} gutterBottom>
        Description
      </Typography>
      <TextField
        margin='none'
        fullWidth
        multiline
        value={detail.properties.description ? detail.properties.description : ''}
        onChange={handleChangeProperties('description')}
      />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    detail: state.estate.detail,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeDetail: (payload) => dispatch(changeDetail(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TreeDetailBody))
