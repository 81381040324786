import { Component } from 'react'
import { connect } from 'react-redux'
import {
  receiveSocketMessage,
  errorSocketMessage,
  showMessage
} from '../actions/index'

const ANALYTICS_SOCKET_URL = process.env.REACT_APP_ANALYTICS_SOCKET_URL
if (!ANALYTICS_SOCKET_URL) throw new Error('Environment Variable REACT_APP_ANALYTICS_SOCKET_URL is required.')

const mapStateToProps = (state,props) => ({
  waiting: state.socket.waiting,
  map: state.estate.map
})

const mapDispatchToProps = (dispatch) => ({
  showMessage: (payload) => dispatch(showMessage(payload)),
  errorSocketMessage: (payload) => dispatch(errorSocketMessage(payload)),
  receiveSocketMessage: (payload) => dispatch(receiveSocketMessage(payload))
})

class SocketElement extends Component{
  constructor(props) {
    super(props)
    this.state = {
      ws: null,
      clientId: '_' + Math.random().toString(36).substr(2, 9)
    }
  }
  componentDidMount() {
    this.connect()
  }
  connect() {
    const ws = new WebSocket(ANALYTICS_SOCKET_URL)
    ws.onopen = () => {
      this.setState({ ws: ws })
      ws.send(JSON.stringify({
        topics: [this.props.map.media_id + '.processed.block'],
        client_id: this.state.clientId
      }))
    }
    ws.onmessage = (e) => {
      this.props.receiveSocketMessage(JSON.parse(e.data))
    }
    ws.onerror= (error) => {
      this.props.errorSocketMessage()
      this.props.showMessage({
        type: 'error',
        message: 'Unable to connect, may not get realtime updates on tree count'
      })
    }
  }
  /*
  //whenever subscription info is updated, use ws client to register for notification service
  componentDidUpdate(prevProps){
    if((!prevProps.waiting || Object.keys(prevProps.waiting).length !== Object.keys(this.props.waiting).length)){
      const ws = new WebSocket(ANALYTICS_SOCKET_URL)
      ws.onerror= (error)=>{
        this.props.errorSocketMessage()
        this.props.showMessage({type: 'error', message: 'Unable to connect, may not get realtime updates on tree count'})
      }
      ws.onopen = ()=>{
        this.setState({ws: ws})
        Object.keys(this.props.waiting).forEach((purpose)=>{
          if(!prevProps.waiting || !prevProps.waiting[purpose]){
            ws.send(JSON.stringify({
              topic: this.props.waiting[purpose]['topic'], 
              media_id: this.props.map.media_id,
              client_id: this.state.clientId
            }))
          }
        })
      }
      ws.onmessage=(e)=>{
        this.props.receiveSocketMessage(JSON.parse(e.data))
      }
    }
  }*/
  render() {
    return (<></>)
  }
}

const ConnectedSocketElement = connect(mapStateToProps, mapDispatchToProps)(SocketElement)
export default ConnectedSocketElement
