import React          from 'react'
import { connect }    from 'react-redux'
import { Doughnut }   from 'react-chartjs-2'
import theme          from '../../utils/site-theme'

const hectarageDoughnutOptions = {
    layout: {
        padding: {
            bottom: 50
        }
    },
    legend: {
        position: 'right',
        labels: {
            boxWidth: 10
        }
    },
    subtext: 'hectares',
    cutoutPercentage: 70,
    title: {
        display: true,
        padding: 50,
        text: 'Total Hectarage',
        fontSize: 24,
        fontColor: theme.palette.primary.main
    },
    tooltips: {
        callbacks: {
            label: function(tooltipItems, data) { 
                var label = data.labels[tooltipItems.index]
                var value = data.datasets[0].data[tooltipItems.index]
                return label +': ' + value.toLocaleString(undefined, {'minimumFractionDigits': 0, 'maximumFractionDigits':0}) + ' hectares'
            }
        }
    }
}

function generateColor(estate) {
  const colors = theme.palette.chart.colors
  const colorList = []
  for (let i = 0, j = 0; i < estate.length; i++) {
    colorList.push(colors[j++ % colors.length])
  }
  return colorList
}

function DashboardHectarage(props) {
  const { estateSummary, maps } = props
  const doughnutColor = generateColor(maps)
  return (
    <Doughnut
      id= 'hectarageChart'
      height= {240}
      data={{
        labels: estateSummary.nameList,
        datasets: [{
          data: estateSummary.hectarage,
          backgroundColor: doughnutColor
        }]
      }}
      options={hectarageDoughnutOptions}
    />
  )
}

function mapStateToProps(state) {
  return {
    maps: state.maps.maps ? state.maps.maps : []
  }
}

export default connect(mapStateToProps, null)(DashboardHectarage)
