import { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import axios from 'axios'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Typography,
  Tooltip,
  Fab,
} from '@material-ui/core'
import {
  Add,
} from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const FLIGHT_SERVICE_URL = process.env.REACT_APP_FLIGHT_SERVICE
if (!FLIGHT_SERVICE_URL) throw new Error('Environment Variable REACT_APP_FLIGHT_SERVICE is required.')

const styles = theme => ({
  floatRight: {
    float: 'right',
  },
  sectionContainer: {
    marginTop:    theme.spacing(2),
    marginBottom: theme.spacing(4),
    minHeight:    theme.spacing(8),
    padding:      theme.spacing(2),
    borderRadius: theme.spacing(1),
    border:       '1px dashed black',
    overflow:     'hidden',
  },
  err: {
    color: theme.palette.error.main,
    fontWeight: 'bold'
  }
})

function LogsUploadDialog({ classes, open, onClose, oidc }) {

  const [ logType, setLogType ] = useState('')
  const [ files,   setFiles   ] = useState([])
  const [ errMsg,  setErrMsg  ] = useState('')
  const [ companyId, setCompanyId ] = useState('')

  useEffect(() => {
    const { access_token } = oidc?.user
    if (access_token) {
      const decoded = jwt.decode(access_token)
      setCompanyId(decoded.plex.company_id)
    }
  }, [oidc])

  async function handleUpload() {
    if (files.length === 0)
      return setErrMsg('Please select at least one file')
    if (logType === '')
      return setErrMsg('Please choose a Log Type (Note: do not mix file types during upload)')

    setErrMsg('')
    // TODO: Validation of file contents

    // A Temporary Flight Object will get created here for the demo
    // By right the Dialog needs to capture more fields in the flight record
    // See compulsory fields on the latest Flight Service
    // https://bitbucket.org/garudarobotics/flight-service/src/master/api-doc.yml
    const newFlight = {
      company_id: companyId,
      // deployment_id, // missing until there's a Sprayer Ops backend with an ID for each job given
      drone: {
        id: 'Invalid Drone ID, to choose from list of drones',
        name: 'Demo Sprayer Drone'
      },
      pilots: [{
        id: 'Invalid User ID, to choose from list of pilots',
        name: 'Demo Drone Pilot',
        username: ''
      }],
      location: 'Invalid Location, to pick from Estate Name',
      path: {}, // to capture at least the home point
    }
    try {
      const res = await axios.post(`${FLIGHT_SERVICE_URL}/flights`, newFlight)
      console.log('res', res)
    } catch (e) {
      console.log('exception', e)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'>
      <DialogTitle>Upload Logs</DialogTitle>
      <DialogContent>
        <Typography variant='body1'>
          Automatically create Flight / Discharge records by dragging in compatible
          log formats.
        </Typography>
        <Typography variant='body2'>Refer to our Plantation Sprayer Survival Guide for more details
          on how to generate compatible log formats.
        </Typography>
        <div style={{ display: 'flex', paddingTop: '24px' }}>
          <div style={{ width: '38%', paddingRight: '24px' }}>
            <TextField select label='Select Log Type' value={logType} fullWidth
              onChange={e => setLogType(e.target.value)}>
              <MenuItem value='cerana-ardupilot'>Cerana UAS (Ardupilot .BIN)</MenuItem>
              <MenuItem value='cerana-geojson'>Cerana UAS (GeoJSON)</MenuItem>
              <MenuItem value='mission-planner-tlog'>Mission Planner (TLOG)</MenuItem>
              <MenuItem value='jiagutech-proprietary'>嘉谷 Jiagutech (Proprietary)</MenuItem>
              <MenuItem value='jiagutech-geojson'>嘉谷 Jiagutech (GeoJSON)</MenuItem>
            </TextField>
            { errMsg && <Typography className={classes.err}>{ errMsg }</Typography> }
          </div>
          <div style={{ width: '62%' }}>
            <FileDropArea
              files={files}
              setFiles={setFiles}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleUpload} color='primary'>Upload</Button>
      </DialogActions>
    </Dialog>
  )
}

function FileDropArea({ files, setFiles }) {
  const classes = makeStyles(styles)()
  const inputFileRef = useRef(null)

  function handleChooseFiles() {
    inputFileRef.current.click()
  }

  function handleRemoveFile(removedFileIndex) {
    let curFiles = [...files]
    curFiles.splice(removedFileIndex, 1)
    setFiles(curFiles)
  }

  function handleAddFile(selectedFiles) {
    const curFiles = []
    curFiles.push.apply(curFiles, selectedFiles)
    setFiles(prevState => ([...prevState, ...curFiles]))
  }

  function handleFileChosen(e) {
    const selectedFiles = e.target.files
    handleAddFile(selectedFiles)
    e.target.value = ''
  }

  function handleFilesDrop(e) {
    e.preventDefault()
    const selectedFiles = e.dataTransfer.files
    handleAddFile(selectedFiles)
  }

  function handleDragOver(e) {
    e.preventDefault()
  }

  return (
    <div className={classes.sectionContainer}
      onDrop={e => handleFilesDrop(e)}
      onDragOver={e => handleDragOver(e)}>
      <Tooltip title='Choose Files'>
        <Fab size='medium' color='primary' onClick={handleChooseFiles} className={classes.floatRight}>
          <Add />
        </Fab>
      </Tooltip>
      <input type='file' onChange={handleFileChosen} hidden multiple ref={inputFileRef} />
      { files.length > 0 ?
        <ol>
          {files.map((file, idx) => (
            <li key={idx}><Typography>{file.name}
              <Button size='small' color='secondary' onClick={() => handleRemoveFile(idx)}>
                Remove
              </Button>
            </Typography></li>
          ))}
        </ol>
        : <Typography variant='body2'>No files chosen.</Typography>
      }
    </div>
  )
}

function mapStateToProps(state){
  return {
    oidc: state.oidc,
  }
}

export default connect(mapStateToProps, null)(withStyles(styles)(LogsUploadDialog))
