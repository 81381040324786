import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import jwt from 'jsonwebtoken'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { green } from '@material-ui/core/colors'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

import { getToken } from '../../actions/index'

const styles = (theme) => {
  return {
    root: {
      paddingTop: '60px',
    },
    stepperContainer: {
      width: '50%'
    },
    actionsContainer: {
      marginTop: '40px',
      marginBottom: '0px',
    },
    estateInput: {
      width: '60%', 
      color: 'primary',
      padding: '2px 8px !important',
      border: '1px solid #384A58',
      borderRadius: '5px'
    },
		uploadButton: {
			border: '2px solid #2B3940',
			color: '#2B3940',
			fontSize: 14,
			width: 140,
			height: 40,
			borderRadius: 4,
      marginRight: 10,
      marginTop:5
    },
    buttonProgress: {
      color: green[500],
      marginLeft: 5
    },
    linkStyle :{
      textDecoration:'none',
      color:'inherit'
    }
  }
}

const API_URL = process.env.REACT_APP_API_URL
if (!API_URL) throw new Error('Environment Variable REACT_APP_API_URL is required.')

const TILING_URL = process.env.REACT_APP_TILING_SERVICE
if (!TILING_URL) throw new Error('Environment Variable REACT_APP_TILING_SERVICE is required.')

class UploadRaster extends Component {
  state = {
    active_step: 0,
    estate_name: '',
    raster_file: {},
    isUploading:false
  }

  componentDidMount = async () => {
    await this.props.getToken()
  }

  handleChooseRasterFile = (e) => {
    console.log('e: ', e.target.files)
    this.setState({ raster_file: e.target.files[0] })
  }

  canGoToNextStep = () => {
    const { active_step, estate_name, raster_file } = this.state
    
    if (active_step === 0)
      return estate_name ? true : false 
    else if (active_step === 1)   
      return raster_file.name ? true : false  
    else if (active_step === 2)
      return estate_name && raster_file.name ? true : false 
  }

  handleNextButtonClick = () => {
    const { active_step,isUploading } = this.state 
    
    if (active_step === 0 || active_step === 1){
      this.setState({ active_step: active_step + 1 })
    }
    else if (active_step === 2){
      this.setState({ isUploading:!isUploading },console.log('isuploading',isUploading))
      this.getS3SignedUrl()
    }
  }

  renderActionButtons = () => {
    const { classes } = this.props  
    const { active_step,isUploading } = this.state 
    
    const can_go_to_next = this.canGoToNextStep()
    return (
      <div className={classes.actionsContainer}>
        <Button
          disabled={active_step === 0}
          onClick={() => this.setState({ active_step: active_step - 1 })}
          className={classes.button}
        >
          Back
        </Button>
        <Button
          disabled={active_step === 2 ? isUploading : !can_go_to_next}
          variant="contained"
          color="primary"
          onClick={() => this.handleNextButtonClick()}
          className={classes.button}
        >
          {active_step === 2 ? 'Upload' : 'Next'}
          {isUploading && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </Button>
      </div>
    )
  }

  getCompanyId = () => {
    const { access_token } = this.props.oidc.user 
    const decoded = jwt.decode(access_token) 

    console.log('decoded: ', decoded)

    return decoded.plex.company_id
  }

  getS3SignedUrl = async () => {
    const { raster_file, estate_name, isUploading,active_step } = this.state
    console.log('Uploading: ', raster_file.name)
    const company_id = this.getCompanyId()
    console.log(company_id)
    const { access_token } = this.props.oidc.user 
    console.log(access_token)
    const public_project_id = estate_name
    const token = this.props.token
    const relative_path = raster_file.webkitRelativePath
    console.log('relative_path: ', relative_path)
    console.log('token: ', token)

    const url = API_URL
			+ '/v1/sign_s3_put_object/orthomosaic/' 
      + company_id + '/'
      + 'plantation/'
      + public_project_id + '/'
      + 'orthomosaic/'
			+ token
			+ '?relativePath=' + encodeURIComponent(raster_file.name)
      + '&mimeType=' + encodeURIComponent(raster_file.type)

    console.log('sending to: ', url)

    const res = await axios.get(url)

    console.log('getS3SignedUrl res: ', res.data)
    if (res.data.success) {
      const signed_s3_url = res.data.signed_s3_put
      await this.uploadRasterFile(signed_s3_url).then(()=>{
        this.setState({isUploading:!isUploading,active_step:active_step + 1})
      })
      console.log('Raster file has been uploaded')
      await this.tilingRasterFile(company_id,'plantation',estate_name,'tif',access_token)
      console.log('Raster file is tiling')
    }
  }

  uploadRasterFile = async (signed_s3_url) => {
    console.log('Uploading ', this.state.raster_file, 'to', signed_s3_url)
    
    const options = {
      headers: {
        'Content-Type': this.state.raster_file.type
      }
    }
    console.log('options: ', options)
    // console.log('uploading: ', this.state.raster_file)
    
    const res = await axios.put(signed_s3_url, this.state.raster_file, options)

    console.log('uploadRasterFile res: ', res)
  }

  tilingRasterFile = async(company_id, project_name, estate_name, file_ext,access_token) =>{
    
    //checking for tiling service
    console.log('retrieving tiling service...')
    await axios.get(`${TILING_URL}/sanity`).then(()=>{
      console.log('tiling service is up and running!')
    }).catch((err) =>{
      console.log('Get tile service error:',err)
    }) 
    //posting to tiling service 
    await axios.post(
      `${TILING_URL}/tile`,
      {
        company_id   : company_id,
        project_name : project_name,
        estate_name  : estate_name,
        file_ext     : file_ext,
        access_token : access_token
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      }
    ).then((res) => {
      console.log('succeed in tiling:', res)
    }).catch((err) => {
      console.log('error in tiling:', err)
    })
  }

  render() {
    const { classes } = this.props 
    const { active_step, estate_name, raster_file } = this.state
    const raster_file_size_in_mb = (raster_file.size/1000000).toFixed(2) + 'MB'

    return (
      <Grid container className={classes.root} spacing={24} direction='row' justifyContent='center' alignItems='center'>
        <Stepper className={classes.stepperContainer} activeStep={active_step} orientation="vertical">

          {/* STEP 1: NAME THE ESTATE */}
          <Step key={0}>
            <StepLabel>Name Your Estate</StepLabel>
            <StepContent>
              <Input
                className={classes.estateInput}
								onChange={(e) => this.setState({ estate_name: e.target.value })}
                value={estate_name}
                disableUnderline
              />
              {this.renderActionButtons()}
            </StepContent>
          </Step>  





          {/* STEP 2: CHOOSE RASTER FILE */}
          <Step key={1}>
            <StepLabel>Choose Raster File</StepLabel>
            <StepContent>
                <input
                  id="upload-raster-file"
                  type="file"
                  style= {{ display: 'none' }}
                  onChange={(e) => this.handleChooseRasterFile(e)} />
								<label htmlFor="upload-raster-file">
                  <Typography>
                    <Button variant="outlined" component="span" className={classes.uploadButton}>
                      Choose File
                    </Button> 
                    { raster_file.name ? raster_file.name : 'No file chosen' }
                  </Typography>
								</label>
              {this.renderActionButtons()}
            </StepContent>
          </Step>  




          {/* STEP 3: UPLOAD */}
          <Step key={1}>
            <StepLabel>Review and Upload</StepLabel>
            <StepContent>
              <Typography><span style={{ fontWeight: 500 }}>Estate Name: </span> {estate_name} </Typography>
              <Typography><span style={{ fontWeight: 500 }}>Raster File:</span> {raster_file.name} <span style={{ fontSize: '10px'}}> ({raster_file_size_in_mb}) </span></Typography>
              {this.renderActionButtons()}
            </StepContent>
          </Step>  

          {/*STEP 4: UPLOAD FINISH */}
          <Step key={1}>
            <StepLabel>Upload Completed!</StepLabel>
            <StepContent>
              <Typography> Your estate map will take some time to be ready. Please come back in 1 day’s time to start using your map.</Typography>
              <Link to={'/'} className={classes.linkStyle} replace>
                <Button className={classes.uploadButton} component='span' variant='outlined'>
                  Home Page
                </Button>
              </Link>
            </StepContent>
          </Step>
        </Stepper>
      </Grid>
    )
  }
}

const mapStateToProps = state => {
  return { 
    oidc: state.oidc,
    token: state.media.token 
  }
}

function mapDispatchToProps(dispatch, state) {
	return {
		getToken: payload => dispatch(getToken(payload))
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UploadRaster)))
