import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from "react-redux"
import { getCompanyId } from '../../actions/index';

import Paper        from '@material-ui/core/Paper'
import UploadOrthomosaic from './UploadOrthomosaic'

const styles = (theme) => {
    return {
		root: {
			marginLeft: 150,
			marginTop: 60
		},
		uploadPaper:{
			width: 800,
		}
	}
}

function mapStateToProps(state) {
	return {
		user: state.oidc.user,
        companyId: state.query.companyId
	}
}

function mapDispatchToProps(dispatch, state) {
	return {
		
        getCompanyId: (payload) => dispatch(getCompanyId(payload))
	}
}

class UploadGoogleTilesContainer extends Component {
    state = {
		newProjectID:        '',
		selectedMediaType:   'orthomosaic',
		selectedMediaFormat: 'google-tiles',
		errorStep1:          ''
    }

    handleRestart = () => {
		this.setState({
			newProjectID:        '',
			selectedMediaType:   '',
			selectedMediaFormat: '',
			errorStep1:          ''
        })
    }
    
    handleSelectMediaType = (mediaType, mediaFormat) => {
		if (this.state.newProjectID !== '')
			this.setState({
				selectedMediaType:   mediaType,
				selectedMediaFormat: mediaFormat
			})
		else
			this.setState({ errorStep1: 'You must create a new project' })
	}
	
	componentDidMount(){
		this.props.getCompanyId()
	}
    componentDidUpdate(){
		console.log('Company Id is ', this.props.companyId)
	}	

    render() {
		const {classes} = this.props
        return (
            <div className={classes.root}>
                <Paper className={classes.uploadPaper}>
					<UploadOrthomosaic
						projectID={this.state.newProjectID}
						projectTitle={this.state.newProjectID}
						format={this.state.selectedMediaFormat}
					/>
				</Paper>
            </div>
        )
    }
}

const ConnectedUploadGoogleTilesContainer = connect(mapStateToProps, mapDispatchToProps)(UploadGoogleTilesContainer)
export default withStyles(styles)(ConnectedUploadGoogleTilesContainer)