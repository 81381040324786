import { connect } from 'react-redux'
import {
  triggerDeleteTree,
  removeAnnotations,
  toggleDeleteDialog,
  toggleDrawing
} from '../../actions/index'
import {
  Divider,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  Cancel,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  headerDiv: {
    width: '100%',
    padding: '5px',
  },
  headerBox: {
    padding: '5px',
  },
})

function DetailDrawerHeader({ classes, detail, toggleDrawing }) {  
  if (!detail)
    return null

  switch(detail.properties.purpose) {
    case 'tree':
      return (
        <div className={classes.headerDiv}>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' className={classes.headerBox}>
            <Grid item sm={10}>
              <Typography variant='h6'>
                Tree
              </Typography>
            </Grid>
            <Grid item sm={2}>
              <IconButton size='small' onClick={(e) => {toggleDrawing('')}}>
                <Cancel color='action' fontSize='small'/>
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
        </div>
      )
    case 'block':
      return (
        <div className={classes.headerDiv}>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' className={classes.headerBox}>
            <Grid item sm={10}>
              <Typography variant='h6'>
                Block
              </Typography>
            </Grid>
            <Grid item sm={2}>
              <IconButton size='small' onClick={(e) => {toggleDrawing('')}}>
                <Cancel color='action' fontSize='small'/>
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
        </div>
      )
    case 'road':
      return (
        <div className={classes.headerDiv}>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' className={classes.headerBox}>
            <Grid item sm={10}>
              <Typography variant='h6'>
                Road
              </Typography>
            </Grid>
            <Grid item sm={2}>
              <IconButton size='small' onClick={(e) => {toggleDrawing('')}}>
                <Cancel color='action' fontSize='small'/>
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
        </div>
      )
    case 'drain':
      return (
        <div className={classes.headerDiv}>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' className={classes.headerBox}>
            <Grid item sm={10}>
              <Typography variant='h6'>
                Waterway
              </Typography>
            </Grid>
            <Grid item sm={2}>
              <IconButton size='small' onClick={(e) => {toggleDrawing('')}}>
                <Cancel color='action' fontSize='small'/>
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
        </div>
      )
    case 'annotation':
      return(
        <div className={classes.headerDiv}>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' className={classes.headerBox}>
            <Grid item sm={10}>
              <Typography variant='h6'>
                {detail.properties.name}
              </Typography>
            </Grid>
            <Grid item sm={2}>
              <IconButton size='small' onClick={(e) => {toggleDrawing('')}}>
                <Cancel color='action' fontSize='small'/>
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
        </div>
      )
    default:
      return null
  }
}

function mapStateToProps(state) {
  return {
    detail: state.estate.detail,
    open: state.estate.visible.rightPanel
  }
}

function mapDispatchToProps(dispatch) {
  return{
    triggerDeleteTree: payload => dispatch(triggerDeleteTree(payload)),
    removeAnnotations: (payload) => dispatch(removeAnnotations(payload)),
    toggleDeleteDialog: (payload) => dispatch(toggleDeleteDialog(payload)),
    toggleDrawing: payload => dispatch(toggleDrawing(payload))
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DetailDrawerHeader))
