
import {connect} from 'react-redux'
import {useDropzone} from 'react-dropzone'
import React , {useCallback, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {
    toggleKmlDialog,
    uploadFile,
    subscribeSocketMessage,
    showMessage
} from '../actions/index';
function mapStateToProps(state, props){
	return {
        open: state.estate.uploadDrawer.open,
        progress: state.estate.uploadDrawer.progress,
        map: state.estate.map
	}
}

function mapDispatchToProps(dispatch, props) {
    return {
        toggleKmlDialog: payload => dispatch(toggleKmlDialog(payload)),
        uploadFile: payload => dispatch(uploadFile(payload)),
        subscribeSocketMessage: payload => dispatch(subscribeSocketMessage(payload)),
        showMessage: (payload)=>dispatch(showMessage(payload))
    }
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function ImportKMLDialog(props) {
  const [files, setFiles] = useState([]);
  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles);
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  function uploadFile(){
    props.subscribeSocketMessage({
        topic: 'blocks_processing',
        purpose: 'block'
    })
    props.uploadFile({files: files, media_id: props.map.media_id});
    props.toggleKmlDialog({})
    props.showMessage({type: "info", message: "Your KML file is in process"})
  }
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.toggleKmlDialog}
        aria-labelledby="alert-dialog-slide-title"
        // aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Import KML File "}</DialogTitle>
        <DialogContent>
          {/*<DialogContentText id="alert-dialog-slide-description">*/}
          <input
            accept="application/vnd.google-earth.kml+xml"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
          />
          <label htmlFor="raised-button-file">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
              {
                isDragActive ?
                  <p>Drop the files here ...</p> :
                  <p>Drag and drop some files here, or click to select files</p>
              }
          </div>  
          </label>
          {
            files.map((f)=>{
              return <span>{f.name}</span>
            })
          } 
          {/*</DialogContentText>*/}
        </DialogContent>
        <DialogActions>
          <Button onClick={uploadFile} color="primary">
            Upload File
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const ConnectedImportKMLDialog = connect(mapStateToProps, mapDispatchToProps)(ImportKMLDialog)
export default (ConnectedImportKMLDialog)