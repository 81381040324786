import { useState } from 'react'
import { connect } from 'react-redux'
import { ReactComponent as PolygonIcon } from '../../static/svg/drawPolygon.svg'
import { showOnboardingBlinker } from '../../utils/onboardingTour'
import {
  toggleTreesLayer,
  toggleOrthoLayer,
  toggleAllListItem,
  toggleDrawing,
  toggleKmlDialog,
  toggleDeleteDialog,
  showMessage,
  setZoomLevel,
} from '../../actions/index'
import {
  getListAllVisible,
  getListSomeVisible
} from '../../selectors/annotations'
import LayerItemDetails from './LayerItemDetails'
import LogsUploadDialog from '../../components/LogsUploadDialog' // Quick Hack, probably don't belong here

import classNames from 'classnames'
import { StyledMenu, StyledMenuItem } from '../../components/shared/StyledMenu'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  IconButton,
  Typography,
  Checkbox,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@material-ui/core'
import {
  Add,
  AttachFile,
  ChevronRight,
  ExpandMore,
  ShowChart,
  LocationOn,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const SHOW_ONBOARDING_BLINKER_STEPS = [3, 5, 9, 10, 11, 12]

const styles = theme => ({
  layerItem: {
    margin: 0,
    color: theme.palette.text.secondary,
    width: '100%',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
  },
  accordionRoot: {
    padding: 0,
  },
  accordionSummary: {
    margin: 0,
  },
  accordionDetails: {
    marginLeft: '5%',
    paddingTop: 0,
    paddingBottom: 0,
  },
  listRoot: {
    width: '100%',
    padding: 0,
  },
  listItemRoot: {
    width: '100%',
    padding: 0,
  },
  iconButtonStyle: {
    color: theme.palette.primary.dark,
  },
  iconRotate: {
    color: theme.palette.secondary.main,
    transform: 'rotate(45deg)',
    transition: 'transform .3s ease-in-out',
  },
  iconRotateExit: {
    color:theme.palette.primary.main,
    transform: 'rotate(0deg)',
    transition: 'transform .3s ease-in-out',
  },
  popoverPaper: {
    overflowY: 'hidden',
    width: '200px',
    height: '35px',
    padding: '10px',
  },
  root: {
    flexGrow: 1,
  },
  checkboxIndeterminate: {
    color: theme.palette.secondary.main,
  },
  hide:{
    opacity: 0,
  },
  show: {
    opacity: 1,
  }
})

function VectorLayerComponent(props) {
  const {
    // drawingMode,
    allListVisible,
    classes,
    hasChildItem,
    hasSubOptions,
    isDrawing,
    itemName,
    items,
    listName,
    loading,
    purpose,
    setZoomLevel,
    showMessage,
    someListVisible,
    step,
    toggleDrawing,
    toggleKmlDialog,
    toggleLayer,
    tourIsOpen,
    // toggleDeleteDialog,
    visible,
    zoom,
  } = props
  const [ expanded, setExpanded ] = useState(false)
  const [ openMenu, setOpenMenu ] = useState(false)
  const [ anchorEl, setAnchorEl ] = useState(null)
  // const [ hideDel,  setHideDel  ] = useState(true)
  const [ blinker,  setBlinker  ] = useState(true)
  const [ onboard,  setOnboard  ] = useState(true) // onboardingStyledMenuCSSOverride
  const [ blinkOnPolygon, setBlinkOnPolygon ] = useState(false)

  // Quick hack, probably don't belong here
  const [ dialog,   setDialog ] = useState('')

  function handleClose(e) {
    if (e)
      e.stopPropagation()
    setAnchorEl(null)
    setOpenMenu(false)
    setBlinkOnPolygon(false)
  }

  function handleOpenClickOrDraw(e) {
    if (!hasSubOptions) {
      toggleDrawing()
    }
    else if (e.target.checked) {
      setOpenMenu(true)
      setAnchorEl(e.currentTarget)
      setOnboard(false)
    }
    else {
      if (hasSubOptions && isDrawing)
        toggleDrawing()
      handleClose(e)
    }
  }

  function handleMenuItemClick(e, options) {
    e.stopPropagation()
    if (tourIsOpen && step === 11)
      setOnboard(true)
    toggleDrawing(options)
    handleClose(e)
  }

  function renderDataTourStep() {
    return tourIsOpen && step === 6 ? 'step-6' : ''
  }

  async function handleBlinkerClick() {
    if (step === 11) {
      // Need to await showBlinker to false before openMenu
      // So that menu opens in the right place
      await setBlinker(false)
      await setOpenMenu(true)
      setBlinkOnPolygon(true)
      // We need to showBlinker again to show the blinker on 'Polygon'
      await setBlinker(true)
    }
    // Opens annotations when user clicks 'Blocks' or 'Annotation'
    if (step === 5 || step === 9)
      await setExpanded(true)
  }

  function showOnboardingBlinkerOnPolygon() {
    if (!blinker)
      return ''
    if (tourIsOpen && blinker && step === 11 && blinkOnPolygon)
      return showOnboardingBlinker(props, true)
  }

  function renderShowOnboardingBlinker() {
    if (!blinker)
      return ''
    if (tourIsOpen && blinker && step === 11 && !blinkOnPolygon && !isDrawing)
      return showOnboardingBlinker(props)
    if (tourIsOpen && step !== 11 && SHOW_ONBOARDING_BLINKER_STEPS.includes(step))
      return showOnboardingBlinker(props)
  }

  function handleAccordionClick() {
    if (listName === 'trees') {
      setZoomLevel(19)
    }
    else if (items.length === 0) {
      showMessage({
        type: 'info',
        message: 'No items are found in this layer. Click add button to add ' + itemName.toLowerCase()
      })
    }
  }

  // function handleMouseEnter() {
  //   if (listName === 'blocks')
  //     setHideDel(false)
  // }

  // function handleMouseLeave() {
  //   if (listName === 'blocks')
  //     setHideDel(true)
  // }

  function expandIconButton() {
    return <IconButton 
      aria-label='Expand'
      disableRipple
      onClick={() => setExpanded(!expanded)}>
      { expanded && items.length > 0 ? 
        <ExpandMore className={classes.iconButtonStyle} /> 
        :
        <ChevronRight className={classes.iconButtonStyle} />
      }
    </IconButton>
  }

  // const deleteIcon = <Tooltip title='Delete All Blocks'>
  //   <DeleteIcon
  //     color={(items === null || items.length === 0) ? 'disabled' : 'secondary'}
  //     onClick={(e) => {
  //       e.stopPropagation()
  //       if (items != null && items.length > 0) {
  //         toggleDeleteDialog('blocks')
  //       }
  //     }}
  //     className={hideDel ? classes.hide : classes.show}
  //   />
  // </Tooltip>

  function addToggle() {

    return <Tooltip
      title={isDrawing ? 'Cancel' : 'Add ' + purpose}>
      <Checkbox
        onClick={e => {
          e.stopPropagation()
          if (listName === 'flights') setDialog('flights')
          if (listName === 'discharges') setDialog('discharges')
        }}
        onChange={e => handleOpenClickOrDraw(e)}
        disabled={zoom < 19 && listName === 'trees'}
        checked={isDrawing}
        icon={
          <Add color='primary' className={classes.iconRotateExit} />
        }
        checkedIcon={
          <Add color='primary' className={classes.iconRotate} />
        }
      />
    </Tooltip>
  }

  function annotationControls() {
    return <StyledMenu
      id={tourIsOpen && (step === 11 || step === 12) && onboard ?
        'simple-popover-annotation' : 'simple-popover'
      }
      open={(tourIsOpen && step === 12) || (tourIsOpen && step === 13) ? false : openMenu}
      anchorEl={anchorEl}
      onClose={e => handleClose(e)}
      keepMounted>
      <StyledMenuItem
        onClick={e => { handleMenuItemClick(e, {geometry: 'line'}) }}>
        <ListItemIcon>
          <ShowChart />
        </ListItemIcon>
        <ListItemText primary='Line' />
      </StyledMenuItem>
      <StyledMenuItem
        onClick={e => { handleMenuItemClick(e, {geometry: 'polygon'}) }}>
        <ListItemIcon>
          <PolygonIcon />
        </ListItemIcon>
        <ListItemText primary='Polygon' />
        {showOnboardingBlinkerOnPolygon()}
      </StyledMenuItem>
      <StyledMenuItem
        onClick={e => { handleMenuItemClick(e, {geometry: 'point'}) }}>
        <ListItemIcon>
          <LocationOn />
        </ListItemIcon>
        <ListItemText primary='Point' />
      </StyledMenuItem>
    </StyledMenu>
  }

  function blockControls() {
    return <StyledMenu
      id='simple-popover'
      open={openMenu}
      anchorEl={anchorEl}
      onClose={e => handleClose(e)}
      keepMounted>
      <StyledMenuItem
        onClick={e => {
          e.stopPropagation()
          toggleKmlDialog()
          handleClose(e)
        }}>
        <ListItemIcon>
          <AttachFile />
        </ListItemIcon>
        <ListItemText primary='Upload KML File' />
      </StyledMenuItem>
      <StyledMenuItem
        onClick={e => handleMenuItemClick(e)}>
        <ListItemIcon>
          <PolygonIcon />
        </ListItemIcon>
        <ListItemText primary='Draw Block' />
      </StyledMenuItem>
    </StyledMenu>
  }

  function visibleCheckbox() {
    return <Checkbox
      onClick={e => e.stopPropagation()}
      checked={allListVisible || visible}
      value={props.itemId}
      indeterminate={someListVisible}
      onChange={toggleLayer}
      color='primary'
      disabled={(!items || items.length === 0) || (zoom < 18 && listName === 'trees')} 
      classes={{
        indeterminate: classes.checkboxIndeterminate,
      }}
    />
  }

  return (<>
    <Accordion
      square
      className={classes.layerItem}
      expanded={(hasChildItem && expanded && items.length > 0)}
      onChange={() => setExpanded(!expanded)}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
      onClick={handleAccordionClick}>
      <AccordionSummary
        classes={{ root: classes.accordionRoot }}
        className={classNames({
          [classes.accordionSummary]: !hasChildItem
        })}>
        <Grid container justifyContent='space-between' alignItems='center'>
          { hasChildItem ? <>
              <Grid item xs={2}>{ expandIconButton() }</Grid>
              <Grid item xs={6}>
                <Typography>{ itemName }</Typography>
              </Grid>
            </>
          : <Grid item xs={8} style={{ paddingLeft: '16px' }}>
              <Typography>{ itemName }</Typography>
            </Grid>
          }
          {/* this.props.listName === 'blocks' &&
            <Grid item xs={?}>{ deleteIcon }</Grid>
          */}
          <Grid item xs={2}>
            { !loading && addToggle() }
            { listName === 'annotations' && annotationControls() }
            { listName === 'blocks' && <>
              { loading && <CircularProgress color='secondary' size={20} /> }
              { blockControls() }
            </> }
          </Grid>
          <Grid item xs={2}>
            { visibleCheckbox() }
          </Grid>
        </Grid>
      </AccordionSummary>
      { hasChildItem && 
        <AccordionDetails
          classes={{ root: classes.accordionDetails }}
          data-tour={renderDataTourStep()}>
          <List
            classes={{ root:classes.listRoot }}>
            { items.map((item, index) => 
              <ListItem key={index}
                classes={{ root:classes.listItemRoot }}>
                <LayerItemDetails item={item} listName={listName} index={index}/>
              </ListItem>
            ) }
          </List>
        </AccordionDetails>
      }
      <span onClick={handleBlinkerClick}>
        {renderShowOnboardingBlinker()}
      </span>
    </Accordion>
    <LogsUploadDialog open={!!dialog} onClose={() => setDialog('')} />
  </>)
}

const mapStateToProps = (state, props) => {
  const stateToProps = {
    step:        state.tour.step,
    tourIsOpen:  state.tour.tourIsOpen,
    items:       state.estate[props.listName] || [], // TBD Flights / Discharges
    drawingMode: state.drawing.draw,
    isDrawing:   false,
    loading:     state.socket.waiting[props.purpose],
    zoom:        state.estate.zoom
  }
  if (props.listName && props.purpose !== 'tree') {
    stateToProps.allListVisible = getListAllVisible(state, props)
    stateToProps.someListVisible = getListSomeVisible(state, props)
  } else if (props.purpose === 'tree') {
    stateToProps.visible = state.estate.visible[props.listName]
  }
  if (props.purpose !== 'annotation') {
    //if annotations, check if any of the geometries contains purpose annotation
    stateToProps.isDrawing = (state.drawing.draw && state.drawing.draw[props.geometry]?.purpose === props.purpose)
  } else {
    stateToProps.isDrawing = state.drawing.draw && (
      (state.drawing.draw.point?.purpose   === 'annotation') ||
      (state.drawing.draw.line?.purpose    === 'annotation') ||
      (state.drawing.draw.polygon?.purpose === 'annotation')
    )
  }
  return stateToProps
}

const mapDispatchToProps = (dispatch, props) =>{ 
  return {
    toggleLayer: (payload={})=>{
      if (props.listName === 'trees') {
        return dispatch(toggleTreesLayer(payload))
      } else if (props.listName === 'annotations' || props.listName === 'blocks' || props.listName === 'roads' || props.listName === 'drains') {
        payload.listName = props.listName
        return dispatch(toggleAllListItem(payload))
      } else {
        return dispatch(toggleOrthoLayer(payload))
      }
    },
    toggleDeleteDialog: payload => dispatch(toggleDeleteDialog(payload)),
    toggleKmlDialog: payload => dispatch(toggleKmlDialog(payload)),
    toggleDrawing: (payload={}) => {
      return dispatch(toggleDrawing({
        purpose: props.purpose,
        geometry: props.geometry,
        ...payload,
      })) 
    },
    showMessage: (payload) => dispatch(showMessage(payload)),
    setZoomLevel: (payload )=> dispatch(setZoomLevel(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VectorLayerComponent))
