import React, { createRef } from 'react'
import { connect } from 'react-redux'
import {
  selectDetail,
  // subscribeSocketMessage
} from '../actions/index'
import { GeoJSON, Tooltip } from 'react-leaflet'
import L from 'leaflet'

import '../static/css/mapStyles.css'
import theme from '../utils/site-theme'

class VectorLayer extends React.Component{

	constructor(props){
		super(props)
		this.geojson = createRef()
	}

	getTreeStatus(color){
		switch(color){
			case "none":
				return "Healthy"
			case theme.palette.annotation.disease:
				return "Disease"
			case theme.palette.annotation.pest:
				return "Pest"
			case theme.palette.annotation.dry:
				return "Water Stress"
			case theme.palette.annotation.death:
				return "Death"
			default: 
				return "Healthy"
		}
	}

	checkProcessing(prevProps, props){
		if((!prevProps || !prevProps.processing) &&  props && props.processing){
			/*this.props.subscribeSocketMessage({
				topic: 'index_points_by_property.complete',
				purpose: 'blocks',
				once: true
			})*/
		}
	}

	componentDidMount(){
		if(this.props.layerName === "blocks"){
			this.checkProcessing(null, this.props)
		}
	}
	pointToLayer(feature, latlng){
			
		if(feature.properties.purpose === 'tree'){
			const color = (feature.properties.category && feature.properties.category !== "none") ? feature.properties.category : "red"
			
			return L.circleMarker(latlng, {
				fill: true,
				fillColor: color,
				fillOpacity: 1.0,
				radius: 5,
				stroke: false
			})
		}else if(feature.properties.purpose === 'annotation'){
			const color = this.getColor(feature)
			const markerSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">'+
			'<path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" fill="' + color + '"/>'+
			'<path d="M0 0h24v24H0z" fill="none"/></svg>'
			// var markerSvg = '<svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">' + 
			// '<path d="M7 0.833333C3.4525 0.833333 0.583332 3.7025 0.583332 7.25C0.583332 12.0625 7 19.1667 7 19.1667C7 19.1667 13.4167 12.0625 13.4167 7.25C13.4167 3.7025 10.5475 0.833333 7 0.833333ZM7 9.54167C5.735 9.54167 4.70833 8.515 4.70833 7.25C4.70833 5.985 5.735 4.95833 7 4.95833C8.265 4.95833 9.29167 5.985 9.29167 7.25C9.29167 8.515 8.265 9.54167 7 9.54167Z" fill="' + color + '"/>' +
			// '</svg>'
			const iconUrl = encodeURI('data:image/svg+xml,' + markerSvg).replace('#', '%23');
			const markerIcon = L.icon( {
				iconUrl: iconUrl,
				iconSize: [38, 38],
				iconAnchor: [18.5, 38],
			});
			return L.marker(latlng, {icon: markerIcon})
		}
		
	}
	componentDidUpdate(prevProps){
		if(this.props.layerName === "blocks"){
			this.checkProcessing(prevProps, this.props)
		}
	}

	formatCountString(treeCount){
		return treeCount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	}
	getColor(el){
		if(el.properties.color){
			return el.properties.color;
		}else if(el.properties.stroke){
			return el.properties.stroke;
		}else if(el.properties.purpose === 'annotation'){
			return 'white';
		}else{
			return 'black';
		}
	}
	render(){
		// const displayOrder = ["block", "annotation","road","drain", "tree"]
		return (<>
			{ (this.props.layerName !== "trees" ||
         (this.props.layerName === "trees" && this.props.zoom > 18)) &&
        this.props.items.map((el, index) => {

						if((!this.props.detail || (this.props.detail._id !== el._id)) && (this.props.layerVisible || el.meta.visible)){
							return ( <GeoJSON 
										ref={this.geojson}
										data={el} 
										key={Math.random()+'n'}
										style={{"color": this.getColor(el)}}
										onClick={() => {
											if((this.props.layerVisible || el.meta.visible) && Object.keys(this.props.drawingMode).length === 0){
												this.props.selectDetail({item: el, index})
											}
										}}
										pointToLayer={this.pointToLayer.bind(this)}
										onMouseOver={(e) => {
											if(this.props.layerName === "trees"){
												e.target.bindTooltip(this.getTreeStatus(el.properties.category)).openTooltip()
											}

											if(el.geometry.type !== "Point" && Object.keys(this.props.drawingMode).length > 0) {
												L.DomUtil.addClass(e.layer._path, 'crosshair-cursor-enabled')
											} else if(el.geometry.type !== "Point" && Object.keys(this.props.drawingMode).length === 0){
												L.DomUtil.removeClass(e.layer._path, 'crosshair-cursor-enabled')
											}
										}}
									>
									{this.props.layerName === "blocks" &&
									<Tooltip direction="center" 
										offset={[0, 0]} 
										opacity={null} 
										className={  (this.props.zoom > 16 && this.props.zoom < 19) ? "show-tooltip": ""}
										backgroundColor='black' permanent>
										{(this.props.zoom <= 16 && el.properties) ? 
											<p>
												{el.properties.name !== null ? el.properties.name : "Block"}
											</p>
											: ""
										}
										{ (this.props.zoom > 16 && this.props.zoom < 19  && el.properties.count) ? 
											<p>
												{el.properties.name !== null ? el.properties.name : "Block"}
												<br />
												{this.formatCountString(el.properties.count) + " stands"}
											</p>
											: ""
										}
									</Tooltip>
									}
								</GeoJSON>
							)
						}
            else {
              return undefined
            }
				}) }
		</>)
	}
}

const mapStateToProps = (state, props) => {
  const stateToProps = {
    zoom:        state.estate.zoom,
    detail:      state.estate.detail,
    drawingMode: state.drawing.draw,
    items:       state.estate[props.layerName] || [],
  }
  if (state.estate.visible[props.layerName]) {
    // use only for tree at the moment
    stateToProps['layerVisible'] = state.estate.visible[props.layerName]
  }
  return stateToProps
}

function mapDispatchToProps(dispatch) {
  return{
    selectDetail: payload => dispatch(selectDetail(payload)),
    //subscribeSocketMessage: payload => dispatch(subscribeSocketMessage(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VectorLayer)
