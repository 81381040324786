import L from 'leaflet';

export default L.Editable.PolylineEditor.extend({
    addHooks: function(){
        L.Editable.PolylineEditor.prototype.addHooks.apply(this, arguments);
        this.map.on('zoomend', ()=>{
            this.reset();
        })

        return this;
    },
    initVertexMarkers: function(){
        L.Editable.PolylineEditor.prototype.initVertexMarkers.call(this, this.getLatLngs())
        let latlngs = [...this.getLatLngs()]
        latlngs = latlngs.map((latlng, index)=>{
            if(index === 0){
                return {
                    render: true,
                    latlng
                };
            }else{
                const nextCoord = this.map.latLngToLayerPoint(latlngs[index - 1]);
                const currentCoord = this.map.latLngToLayerPoint(latlngs[index]);
                const viewDistance = Math.sqrt(Math.pow(currentCoord.x - nextCoord.x, 2) + Math.pow(currentCoord.y - nextCoord.y , 2))
                
                return {
                    render: (index === latlngs.length - 1) || (viewDistance > 10 && latlngs.length > 3),
                    latlng: latlng
                }
            }
        });
        this.editLayer.eachLayer(function(layer){
            if(layer.latlng && layer.latlng.__vertex){
                let result = latlngs.find((vertex)=> vertex.latlng.lat === layer.latlng.lat && vertex.latlng.lng === layer.latlng.lng);
                if(!result.render){
                    L.DomUtil.addClass(layer.latlng.__vertex._icon, "vertex-hidden");
                }
            }
        })
        
    }
});