import { takeEvery, call, put } from "redux-saga/effects"
import {
  GET_TOKEN_REQUESTED,
  GET_TOKEN_COMPLETED,
  GET_TOKEN_ERRORED,
  PUT_MAP_REQUESTED,
  PUT_MAP_COMPLETED,
  PUT_MAP_ERRORED
} from '../constants/action-types'

import axios from 'axios'
import store from '../store/index'

const apiUrl = process.env.REACT_APP_API_URL
const analyticsUrl = process.env.REACT_APP_ANALYTICS_BASE_URL

function authHeader() {
  return { Authorization: 'Bearer ' + store.getState().oidc.user.access_token }
}

export default function* watcherSaga() {
  yield takeEvery(GET_TOKEN_REQUESTED, workerGetAPITokenSaga)
  yield takeEvery(PUT_MAP_REQUESTED, workerPutMapSaga)
}


function* workerGetAPITokenSaga(action){
  try {
    const payload = yield call(getAPIToken, action);
    yield put({ type: GET_TOKEN_COMPLETED, payload });
  } catch (e) {
    yield put({ type: GET_TOKEN_ERRORED, payload: e });
  }
}

function* workerPutMapSaga(action){
  try{
    const payload = yield call(putMap, action)
    yield put({ type: PUT_MAP_COMPLETED, payload })
  } catch (e) {
    yield put({ type: PUT_MAP_ERRORED, payload: e })
  }
}

function putMap(payload){
  // console.log('apiurl:',apiUrl + '/v1/media/' + store.getState().media.token )
  return axios.put(apiUrl
    + '/v1/media/'
    + store.getState().media.token, {
    media: [payload.payload],
    headers: {
        'Content-Type': 'application/json'
    }
  }).then((result) => {
    return axios.post(analyticsUrl+'map/'+result.data.media_ids[0], result, {
      headers: authHeader()
    })
    .then((result) => {
      console.log(result)
      return result
    })
  })
}

function getAPIToken(payload){
  return axios.get(apiUrl + '/v1/get-token/' ,{
    headers: {
      // This does not make sense. For further investigation on why is this hardcoded
      // The value has been removed from all environment variables
      Authorization: "Basic anVhbitAZ2FydWRhLmlvOkRlbW9wbGFudGF0aW9uMTIz"
    }
  })
  .then((result) => {
    console.log('result:', result)
    return result;
  })
  .catch((err) => {
    console.log('api token error:', err)
  })
}
