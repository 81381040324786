import {
	// PAYLOAD_LISTNAME_BLOCKS,
	// PAYLOAD_LISTNAME_TREES,
	// PAYLOAD_LISTNAME_,


	SELECT_DETAIL,
	CLEAR_MAP_STATE,
	DETAIL_CHANGED,
	HIDE_MESSAGE,
	TOGGLE_KML_DIALOG,
	SHOW_MESSAGE,
	UNSELECT_DETAIL
} from '../constants/action-types';
export function selectDetail(payload){
	//expecting a listname, index and item
	/*
	if(payload.block){
		payload.purpose = PAYLOAD_PURPOSE_BLOCK;
	}else if(payload.tree){
		payload.purpose = PAYLOAD_PURPOSE_TREE;
	}else if(payload.annotation){
		payload.purpose = PAYLOAD_LISTNAME;
	}*/
	return {type: SELECT_DETAIL, payload};
}
export function toggleKmlDialog(payload){
	return {type: TOGGLE_KML_DIALOG, payload}
}
export function clearMapState(payload){
	return {type: CLEAR_MAP_STATE, payload}
}
export function changeDetail(payload){
	return {type: DETAIL_CHANGED, payload}
}

export function hideMessage(payload){
	return {type: HIDE_MESSAGE, payload}
}

export function showMessage(payload){
	return {type: SHOW_MESSAGE, payload}
}

export function unselectDetail(payload){
	return {type: UNSELECT_DETAIL, payload}
}