import {
    ZOOM_LEVEL_CHANGED,
    HIDE_TREES,
    TRIGGER_DELETE_TREE,
    TOGGLE_ORTHO_OPACITY,
    SAVE_GEOMETRY_CHANGES,
    TOGGLE_DELETE_DIALOG,
    SET_ZOOM_LEVEL,
    UPDATE_ESTATE_REQUESTED,
    SORT_ESTATES
} from '../constants/action-types'

export const saveGeometryChanges=(payload={})=>{
  return {type: SAVE_GEOMETRY_CHANGES, payload}
}
export function zoomLevelChanged(payload){
  return {type: ZOOM_LEVEL_CHANGED, payload}
}
export function hideTrees(payload){
  return {type: HIDE_TREES, payload}
}
export function triggerDeleteTree(payload){
  return {type: TRIGGER_DELETE_TREE, payload}
}
export function toggleOrthoOpacity(payload){
  return {type: TOGGLE_ORTHO_OPACITY, payload}
}
export function toggleDeleteDialog(payload){
  return {type: TOGGLE_DELETE_DIALOG, payload}
}
export function setZoomLevel(payload){
  return {type: SET_ZOOM_LEVEL, payload}
}
export function updateEstate(payload){
  return {type: UPDATE_ESTATE_REQUESTED, payload}
}
export function sortEstates(payload){
  return {type: SORT_ESTATES, payload}
}