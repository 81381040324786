import classNames from 'classnames'
import AnnotationLayerItemList from './AnnotationLayerItemList'
import {
  Drawer,
} from '@material-ui/core'
import {
  ChevronLeft,
  ChevronRight,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const drawerWidth = 240
const drawerHeight = 65

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    height: 'calc(100vh - '+ drawerHeight + 'px)',
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    height: 'calc(100vh - '+ drawerHeight + 'px)',
    bottom: 0,
    position: 'sticky',
    backgroundColor: theme.palette.background.default,
    borderStyle: 'none',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)',
  },
  drawerHandle: {
    position:'absolute',
    top: '70px',
    width: '23px',
    height: '48px',
    zIndex: 500,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    cursor: 'pointer',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    lineHeight: '4',
  },
  drawerHandleShift: {
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
  },
  hide: {
    display: 'none',
  },
})

function AnnotationDrawers({ classes, open, onToggle }) {
  return (
    <div>
      <div 
        className={classNames(classes.drawerHandle, {
          [classes.drawerHandleShift]: open,
        })}
        onClick={onToggle}>
        <ChevronRight
          className={classNames({[classes.hide]: open})}
        />
        <ChevronLeft
          className={classNames({[classes.hide]: !open})}
        />
      </div>
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <AnnotationLayerItemList />
      </Drawer>
    </div>
  )
}

export default withStyles(styles)(AnnotationDrawers)
