// src/js/store/action-types.js
import { applyMiddleware, createStore, compose } from 'redux'
import { loadUser } from 'redux-oidc'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
import createSagaMiddleware from 'redux-saga'
import axios from 'axios'

import rootReducer from '../reducers/index'
import annotationSaga from '../sagas/annotation-saga'
import mapsSaga from '../sagas/maps-saga'
import userManager from '../utils/userManager'
import elevationSaga from '../sagas/elevation-saga'
import editingSaga from '../sagas/editing-saga'
import paymentSaga from '../sagas/payment-saga'
import querySaga from '../sagas/query-saga'
import mediaSaga from '../sagas/media-saga'
import tourSaga from '../sagas/tour-saga'

export const history = createBrowserHistory()

history.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
})

const initialiseSagaMiddleware = createSagaMiddleware()

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
	rootReducer(history),
	storeEnhancers(applyMiddleware(routerMiddleware(history), initialiseSagaMiddleware))
)

initialiseSagaMiddleware.run(annotationSaga)
initialiseSagaMiddleware.run(mapsSaga)
initialiseSagaMiddleware.run(elevationSaga)
initialiseSagaMiddleware.run(editingSaga)
initialiseSagaMiddleware.run(querySaga)
initialiseSagaMiddleware.run(mediaSaga)
initialiseSagaMiddleware.run(paymentSaga)
initialiseSagaMiddleware.run(tourSaga)

loadUser(store, userManager)

axios.interceptors.request.use(function(config) {
  const { access_token } = store.getState().oidc.user
  config.headers.Authorization = `Bearer ${access_token}`
  return config
})

export default store


/*
export default function configureStore(preloadedState) {
	const store = createStore(
		createRootReducer(history), // root reducer with router state
		preloadedState,
		compose(
			applyMiddleware(
				routerMiddleware(history), // for dispatching history actions
				// ... other middlewares ...
			),
		),
	)

	return store
}*/