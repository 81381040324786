//payload constants for options for certain actions
export const PAYLOAD_VISIBLE_TREES = "trees"
export const PAYLOAD_VISIBLE_BASELAYER = "baseLayer"
export const PAYLOAD_VISIBLE_ORTHO = "ortho"
export const PAYLOAD_VISIBLE_LEFT_PANEL = "leftPanel"
export const PAYLOAD_VISIBLE_RIGHT_PANEL = "rightPanel"

export const PAYLOAD_LISTNAME_BLOCKS = "blocks"
export const PAYLOAD_LISTNAME_TREES = "trees"
export const PAYLOAD_LISTNAME_ANNOTATIONS = "annotations"
export const PAYLOAD_LISTNAME_ROADS = "roads"
export const PAYLOAD_LISTNAME_DRAINS = "drains"

export const PAYLOAD_GEOMETRY_POINT = 'point'
export const PAYLOAD_GEOMETRY_LINE = 'line'
export const PAYLOAD_GEOMETRY_POLYGON = 'polygon'

export const PAYLOAD_PURPOSE_TREE = 'tree'
export const PAYLOAD_PURPOSE_BLOCK = 'block'
export const PAYLOAD_PURPOSE_ANNOTATION = 'annotation'
export const PAYLOAD_PURPOSE_DRAIN = 'drain'
export const PAYLOAD_PURPOSE_ROAD = 'road'