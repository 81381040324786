import React,{createRef} from 'react'
import { connect } from "react-redux"
import { GeoJSON, withLeaflet, Tooltip} from 'react-leaflet'
import {saveGeometryChanges} from '../actions/index';
import {getVisiblityForDetail} from '../selectors/annotations';
import L from 'leaflet'

const mapStateToProps = (state,props) => {
    
    return { 
        editMode: state.drawing.edit,
        detail: state.estate.detail,
        visible: getVisiblityForDetail(state,props),
        zoom: state.estate.zoom
	};
}

function mapDispatchToProps(dispatch) {
	return{
        saveGeometryChanges: payload => dispatch(saveGeometryChanges(payload))
	}
}

class CustomEditControl extends React.Component{
    constructor(props) {
        super(props);  
        this.tooltip = null; 
        this.geojson = createRef()
        this.geometryChanges = null;
    }
    addTooltip(e){
        L.DomEvent.on(document, 'mousemove', this.moveTooltip.bind(this))
        if(e.layer.feature.geometry.type === "Polygon" || e.layer.feature.geometry.type === "LineString"){
            this.tooltip.innerHTML = "Drag a vertex to change the shape"
        }else{
            this.tooltip.innerHTML = "Move the marker to change location"
        }
        
        this.tooltip.style.display = 'block'
    }
    moveTooltip(e){
        this.tooltip.style.left = e.clientX + 15 + 'px'
        this.tooltip.style.top = e.clientY - 60 + 'px'
    }
    removeTooltip(e){
        this.tooltip.innerHTML = ''
        this.tooltip.style.display = 'none'
        L.DomEvent.off(document, 'mousemove', this.moveTooltip.bind(this))
    }
	getColor(el){
		if(el.properties.color){
			return el.properties.color;
		}else if(el.properties.stroke){
			return el.properties.stroke;
		}else if(el.properties.purpose === 'annotation'){
			return 'white';
		}else{
			return 'black';
		}
    }
    addLayer(){

    }
    componentDidUpdate(prevProps){
        if(this.geojson.current){
            if((!prevProps.visible && this.props.visible)
             || (!prevProps.detail && this.props.detail) 
             || (prevProps.detail && prevProps.detail._id !== this.props.detail._id)){
                this.tooltip = L.DomUtil.get('tooltip')
                this.geojson.current.leafletElement.eachLayer((layer)=>{
                    layer.on('editable:enable', (e) => {
                        e.target.editor.editLayer.on('layeradd', function(){
                            console.log('add');
                        })
                        this.addTooltip.bind(this)(e)
                        
                        if(e.layer.toGeoJSON()['geometry']['type'].toLowerCase() !== 'point'){
                            e.layer.setStyle({color: this.getColor(e.layer.toGeoJSON()), width: 10})
                        }

                        if(this.props.detail.geometry.type === "Point" && this.props.detail.properties.purpose === "annotation"){
                            // const color = this.props.detail.properties.color ? this.props.detail.properties.color : "white"
                            const svg = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">'+
                            '<path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" stroke="black" fill="' + this.getColor(this.props.detail) + '"/>'+
                            '<path d="M0 0h24v24H0z" fill="none"/></svg>'
                            const iconUrl = encodeURI('data:image/svg+xml,' + svg).replace('#', '%23');
                            const markerIcon = L.icon( {
                                iconUrl: iconUrl,
                                iconSize: [38, 38],
                                iconAnchor: [18.5, 38],
                            });
                            e.target.setIcon(markerIcon)
                        }
                        if(this.props.detail.geometry.type === "Point" && this.props.detail.properties.purpose === "tree"){

                            const color = this.props.detail.properties.category && this.props.detail.properties.category !== "none" ? this.props.detail.properties.category : "red"

                            const svg = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">'+
                            '<circle cx="50%" cy="50%" r="5" stroke="white" stroke-width="2" fill="' + color + '" />' +
                            '</svg>'
                            const iconUrl = encodeURI('data:image/svg+xml,' + svg).replace('#', '%23');
                            const circleMarkerIcon = L.icon({
                                iconUrl: iconUrl,
                                iconSize: [28, 28],
                            });
                            e.target.setIcon(circleMarkerIcon)
                        }
                    })
                    layer.on('editable:disable', this.removeTooltip.bind(this))
                    layer.enableEdit();
                    
                    const saveGeometryChanges=(event)=>{
                        this.geometryChanges = event.layer.toGeoJSON()['geometry']
                        this.props.saveGeometryChanges({geometry: this.geometryChanges})
                    }
                    layer.on('editable:dragend', saveGeometryChanges)
                    layer.on('editable:editing', saveGeometryChanges)
                })
            }
        }
    }
	render(){
        return (
			<React.Fragment>
                {this.props.detail && this.props.visible &&  
                <GeoJSON ref={this.geojson} key={this.props.detail._id} data={this.props.detail}>
                    { this.props.detail.properties.purpose === 'block' && 
                    <Tooltip direction="center" 
                        offset={[0, 0]} 
                        opacity={null} 
                        className={  (this.props.zoom > 15 && this.props.zoom < 19) ? "show-tooltip": ""}
                        backgroundColor='black' permanent>
                        { (this.props.zoom > 15 && this.props.zoom < 19)  && (this.props.detail.properties.count ?  this.props.detail.properties.count.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +" stands " : "0 stands" ) }
                    </Tooltip>
                    }
                </GeoJSON>
            }
                <div id='tooltip'></div>
			</React.Fragment>
		)
	}
}

const ConnectedCustomEditControl = connect(mapStateToProps, mapDispatchToProps)(withLeaflet(CustomEditControl))
export default ConnectedCustomEditControl