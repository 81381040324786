import { takeEvery, call, put } from "redux-saga/effects"
import {
  SAVE_DETAIL_REQUESTED,
  SAVE_DETAIL_COMPLETED,
  SAVE_DETAIL_ERRORED,
} from '../constants/action-types'
import axios from 'axios'
import store from '../store/index'

const analyticsUrl = process.env.REACT_APP_ANALYTICS_BASE_URL

export default function* watcherSaga() {
  yield takeEvery(SAVE_DETAIL_REQUESTED, workerSaveDetailSaga)
}
 
function authHeader() {
  return { Authorization: 'Bearer ' + store.getState().oidc.user.access_token }
}

function* workerSaveDetailSaga(action) {
  try {
    const payload = yield call(saveDetail, action)
    yield put({ type: SAVE_DETAIL_COMPLETED, payload })
  } catch (e) {
    console.log(e)
    yield put({ type: SAVE_DETAIL_ERRORED, payload: e })
  }
}

function saveDetail(payload) {
  return axios({
    method: "POST",
    url: analyticsUrl + 'annotation/' + payload.payload.feature.properties.media_id + '/update',
    data:{
      item: payload.payload.feature
    },
    headers: authHeader()
  })
}
