import { connect } from 'react-redux'
import { toggleDetailDrawer } from '../../actions/index'

import DetailDrawerHeader from './DetailDrawerHeader'
import DetailDrawerBody   from './DetailDrawerBody'
import classNames from 'classnames'
import {
  Drawer,
} from '@material-ui/core'
import {
  ChevronLeft,
  ChevronRight,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const drawerWidth = 240
const drawerHeight = 65

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    height: 'calc(100vh - '+ drawerHeight + 'px)',
    flexShrink: 0,
    maxWidth: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    height: 'calc(100vh - '+ drawerHeight + 'px)',
    top: '65px',
    position: 'fixed',
    backgroundColor: theme.palette.background.default,
    maxWidth: drawerWidth,
  },
  drawerHandle: {
    position:'absolute',
    top: 'calc((100vh - ' + drawerHeight + 'px)/2)',
    width: '24px',
    height: '25px',
    zIndex: 500,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#F2F2F2',
    textAlign: 'center',
    cursor: 'pointer',
    boxShadow: '0px -4px 0px -8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    right: 0,
  },
  drawerHandleShift: {
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      right: drawerWidth,
  },
  hide: {
    display: 'none',
  },
})

function DetailDrawers({ classes, open, toggleDetailDrawer }) {
  return (<>
    <div
      className={classNames(classes.drawerHandle, {
        [classes.drawerHandleShift] : open,
      })}
      onClick={ () => {
        toggleDetailDrawer()} 
      }>
      <ChevronLeft 
        className={classNames({[classes.hide] : open})}
      />
      <ChevronRight
        className={classNames({[classes.hide] : !open})}
      />
    </div>
    <Drawer 
      className = {classes.drawer}
      variant='persistent'
      anchor='right'
      open={open}
      data-tour='step-8'
      classes={{
        paper: classes.drawerPaper
      }}>
      <DetailDrawerHeader />
      <DetailDrawerBody />
    </Drawer>
  </>)
}

function mapStateToProps(state) {
  return {
    open: state.estate.visible.rightPanel,
  }
}

function mapDispatchToProps(dispatch) {
  return{
    toggleDetailDrawer: payload => dispatch(toggleDetailDrawer(payload)),
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DetailDrawers))
