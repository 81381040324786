import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from "react-redux"

import UploadGoogleTiles from './UploadGoogleTiles'

const styles = (theme) => {
    return {}
}

function mapStateToProps(state) {
	return {
		user: state.oidc.user
	}
}

function mapDispatchToProps(dispatch, state) {

	return {

	}
}

class UploadOrthomosaic extends Component {

    static defaultProps = {
		projectID:    null,
		projectTitle: '',
		format:       null
    }
    
    componentDidMount(){
        console.log(this.props.format)
    }
    componentDidUpdate(prevProps){
        //console.log(this.state.selectedMediaType, this.state.selectedMediaFormat)
    }

    render() {
        return (
            <div style={{backgroundColor: 'white', padding: '16px'}}>
				{/* <div>
					Upload <b>{this.props.format === 'google-tiles' ? 'Google Tiles' : 'GIS Data Files'}</b> for project <b>{this.props.projectTitle}</b> ({this.props.projectID})
				</div>
				<div>
					<b>Preparation Steps</b><br />
					<ol>
						<li>Launch Pix4D, start a new project.</li>
						<li>Click <b>Process > Processing Options...</b>, select <b>3. DSM, Orthomosaic and Index</b>.</li>
						<li>In DSM and Orthomosaic section, select <b>GeoTIFF (with Merge Tiles)</b> and <b>Google Maps Tiles and KML</b>.</li>
						<li>To avoid restaring the processing from scratch, go to the menu, <b>View > Processing</b>, and ensure the 1. Initial Processing and 2. Point Cloud Densification and Mesh are not selected.</li>
						<li>Click Start</li>
					</ol>
					<br />
					<p>Once you're ready with the assets, make sure they exist in your Pix4D working directory before proceeding.</p>
				</div> */}
				{
					this.props.format === 'google-tiles' ?
						<UploadGoogleTiles {...this.props} />
					: null
				}
			</div>
        )
    }
}

const ConnectedUploadOrthomosaic = connect(mapStateToProps, mapDispatchToProps)(UploadOrthomosaic)
export default withStyles(styles)(ConnectedUploadOrthomosaic)