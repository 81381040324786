import ReactGA from 'react-ga'

import store from '../store'

export function tourNextStep(delayTime=0){
	const state = store.getState()
	console.log(`User clicked next from step ${state.tour.step+1}`)

	ReactGA.event({ 
		category: 'Onboarding',
		action: `User clicked next from step ${state.tour.step+1}`
	})

	if (delayTime)
		return { type: 'TOUR_NEXT_STEP', delayTime }

	return { type: 'TOUR_NEXT_STEP' }
}

export function tourPrevStep(){
	const state = store.getState()
	
	ReactGA.event({ 
		category: 'Onboarding',
		action: `User went back from step ${state.tour.step+1}`,
	})

	return { type: 'TOUR_PREV_STEP' }
}

export function startTour() {
	const state = store.getState()
	
	if (state.oidc.user) {
		const userEmail = state.oidc.user.profile.email
		const tutorialDoneKey = `done_tutorial_${userEmail}`
		const doneTutorial = localStorage.getItem(tutorialDoneKey)

		// We return { payload: false } because this means that the user has done the tutorial
		// We return { payload: true } because this means that the user has NOT done the tutorial
		
		if (doneTutorial)
			return { type: 'START_TOUR', payload: false }
		else 
			return { type: 'START_TOUR', payload: true }		
	} else {
		// We return payload: false because this means that there is no one signed in
		return { type: 'START_TOUR', payload: false }
	}	
}

export function endTour(){
	const state = store.getState()

	ReactGA.event({ 
		category: 'Onboarding',
		action: `User ended tour from step ${state.tour.step+1}`,
	})

	const userEmail = state.oidc.user.profile.email
	const tutorialDoneKey = `done_tutorial_${userEmail}`

	localStorage.setItem(tutorialDoneKey, true)

	return { type: 'END_TOUR' }
}
