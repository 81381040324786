import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getMaps, sortEstates, getBlocksData } from '../../actions/index'

import PlantationThumbnail from './PlantationThumbnail'
import {
  Typography,
  Divider,
  Menu,
  Button,
  MenuItem,
  IconButton,
} from '@material-ui/core'
import {
  ArrowDropDown,
  Add,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const estateOptions = ['Estates', 'Archived']
const sortOptions = ['Name (Ascending)', 'Stand Count (Largest)', 'Last Updated (Latest)']

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    paddingLeft: '2vw',
    paddingRight: '2vw'
  },
  addEstate: {
    float: 'right',
    width: 170,
    height: 40,
    marginLeft: 10
  },
  sort: {
    float: 'right',
    width: 250,
    height: 40
  },
  title: {
    alignItems: 'center',
    fontFamily: 'Roboto',
    width: 'calc(94vw - 60px)',
    height: 28,
    marginLeft: 60,
    marginTop: '3vh',
    marginBottom: '3vh',
    fontSize: 28,
    //lineHeight: 28,
    color: '#000000'
  }
})

function PlantationHeader(props) {
  const {
    classes,
    page,
    isSort,
    isAddEstate,
    location,
    // mapped from dispatch
    getMaps, getBlocksData, sortEstates,
    // mapped from state
    maps, updatedMap, allBlocks,
  } = props
  const { pathname } = location
  const [headerName, setheaderName] = useState(page)
  const [sort, setSort] = useState(sortOptions[0])
  const [anchorElEstate, setanchorElEstate] = useState(null)
  const [anchorElSort, setanchorElSort] = useState(null)
  
  function handleToggle(e) {
    if (anchorElSort == null)
      setanchorElSort(e.currentTarget)
    else
      setanchorElSort(null)
  }
  function handleClose(e) {
    setanchorElEstate(null)
  }
  function handleClick(e) {
    setanchorElEstate(e.currentTarget)
  }
  function handleEstateChange(option, e) {
    setanchorElEstate(null)
    setheaderName(option)
  }
  function handleSortChange(option, e) {
    setanchorElSort(null)
    setSort(option)
    sortEstates(option)
  }

  // Effect hook to retrieve latest maps information upon first load and after update (rename, delete)
  // TODO: Breaking react-hooks/exhaustive-deps, to rewrite using sagas
  useEffect((val) => {
    // console.info('PlantationHeader updatedMap', updatedMap, 'getMaps!')
    getMaps({})
  }, [updatedMap, getMaps]) //Adding additional array argument to re-render only when map is updated

  // Effect hook to get blocks information after map has been retrieved
  // TODO: Breaking react-hooks/exhaustive-deps, to rewrite using sagas
  useEffect(() => {
    // console.info('PlantationHeader maps', maps, 'getBlocksData!')
    getBlocksData({ maps: maps })
  }, [maps, getBlocksData])

  return (
    <div>
      <Typography variant='h4' className={classes.title}> {headerName} 
      { <>
        { pathname === '/' ?
          <>
            <IconButton onClick={handleClick}><ArrowDropDown color='action'/></IconButton>
            <Menu
                disableAutoFocusItem
                id='long-menu'
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                anchorEl={anchorElEstate}
                keepMounted
                open={Boolean(anchorElEstate)}
                onClose={handleClose}>
                {estateOptions.map(option => (
                <MenuItem style={{width: 120}} key = {option} onClick={(e) => handleEstateChange(option, e)}>{option}</MenuItem>
                ))}
            </Menu>
          </> : ''
        }
        {isAddEstate ? <Button className={classes.addEstate} href='upload' variant='contained' color='primary' data-tour='step-20'> {<Add style={{paddingBottom: 3}}/>} Add Estate</Button> :''}
        {isSort ? <Button className={classes.sort} variant= 'outlined' onClick={handleToggle}> {sort} {
          <>
            <ArrowDropDown color='action'/>
            <Menu
              disableAutoFocusItem
              id='long-menu'
              anchorEl={anchorElSort}
              getContentAnchorEl={null}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
              }}
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
              keepMounted
              open={Boolean(anchorElSort)}
              onClose={handleToggle}>
              {sortOptions.map(option => (
                <MenuItem key = {option} onClick={(e) => handleSortChange(option, e)}>{option}</MenuItem>
              ))}
            </Menu>
          </>
          } </Button> : ''
        }
        </>
      }
      </Typography>
      <Divider />
      { pathname === '/' ?
        <div className= {classes.root}>
          { headerName === 'Estates' ? 
            maps.map((map)=>{
              if(!map.properties.isDeleted)
                return (<PlantationThumbnail key={map.media_id} media_id={map.media_id} display= {headerName} map={map} blocks={allBlocks.filter(estate => estate.properties.media_id === map.media_id)}></PlantationThumbnail>)
              return undefined
            }) : 
            maps.map((map)=>{
              if(map.properties.isDeleted)
                return (<PlantationThumbnail key={map.media_id} media_id={map.media_id} display= {headerName} map={map} blocks={allBlocks}></PlantationThumbnail>)
              return undefined
            })
          }
        </div>
        : ''
      }
    </div>
  )
}

function mapStateToProps(state){
  return {
    maps: state.maps.maps ? state.maps.maps : [],
    updatedMap: state.maps.updatedMap,
    allBlocks: state.estate.allBlocks ? state.estate.allBlocks : []
  }
}

function mapDispatchToProps(dispatch){
  return {
    getMaps: (payload) => dispatch(getMaps(payload)),
    getBlocksData: (payload) => dispatch(getBlocksData(payload)),
    sortEstates: (payload) => dispatch(sortEstates(payload))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlantationHeader)))
