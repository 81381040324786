import { connect } from 'react-redux'
import { tourNextStep } from '../../actions/tour-actions'
import { toggleListItem, selectDetail } from '../../actions/index'
import { showOnboardingBlinker } from '../../utils/onboardingTour'
import {
  Checkbox,
  Grid,
  Link,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  listItemRoot: {
    width: '100%',
    padding: 0,
  },
  linkText: {
    color: 'black',
    cursor: 'pointer',
  },
})

const handleDetailClick = (props, item) => {
  props.selectDetail({item, index: props.index})

  if (props.tourIsOpen) {
    if (item.properties.name === 'Unhealthy' && props.step === 10)
      props.goToNextTourStep()

    if (props.step === 12)
      props.goToNextTourStep(500)
  }
}

function LayerItemDetails(props) {
  const { classes } = props
  const visible = props.item.meta.visible
  const item = props.item
  
  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <span onClick={() => handleDetailClick(props, item)}>
        {showOnboardingBlinker(props)}
      </span>
      <Grid item xs={9}>
        <Link 
          onClick={() => handleDetailClick(props, item)} 
          className={classes.linkText} 
          underline='none'>
          <Typography>
            {item.properties.name ? item.properties.name : ('Block')}
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={3}>
        <Checkbox 
          checked={visible}
          onChange={() => {
            props.toggleListItem({ index: props.index })
          }}
          color='primary'
        />
      </Grid>
    </Grid>
  )
}

function mapStateToProps(state, props) {
  return {
    tourIsOpen: state.tour.tourIsOpen,
    step: state.tour.step,
    item: state.estate[props.listName][props.index],
  }
}

function mapDispatchToProps(dispatch, props) {
  return{
    toggleListItem: payload =>{
      payload.listName = props.listName
      return dispatch(toggleListItem(payload))
    },
    selectDetail: payload => dispatch(selectDetail(payload)),
    goToNextTourStep: ()  => dispatch(tourNextStep())
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LayerItemDetails))
