import React, {Component} from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PlantationHeader from '../../views/Plantation/PlantationHeader'
import OrderSuccess from './OrderSuccess'
import {
  Grid,
  TextField,
  Divider,
  Card,
  CardMedia,
  CardContent,
  Button,
  MenuItem
} from '@material-ui/core'
import {CardNumberElement, CardExpiryElement, CardCvcElement, injectStripe} from 'react-stripe-elements'
import { CountryRegionData } from "react-country-region-selector"

import {
    paymentTreeCount,
    countTree,
    clearPurchasedTree
} from '../../actions/index'

const styles = (theme) =>{
    return {
        root: {
            flexWrap: 'wrap',
            display: 'flex'
        },
        detailsDiv: {
            flexGrow: 1,
            marginLeft: 100,
            marginTop: 30,
            maxWidth: '45vw',
            minWidth: 560,
            float: 'left'
        },
        title: {
            fontWeight: 600,
            marginBottom: 5,
            fontSize: 14,
            fontFamily: 'Roboto'
        },
        summaryDiv: {
            marginLeft: 100,
            marginTop: 50,
            justifyContent: 'center'
        },
        card: {
            width: 400
        },
        media: {
          maxHeight: 162,
          margin: '15px 15px 0px 15px',
          paddingTop: '45%',
          backgroundColor: theme.palette.background.default,
        },
        stripeElements: {
            cursor: 'text',
            "&:hover": {border: '1px solid rgba(0,0,0,0.87)'},
            padding: '18.5px 14px',
            border: '1px solid rgba(0,0,0,0.23)',
            borderRadius: '5px',
            height: 56,
            lineHeight: 'inherit'
        },
        errorText: {
            textAlign: 'center',
            color: 'red',
            marginBottom: 10
        },
        payButton: {
            margin: 20, 
            height: 50, 
            width: 360, 
            backgroundColor: '#2B3940', 
            color: '#ffffff',
            '&:disabled': {backgroundColor: 'rgba(43, 57, 64, 0.33)'}
        }
        
    }
}

function mapStateToProps(state){
    return {
        maps: state.maps.maps ? state.maps.maps : [],
        user: state.oidc.user,
        accountInfo: state.query.accountInfo,
        purchasedTree: state.payment.purchasedTree
    }
}

function mapDispatchToProps(dispatch){
    return {
        paymentTreeCount: (payload) => dispatch(paymentTreeCount(payload)), 
        countTree: (payload) => dispatch(countTree(payload)),
        clearPurchasedTree: (payload) => dispatch(clearPurchasedTree(payload))
    }
}

function getRegions(country){
    if (!country) {
      return []
    }
    return country[2].split("|").map(regionPair => {
      // [1] is the regionShortCode
      const regionName = regionPair.split("~")[0]
      return regionName
    })
}

class TreeCountForm extends Component {
    constructor(props){
        super(props);
        const {purchasedTree} = props
		this.state = {
            chosenMap: [],
            totalSize: 0,

            payment_method: 'card',
            amount: 1000,
            currency: 'myr',
            name: '',
            address_line1: '',
            address_city: '',
            address_state: '',
            address_zip: '',
            address_country: '',

            // For completeness only
            isCardNumEmpty: true,
            isExpDateEmpty: true,
            isCvvEmpty: true,

            errorMessage: purchasedTree.status ? purchasedTree.status : null,
            isProcessing: false,
            isError: false
        }
        this.pay = this.pay.bind(this);
    }

    async componentWillMount(){
        this.props.maps.forEach((map) => {
            if(map.media_id === this.props.match.params.id){
                this.state.chosenMap.push(map)
            }
        })
        await this.setState({
            totalSize: this.state.chosenMap[0].properties.estate_size ? 
            this.state.chosenMap[0].properties.estate_size
            :
            this.state.chosenMap[0].properties.total_size / 1000000
        })

        const amount = await this.getPrice(Math.max(0, Math.floor(this.state.totalSize - this.props.accountInfo.trial_balance.tree_count)))
        await this.setState({
            amount: amount
        })
        this.props.clearPurchasedTree()
        // console.log(this.props.accountInfo)
    }

    handleError(){
        if(this.state.isError === false){
            console.log('triggered')
            this.setState({isError: true, isProcessing: false})
        }
    }
    pay(e) {
        this.setState({isProcessing: true})
        const ownerData = this.getOwnerData()
        this.props.stripe.createSource(ownerData)
        .then((result) => {
            if (result.error) {
                this.setState({errorMessage: result.error.message, isProcessing: false})
                console.log('Error: ', result.error)
            } else {
                // console.log(result)
                this.setState({errorMessage: null})
                this.props.paymentTreeCount({result: result, type: 'tree'})
            }
        })
    }

    getPrice(area){
        const pricePerHectare = 500 //5 ringgit
        const price = pricePerHectare * area
        return price
    }
    getOwnerData(){
        const ownerData = {
            type: this.state.payment_method,
            amount: this.state.payment_method === 'wechat' ? this.state.amount : undefined,
            currency: this.state.currency,
            owner: {
                name: this.state.name,
                address: {
                  city: this.state.address_city,
                  country: this.state.address_country[1],
                  line1: this.state.address_line1,
                  postal_code: this.state.address_zip,
                  state: this.state.address_state
                },
                email: this.props.user ? this.props.user.profile.email : 'juan@garuda.io',
            },
            metadata: {
                amount: this.state.amount, //for credit card payment
                company_id: this.props.accountInfo.company_id ?  this.props.accountInfo.company_id : '1790145f9843d43029ff1903a7008d95',
                media_id: this.props.location.pathname.substring(15),
                area: this.state.totalSize,
                estate_name: this.state.chosenMap[0].name
            }
        }
        // console.log(ownerData)
        return ownerData
    }

    
    handleField(field, e){
        switch(field){
            case 'name':
                this.setState({name: String(e.target.value)})
                break
            case 'address_line1':
                this.setState({address_line1: String(e.target.value)})
                break
            case 'address_city':
                this.setState({address_city: String(e.target.value)})
                break
            case 'address_state':
                this.setState({address_state: String(e.target.value)})
                break
            case 'address_zip':
                this.setState({address_zip: e.target.value})
                break
            case 'address_country':
                this.setState({address_country: e.target.value})
                break
            default:
                break
        }
    }
    render(){
        const {classes} = this.props
        const {properties, name} = this.state.chosenMap[0]
        const {address_line1, address_city, address_state, address_zip, address_country, isCardNumEmpty, isExpDateEmpty, isCvvEmpty, isProcessing, errorMessage} = this.state
        const {tree_count} = this.props.accountInfo.trial_balance
        const areaTotal = Math.floor(this.state.totalSize)
        const areaPayable = Math.max(Math.floor(this.state.totalSize - tree_count), 0)

        const amountTotal = this.getPrice(areaTotal/100).toLocaleString(undefined, {'minimumFractionDigits': 2, 'maximumFractionDigits':2})
        const amountPayable = this.getPrice(areaPayable/100).toLocaleString(undefined, {'minimumFractionDigits': 2, 'maximumFractionDigits':2})

        const amountTrial = this.getPrice(tree_count/100).toLocaleString(undefined, {'minimumFractionDigits': 2, 'maximumFractionDigits':2})
        
        const isComplete = this.state.name.length > 0 && address_line1.length > 0 && address_city.length > 0 && address_state.length > 0
        && address_zip.length > 0 && address_country.length > 0 && !isCardNumEmpty && !isExpDateEmpty && !isCvvEmpty
        
        if(this.props.purchasedTree.status !== undefined){
            this.handleError()
        }
        return (
            <>
                <PlantationHeader page= 'Complete Your Purchase' isSort={false} isAddEstate={false} estates={null}/>
                {this.props.purchasedTree.status !== 'succeeded' ? 
                <div className={classes.root}>
                <div className={classes.detailsDiv}>
                <Typography variant='h5' style={{marginBottom: 30}}>Billing Address</Typography>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12}>
                        <Typography className={classes.title}>Name</Typography>
                        <TextField
                            required
                            id="cardName"
                            variant='outlined'
                            fullWidth
                            autoComplete="fname"
                            onChange={this.handleField.bind(this, 'name')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>Address</Typography>
                        <TextField
                            required
                            id="address1"
                            variant='outlined'
                            fullWidth
                            autoComplete="billing address-line1"
                            onChange={this.handleField.bind(this,'address_line1')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.title}>City</Typography>
                        <TextField
                            required
                            id="city"
                            variant='outlined'
                            fullWidth
                            autoComplete="billing address-level2y"
                            onChange={this.handleField.bind(this, 'address_city')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.title}>Zip Code</Typography>
                        <TextField
                            required
                            id="zip"
                            variant='outlined'
                            fullWidth
                            autoComplete="billing postal-code"
                            onChange={this.handleField.bind(this, 'address_zip')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.title}>Country</Typography>
                        <TextField
                            required
                            select
                            id="country"
                            value={this.state.address_country}
                            variant='outlined'
                            fullWidth
                            autoComplete="billing country"
                            onChange={this.handleField.bind(this, 'address_country')}
                        >
                        {CountryRegionData.map((option, index) => (
                            <MenuItem key={option[0]} value={option}>
                                {option[0]}
                            </MenuItem>
                        ))}    
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.title}>State</Typography>
                        <TextField id="state" select variant='outlined' value={this.state.address_state} fullWidth 
                                    onChange={this.handleField.bind(this, 'address_state')}
                        >
                            {getRegions(this.state.address_country).map(
                                (option, index) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                )
                            )}
                        </TextField>
                    </Grid>
                    </Grid>
                    <Typography variant='h5' style={{marginBottom: 30, marginTop: 50}}> Card Information</Typography>
                    <Grid container spacing={24}>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.title}>Card Number</Typography>
                        <CardNumberElement className={classes.stripeElements} onChange={(e)=> {this.setState({isCardNumEmpty: e.empty})}}/>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Typography className={classes.title}>Expiry Date</Typography>
                        <CardExpiryElement className={classes.stripeElements} onChange={(e)=> {this.setState({isExpDateEmpty: e.empty})}}/>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <Typography className={classes.title}>CVV</Typography>
                        <CardCvcElement className={classes.stripeElements} onChange={(e)=> {this.setState({isCvvEmpty: e.empty})}}/>
                    </Grid>
                </Grid>
                </div>
                <div className={classes.summaryDiv}>
                    <Card className={classes.card}>
                        <Typography variant='h5' style={{padding: 20, fontWeight: 'bold'}}>Order Summary</Typography>
                        <Divider/>
                        <CardMedia
                            className={classes.media}
                            image={properties.thumb ? properties.thumb : ''}
                            title="Image Thumbnail"
                        />
                        <CardContent>
                            <Typography style={{paddingLeft: 15, fontWeight: 600, fontFamily: 'Roboto'}} variant='h6'>{name}</Typography>
                            <Typography style={{padding: '15px 0px 0px 15px', fontWeight: 600, fontFamily: 'Roboto'}} variant='subtitle1'>Price breakdown</Typography>
                            <table style={{padding: '0px 15px', width: '100%', borderSpacing: '0px 10px', fontFamily: 'Roboto'}}>
                                <tr>
                                    <td>RM 5 x {areaTotal.toLocaleString(undefined, {'maximumFractionDigits':0})} ha</td>
                                    <td style={{float: 'right'}}>RM {amountTotal}</td>
                                </tr>
                                <tr>
                                    <td>{tree_count > 0 ? <p>First {tree_count} ha free</p>: ''}</td>
                                    <td style={{float: 'right'}}>{tree_count > 0 ? <p>-RM {amountTrial}</p> : ''}</td>
                                </tr>
                            </table>
                            <hr style={{borderTop: '1px solid rgba(0,0,0,0.12)'}}/>
                            <table style={{padding: '0px 15px', width: '100%', borderSpacing: '0px 10px', fontFamily: 'Roboto'}}>
                                <tr style={{fontSize: 16}}>
                                    <td><Typography style={{fontWeight: 600, fontFamily: 'Roboto'}} variant='h6'>Total</Typography></td>
                                    <td style={{float: 'right'}}><Typography style={{fontWeight: 600, fontFamily: 'Roboto'}} variant='h6'>RM {amountPayable}</Typography></td>
                                </tr>
                            </table>
                            <hr style={{borderTop: '1px solid rgba(0,0,0,0.12)'}}/>
                        </CardContent>
                        <Button onClick={this.pay} variant='outlined' className={classes.payButton} disabled={!isComplete || isProcessing}>Pay</Button>
                        {/* Handle Error Messages */}
                        {this.props.purchasedTree.status ? <Typography className={classes.errorText}> {this.props.purchasedTree.status} </Typography> : '' }
                        {errorMessage ? <Typography className={classes.errorText}> {errorMessage} </Typography> : '' }
                    </Card>
                </div>
            </div>
            : <OrderSuccess media_id={this.props.location.pathname.substring(15)}/>}
            </>
        )
    }
}

const ConnectedTreeCountForm = connect(mapStateToProps, mapDispatchToProps)(injectStripe(withStyles(styles)(TreeCountForm)))
export default withRouter(ConnectedTreeCountForm)