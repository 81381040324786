import { useState } from 'react'
import { connect }  from 'react-redux'
import { changeDetail } from '../../actions/index'

import area from '@turf/area'

import classNames from 'classnames'
import Select from 'react-select'
import { CirclePicker } from 'react-color'
import { formatDecimal } from '../../utils/formatter'
import {
  Grid,
  TextField,
  Link,
  Popover,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import theme from '../../utils/site-theme'

const styles = theme => ({
  divBox: {
    padding: '10px',
  },
  marginTop: {
    marginTop: '15px',
  },
  textOverflow: {
    overflowWrap: 'break-word',
  },
  dropdown: {
    width: '100%',
  },
  colorButton: {
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    display: 'block',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5)',
  },
  colorPopover: {
    backgroundColor: '#ffffff',
      overflowY: 'hidden',
      padding: '5px',
  },
  fontBold: {
    fontWeight: 'bold',
  }
})

const suggestions = [
  { value: 'Dura', label: 'Dura' },
  { value: 'Pisifera', label: 'Pisifera' },
  { value: 'Tenera', label: 'Tenera' },
]

function BlockDetailBody({ classes, changeDetail, detail }) {

  const plantingYearInputProps = {
    min: 1,
    max: 9999,
  }

  // const [colorPanelOpen, toggleColorPanel] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  function handleToggleColorPanel(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const handleChangeProperties = propName => event => {
    let value = null
    if (propName === 'plantingSpecies') {
      value = event
    } else {
      value = event.target.value
    }
    changeDetail({
      [propName]: value
    })
  }
  if (!detail.properties['Planting Year']){
    detail.properties['Planting Year'] = new Date().getFullYear()
  }
  if (!detail.properties['color']){
    detail.properties['color'] = theme.palette.common.black
  }
  
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : null
  return (
    <div className={classes.divBox}>
      <Typography variant='h6' gutterBottom className={classes.fontBold}>
        Measurements
      </Typography>
      <Grid container direction='row' justifyContent='space-between' alignItems='center' wrap='nowrap'>
        <Grid item sm={5}>
          <Typography variant='body2' gutterBottom className={classes.fontBold}>
            Area: 
          </Typography>
        </Grid>
        <Grid item sm={7}>
          <Typography variant='body2' className={classNames(classes.textOverflow, classes.fontBold)} gutterBottom>
            { formatDecimal((area(detail.geometry)/10000), 2) } ha
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction='row' justifyContent='space-between' alignItems='center'>
        <Grid item sm={5}>
          <Typography variant='body2' gutterBottom className={classes.fontBold}>
            Stand: { detail.meta.processing ? '(Updating)' : ''}
          </Typography>
        </Grid>
        <Grid item sm={7}>
          <Typography variant='body2' className={classNames(classes.textOverflow, classes.fontBold)} gutterBottom>
            { 
              detail.properties.count ? detail.properties.count.toLocaleString(undefined, {'minimumFractionDigits': 0, 'maximumFractionDigits':0})  : 0 
            }  stands
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction='row' justifyContent='space-between' alignItems='center'>
        <Grid item sm={5}>
          <Typography variant='body2' gutterBottom className={classes.fontBold}>
            Planting Density: 
          </Typography>
        </Grid>
        <Grid item sm={7}>
          <Typography variant='body2' className={classNames(classes.textOverflow, classes.fontBold)} gutterBottom>
            {detail.properties.density ? formatDecimal(detail.properties.density, 2) : 0} stands/ha
          </Typography>
        </Grid>
      </Grid>
      <Typography variant='h6' className={classes.marginTop} gutterBottom>
        More Information
      </Typography>
      <Typography variant='body2' gutterBottom>
        Block Name
      </Typography>
      <TextField 
        fullWidth={true} 
        value={detail.properties.name}
        onChange={handleChangeProperties('name')}
      />
      <Typography variant='body2' className={classes.marginTop} gutterBottom>
        Planting Year
      </Typography>
      <TextField 
        margin='none' 
        fullWidth={true} 
        type='number' 
        defaultValue={new Date().getFullYear()} 
        value={detail.properties.plantingYear}
        inputProps={plantingYearInputProps}
        onChange={handleChangeProperties('plantingYear')} />
      <Typography variant='body2' className={classes.marginTop} gutterBottom>
        Planting Species
      </Typography>
      <Select
        isMulti
        name='species'
        options={suggestions}
        onChange={handleChangeProperties('plantingSpecies')}
        value={detail.properties.plantingSpecies != null ? detail.properties.plantingSpecies : null}
      />
      <Typography variant='body2' className={classes.marginTop} gutterBottom>
        Color
      </Typography>
      <Link
        component='button'
        classes={{
          root: classes.colorButton,
        }}
        onClick={handleToggleColorPanel}
        style={{
          // backgroundColor: props.blockDetail.properties.color,
          backgroundColor: detail.properties.color ,
        }}>
      </Link>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.colorPopover,
        }}> 
        <div>
          <CirclePicker 
            width={210}
            colors={theme.palette.annotation.colors}
            onChangeComplete={(e) => {
              handleToggleColorPanel(e)
              handleChangeProperties('color')({target: { value: e.hex }})
            }}
          />
        </div>
      </Popover>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    detail: state.estate.detail,
    geometryCoordinates: state.estate.detail.geometry.coordinates
  }
}

function mapDispatchToProps(dispatch, state) {
  return {
    changeDetail: (payload)=> dispatch(changeDetail(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BlockDetailBody))
