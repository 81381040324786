import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

import { tourNextStep } from '../actions/tour-actions'
import { toggleDetailDrawer } from '../actions/index'

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(40, 137, 212, .4);
  }

  50% {
    transform: scale(1);
    box-shadow: 0 0 0 40px rgb(40, 137, 212, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(40, 137, 212, 0);
  }
`

const Blinker = styled.div`
  text-align: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  
  cursor: pointer;
  position: ${props => props.fixed ? 'fixed' : 'absolute'};
  
  background: ${props => props.transparent ? '' : 'rgb(40, 137, 212)'};
  border: ${props => props.transparent ? '2px solid rgb(40,137,212)' : ''};
  box-shadow: 0 0 0 0 rgb(40, 137, 212, 1);
  z-index: 10;

	transform: scale(1);
	animation: ${pulse} 1s infinite;

  top: ${props => props.top ? props.top + 'px' : ''};
  bottom: ${props => props.bottom ? props.bottom + 'px' : ''};
  left: ${props => props.left ? props.left + 'px' : ''};
  right: ${props => props.right ? props.right + 'px' : ''};

  margin-top: ${props => props.marginTop ? props.marginTop + 'px' : ''};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom + 'px' : ''};
  margin-left: ${props => props.marginLeft ? props.marginLeft + 'px' : ''};
  margin-right: ${props => props.marginRight ? props.marginRight + 'px' : ''};
`

class BlinkerComponent extends Component {
  handleBlinkerClick = async () => {
    const { step } = this.props

    if (step === 1) {
      await this.props.history.push('/map/' + this.props.demoEstateId) 
    }

    if (step === 11 && !this.props.goToNext) 
      console.log('we not going to next')
    else
      this.props.onNextStep(1000)
  }

  render() {
    console.log('blinker step: ', this.props.step)

    return (
      <span id="onboarding-blinker">
        <Blinker {...this.props} onClick={() => this.handleBlinkerClick()} />
      </span>
    )
  }
}

const mapStateToProps = (state) => {
  let demoEstateId = ''

  for (const map of state.maps.maps) 
    if (map.demo) 
      demoEstateId = map._id

  return {
    step: state.tour.step,
    tourIsOpen: state.tour.tourIsOpen,
    demoEstateId
  }
}

const mapDispatchToProps = dispatch => {
	return {
		onNextStep: (delayTime) => dispatch(tourNextStep(delayTime)),
		openDetailDrawer: () => dispatch(toggleDetailDrawer())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlinkerComponent))
