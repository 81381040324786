import { combineReducers } from 'redux'
import { reducer as oidcReducer } from 'redux-oidc'
import { connectRouter } from 'connected-react-router'

import annotationsReducer from './annotations'
import drawReducer        from './drawing'
import mapReducer         from './maps'
import mediaReducer       from './media'
import paymentReducer     from './payment'
import queryReducer       from './query'
import socketReducer      from './socket'
import tourReducer        from './tour'

const reducer = (history) => combineReducers({
  drawing: drawReducer,
  estate:  annotationsReducer,
  maps:    mapReducer,
  media:   mediaReducer,
  oidc:    oidcReducer,
  payment: paymentReducer,
  query:   queryReducer,
  router:  connectRouter(history),
  socket:  socketReducer,
  tour:    tourReducer,
})

export default reducer
