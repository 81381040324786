import { 
  ANNOTATIONS_DATA_LOADED,
  BLOCKS_DATA_LOADED,
  ZOOM_LEVEL_CHANGED,
  HIDE_TREES,
  GET_MAP_COMPLETED,
  ADD_ANNOTATION_COMPLETED,
  TOGGLE_VISIBLE_SINGLE,
  TOGGLE_VISIBLE_LIST,
  TOGGLE_VISIBLE_LIST_ENTRY,
  CLEAR_MAP_STATE,
  POINT_ELEVATION_COMPLETED,
  DETAIL_CHANGED,
  SAVE_DETAIL_COMPLETED,
  SELECT_DETAIL,
  SAVE_GEOMETRY_CHANGES,
  UPLOAD_FILE_PROGRESS,
  REMOVE_ANNOTATION_COMPLETED,
  TOGGLE_ORTHO_OPACITY,
  TOGGLE_KML_DIALOG,
  HIDE_MESSAGE,
  TOGGLE_DELETE_DIALOG,
  SHOW_MESSAGE,
  UNSELECT_DETAIL,
  SET_ZOOM_LEVEL,
  TOGGLE_DRAWING
} from '../constants/action-types'
import _ from 'lodash'

const initialState = {
  allBlocks: [],
  blocks: [],
  trees: [],
  zoom: 10,
  annotations:[],
  roads: [],
  drains: [],
  map: null,
  visible: {
    trees: true,
    baseLayer: true,
    ortho: true,
    leftPanel: false,
    rightPanel: false
  },
  detail: null,
  elevation: 0.0,
  treeMarkerEnabled: false,
  opacity: {
    ortho: 100,
  },
  uploadDrawer : {
    open: false,
    progress: 0.0
  },
  socket: {
    socket:null
  },
  deleteDialog: {
    open: false,
    deleteType: '',
  },
  messageDisplay: {
    type: '',
    message: ''
  }
}

function annotationReducer(state = initialState, action) {
  if (action.type === ADD_ANNOTATION_COMPLETED) {
    action.payload.inserted['meta'] = {
      'visible': true
    }
    if (action.payload.inserted.properties.purpose === 'annotation') {
      if (!action.payload.inserted.properties.name) {
        action.payload.inserted.properties.name = action.payload.inserted.geometry.type
      }
      return Object.assign({}, state, {
        annotations: [...state.annotations, action.payload.inserted],
        messageDisplay:{
          type: 'success',
          message: 'Added !'
        }
      })
    } else if (action.payload.inserted.properties.purpose === 'tree') {
      action.payload.inserted.meta = {}
      return Object.assign({}, state, {
        trees: [...state.trees, action.payload.inserted],
        messageDisplay:{
          type: 'success',
          message: 'Added !'
        }
      })
    } else if (action.payload.inserted.properties.purpose === 'block') {
      return Object.assign({}, state, {
        blocks: [...state.blocks, action.payload.inserted],
        messageDisplay:{
          type: 'success',
          message: 'Added !'
        }
      })
    } else if (action.payload.inserted.properties.purpose === 'road') {
      return Object.assign({}, state, {
        roads: [...state.roads, action.payload.inserted],
        messageDisplay:{
          type: 'success',
          message: 'Added !'
        }
      })
    } else if (action.payload.inserted.properties.purpose === 'drain') {
      return Object.assign({}, state, {
        drains: [...state.drains, action.payload.inserted],
        messageDisplay:{
          type: 'success',
          message: 'Added !'
        }
      })
    }
  }
  if (action.type === GET_MAP_COMPLETED) {
    return Object.assign({}, state, {
      map: action.payload
    })
  }
  if (action.type === ZOOM_LEVEL_CHANGED) {
    return Object.assign({}, state, {
      zoom: action.payload.zoom
    })
  }
  if (action.type === BLOCKS_DATA_LOADED) {
    if (action.payload && action.payload.features !== undefined) {
      const blocks = action.payload.features
      return Object.assign({}, state, {
        allBlocks: blocks
      })
    }
  }

  if (action.type === ANNOTATIONS_DATA_LOADED ) {
    let changes = {}
    action.payload.features.forEach((feature)=>{
      if (state.detail && feature._id === state.detail._id) {
        changes['detail'] = {
          ...state.detail,
          meta: {
            ...state.detail.meta,
            processing: false
          },
          properties: {
            ...state.detail.properties,
            density: feature.properties.density,
            count: feature.properties.count
          }
        }

      }
    })
    const mergeOldAndNew = (prevItems, items)=>{
      let newItems = [...items]
      for (let i = 0; i < newItems.length; i++) {
        let oldIndex = _.findIndex(prevItems, (oldItem)=> oldItem._id === newItems[i]._id)

        if (oldIndex !== -1 && prevItems[oldIndex]['meta'])
          newItems[i]['meta'] = prevItems[oldIndex]['meta']
      }
      return newItems
    }
    const trees = action.payload.features.filter((feature)=>{
      return feature.geometry.type.toLowerCase() === 'point' && feature.properties.purpose === 'tree'
    })
    const blocks = action.payload.features.filter((feature)=>{
      return feature.geometry.type.toLowerCase() === 'polygon' && feature.properties.purpose === 'block'
    })
    const annotations = action.payload.features.filter((feature)=>{
      return !feature.properties.purpose || feature.properties.purpose === 'annotation'
    })

    const roads = action.payload.features.filter((feature) => {
      return feature.properties.purpose === 'road'
    })

    const drains = action.payload.features.filter((feature) => {
      return feature.properties.purpose === 'drain'
    })

    if (trees.length !== 0) {
      changes['trees'] = trees.map((t)=>{
        t['meta'] = {
          ...t.meta
        }
        return t
      })
    } 
    // console.log(changes['trees'])
    if (blocks.length !== 0) {
      changes['blocks'] = blocks.map((b)=>{
        b['meta'] = {
          ...b.meta,
          'visible': true
        }
        return b
      })
      changes['blocks'] = mergeOldAndNew(state.blocks, changes['blocks'])
    }
    if (annotations.length !== 0) {
      changes['annotations'] = annotations.map((annotation, i)=>{
        annotation['meta'] = {
          ...annotation.meta,
          'visible': true
        }
        return annotation
      })
    }

    if (roads.length !== 0) {
      changes['roads'] = roads.map((road, i)=>{
        road['meta'] = {
          ...road.meta,
          'visible': true
        }
        return road
      })
    }

    if (drains.length !== 0) {
      changes['drains'] = drains.map((drain, i)=>{
        drain['meta'] = {
          'visible': true
        }
        return drain
      })
    }

    // console.log(state)
    return Object.assign({}, state, changes)
  }

  //hide trees without unchecking trees
  if (action.type === HIDE_TREES) {
    return Object.assign({}, state, {
      trees: state.trees.map((a)=>{
        //at higher zoom level still show editing tree
        if (!a.meta.editing) {
          a.meta.visible = false
        }
        return a
      })
    })
  }
  if (action.type === TOGGLE_VISIBLE_LIST || action.type === TOGGLE_VISIBLE_LIST_ENTRY) {
    let oldItems = [...state[action.payload.listName]]

    if (action.type === TOGGLE_VISIBLE_LIST_ENTRY) {
      oldItems[action.payload.index]['meta']['visible'] =  !oldItems[action.payload.index]['meta']['visible'] 
    }
    else{
      let toggledVisible = !(_.every(oldItems.map(i => i.meta.visible)))
      for (let i = 0; i < oldItems.length; i++) {
        oldItems[i]['meta']['visible'] = toggledVisible
      }
    }
    return Object.assign({}, state, {
      [action.payload.listName] : oldItems
    })
  }

  if (action.type === TOGGLE_VISIBLE_SINGLE) {
    const specificChanges = {}

    return Object.assign({}, state, {
      ...specificChanges,
      visible:{
        ...state.visible,
        [action.payload.type]: !state.visible[action.payload.type]
      }
    })
  }

  if (action.type === SELECT_DETAIL) {
    let changes = {}
    let item = action.payload.item

    if (state.detail) {
      if (state.detail.meta && state[state.detail.meta.payload_type][state.detail.meta.index])
        state[state.detail.meta.payload_type][state.detail.meta.index]['meta']['editing'] = false
    }

    if (action.payload.item.properties.purpose === 'block') {
      action.payload.type = 'blocks'
    }
    if (action.payload.item.properties.purpose === 'annotation') {
      action.payload.type = 'annotations'
    }
    if (action.payload.item.properties.purpose === 'drain') {
      action.payload.type = 'drains'
    }
    if (action.payload.item.properties.purpose === 'road') {
      action.payload.type = 'roads'
    }
    if (action.payload.item.properties.purpose === 'tree') {
      action.payload.type = 'trees'
    }

    const editedBlocks = [...state[action.payload.type]]
    editedBlocks[action.payload.index]['meta']['editing'] = true
    changes[action.payload.type] = editedBlocks

    changes['visible'] = {
      ...state.visible,
      rightPanel: true
    }
    item['meta']['index'] = action.payload.index
    item['meta']['payload_type'] = action.payload.type
    changes['detail'] = item
    changes['elevation'] = 0.0
    return Object.assign({}, state, changes)
  }

  if (action.type === CLEAR_MAP_STATE) {
    return Object.assign({}, state, initialState)
  }
  if (action.type === POINT_ELEVATION_COMPLETED) {
    return Object.assign({}, state, {
      elevation: action.payload.elevation,
    })
  }
  if (action.type === DETAIL_CHANGED) {
    return Object.assign({}, state,{
      detail: {
        ...state.detail,
        properties: Object.assign({}, state.detail['properties'], action.payload )
      }
    })
  }

  if (action.type === SAVE_GEOMETRY_CHANGES) {
    // console.log(state.detail, {
    //  detail: {
    //    ...state.detail,
    //    geometry: action.payload.geometry
    //  }
    // })
    return Object.assign({}, state, {
      detail: {
        ...state.detail,
        geometry: action.payload.geometry
      }
    })
  }

  if (action.type === SAVE_DETAIL_COMPLETED) {
    const changes = {}
    const item = action.payload.data.updated.item
    let listName = ''

    if (item.properties.purpose === 'tree') {
      listName='trees'
    } else if (item.properties.purpose === 'block') {
      listName = 'blocks'
    } else if (item.properties.purpose === 'annotation') {
      listName = 'annotations'
    } else if (item.properties.purpose === 'drain') {
      listName = 'drains'
    } else if (item.properties.purpose === 'road') {
      listName = 'roads'
    }
    const oldIndex = state[listName].findIndex((p)=> p._id === item._id)
    let newItems = [...state[listName]]
    item['meta']['processing'] = true
    item['meta']['editing'] = false

    newItems[oldIndex] = {
      ...item,
      properties: {
        ...state[listName][oldIndex]['properties'],
        ...item.properties
      }
    }
    changes[listName] = newItems
    if (!changes['messageDisplay']) {
      changes['messageDisplay'] = {}
    }
    changes['messageDisplay']['type'] = 'success'
    changes['messageDisplay']['message'] = 'Saved !'

    //remove previous detail
    changes['detail'] = initialState.detail
    //close right panel
    changes['visible'] = {
      ...state.visible,
      rightPanel: false,
    }

    return Object.assign({}, state, changes)
  }
  if (action.type === REMOVE_ANNOTATION_COMPLETED) {
    switch(action.payload.deleteType) {
      case 'block':
        action.payload.deleteType = 'blocks'
        break
      case 'tree':
        action.payload.deleteType = 'trees'
        break
      case 'waterway':
        action.payload.deleteType = 'drains'
        break
      case 'annotation':
        action.payload.deleteType = 'annotations'
        break
      case 'road':
        action.payload.deleteType = 'roads'
        break
      default:
        break
    }
    let newPoints = [...state[action.payload.deleteType]]
    let removedPointsId = action.payload.deleted

    newPoints = newPoints.filter(p =>{
      return removedPointsId.findIndex(otherId => {
        return otherId === p._id
      }) === -1
    })
    return Object.assign({}, state, {
      [action.payload.deleteType]: newPoints,
      visible: {
        ...state.visible,
        rightPanel: false
      },
      messageDisplay:{
        type: 'success',
        message: 'Deleted !'
      },
      detail: initialState.detail,
      deleteDialog: {
        ...state.deleteDialog,
        open: false
      }
    })
  }
  if (action.type === TOGGLE_ORTHO_OPACITY) {
    return Object.assign({}, state, {
      opacity:{
        ...state.opacity,
        ortho: action.payload,
      }
    })
  }
  if (action.type === TOGGLE_KML_DIALOG) {
    return Object.assign({}, state, {
      uploadDrawer : {
        ...state.uploadDrawer,
        open: !(state.uploadDrawer.open)
      }
    })
  }
  
  if (action.type === UPLOAD_FILE_PROGRESS) {
    return Object.assign({}, state, {
      uploadDrawer :{
        ...state.uploadDrawer,
        progress: action.payload.payload
      }
    })
  }
  if (action.type === HIDE_MESSAGE) {
    return Object.assign({}, state, {
      messageDisplay: {
        type: '',
        message: ''
      }
    })
  }
  if (action.type === TOGGLE_DELETE_DIALOG) {
    return Object.assign({}, state, {
      deleteDialog : {
        deleteType: action.payload,
        open: !(state.deleteDialog.open)
      }
    })
  }
  if (action.type === SHOW_MESSAGE) {
    return Object.assign({}, state, {
      messageDisplay: {
        type: action.payload.type,
        message: action.payload.message
      }
    })
  }
  if (action.type === UNSELECT_DETAIL) {
    return Object.assign({}, state, {
      detail: initialState.detail,
      visible: {
        ...state.visible,
        rightPanel: false
      },
    })
  }
  if (action.type === SET_ZOOM_LEVEL) {
    return Object.assign({}, state, {
      zoom: action.payload,
    })
  }
  if (action.type === TOGGLE_DRAWING) {
    return Object.assign({}, state, {
      detail: initialState.detail,
      visible: {
        ...state.visible,
        rightPanel: false
      },
    })
  }
  return state
}
export default annotationReducer