import {
    PAYMENT_TREE_COUNT_REQUESTED,
    COUNT_TREE_REQUESTED,
    CLEAR_PURCHASED_TREE
} from '../constants/action-types'

export const paymentTreeCount=(payload={})=>{
	return {type: PAYMENT_TREE_COUNT_REQUESTED, payload};
}
export const countTree=(payload={})=>{
	return {type: COUNT_TREE_REQUESTED, payload};
}
export const clearPurchasedTree=(payload={})=>{
	return {type: CLEAR_PURCHASED_TREE, payload};
}