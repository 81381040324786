import React             from 'react'
import { connect }       from 'react-redux'
import { HorizontalBar } from 'react-chartjs-2'
import theme             from '../../utils/site-theme'

const optimumStatsOptions = {
    scales: {
        xAxes: [{
            ticks: {min: 0,
            userCallback: function(value, index, values) {
                value = value.toString();
                value = value.split(/(?=(?:...)*$)/);
                value = value.join(',');
                return value;
            }},
            stacked: true
        }],
        yAxes: [{
            stacked: true
        }]
    },
    layout: {
        padding: {
            bottom: 50,
            top: 50
        }
    },
    legend: {
        position: 'bottom',
        labels: {
            boxWidth: 10
        }
    },
    title: {
        display: true,
        text: 'Planting Efficiency',
        fontSize: 24,
        fontColor: theme.palette.primary.main
    },
    tooltips: {
        callbacks: {
            label: function(tooltipItems, data) { 
                var label = data.datasets[tooltipItems.datasetIndex].label
                return label + ': ' + tooltipItems.xLabel.toLocaleString(undefined, {'minimumFractionDigits': 0, 'maximumFractionDigits':0}) + ' Stands'
                
            }
        }
    }
}

function DashboardPlantEfficiency(props) {
  const { estateSummary, maps } = props
  return (
    <HorizontalBar
      height={240}
      data={{
        labels: estateSummary.nameList,
        datasets: [
          {
            label: 'Current Stand Count',
            data: estateSummary.standCount,
            backgroundColor: Array(maps.length).fill(theme.palette.primary.main)
          },
          {
            label: 'Potential Addition of Stand Count',
            data: estateSummary.optimalCount,
            backgroundColor: Array(maps.length).fill(theme.palette.secondary.main)
          }
        ]
      }}
      options={optimumStatsOptions}
    />
  )
}

function mapStateToProps(state) {
  return {
    maps: state.maps.maps ? state.maps.maps : []
  }
}

export default connect(mapStateToProps, null)(DashboardPlantEfficiency)
