import React, {useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

const styles = theme => {
    return {
        textField: {
            marginTop: 11,
            marginBottom: 25,
            maxWidth: '75vw',
            width: 550
        },
        inputLabel: {
            fontSize: 16,
            marginTop: 25,
            fontWeight: 'bold'
        },
        dialog: {
            width: 600
        },
        button: {
            margin: 24,
        }
    }
}

function mapStateToProps(state){
    return {}
}

function mapDispatchToProps(dispatch){
    return {}
}

function RenameDialog(props) {
    const {classes, isOpened, handleDialogClose, handleRename, name} = props
    const [estateName, setestateName] = useState(name)
    const isComplete = estateName.length > 0

    return (
        <Dialog open={isOpened} onClose={handleDialogClose}>
            <div className={classes.dialog} >
                <DialogTitle id="form-dialog-title">Rename Estate</DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography className={classes.inputLabel}>Name</Typography>
                    <TextField required className= {classes.textField} variant= 'outlined' onChange=
                        { (val) => setestateName(val.target.value) }
                        value={estateName}
                    />
                </DialogContent>
                <DialogActions className= {classes.button} >
                <Button onClick={handleDialogClose} variant='outlined'>
                    Cancel
                </Button>
                <Button onClick={() => handleRename(estateName)} disabled={!isComplete}
                        color='primary' variant='contained' >
                    Submit
                </Button>
                </DialogActions>
            </div>
        </Dialog>
    )
}

const ConnectedRenameDialog = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RenameDialog))
export default ConnectedRenameDialog
